import classnames from "classnames";
import Common from "helpers/Common";
import {
    AUTH_SIGNUP_DOCUMENT_DETAILS,
    URL_CHANGE_PASSWORD,
    URL_COMPANY_PROFILE,
    URL_COMPANY_PROFILE_V2,
    URL_DEMO_LOGIN,
    URL_INVENTORY_FILE_SUMMARY_DETAIL,
    URL_LOGIN,
    URL_OTP,
    URL_PlanDetails,
    URL_RECENT_BILLS,
    URL_RECENT_ENVENTORY,
    URL_REGISTRATION,
    URL_SIGNUP,
    URL_SIGNUP_WITH_DOCUMENTS,
    URL_SIGNUPEMAIL,
    URL_SITEMAP,
    URL_SUBSCRIPTION,
    URL_TECHNICAL_ALERT,
} from "helpers/Paths";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import { useLoginStore } from "hooks/Login";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { IoLogOutOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Container } from "reactstrap";
import LoginAPICall from "service/LoginAPICall";
import { logout } from "store/slice/Login";
import store from "store/store";

const excludedPaths = [
    URL_LOGIN,
    URL_OTP,
    URL_CHANGE_PASSWORD,
    URL_INVENTORY_FILE_SUMMARY_DETAIL,
    URL_SIGNUP,
    URL_SIGNUPEMAIL,
    URL_REGISTRATION,
    URL_COMPANY_PROFILE_V2,
    URL_RECENT_BILLS,
    URL_RECENT_ENVENTORY,
    URL_TECHNICAL_ALERT,
    URL_SIGNUP_WITH_DOCUMENTS,
    AUTH_SIGNUP_DOCUMENT_DETAILS,
    URL_COMPANY_PROFILE,
    URL_SUBSCRIPTION,
    URL_PlanDetails,
    URL_DEMO_LOGIN,
    URL_SITEMAP,
];

function AdminNavbar({ routes }) {
    const navigate = useNavigate();
    const location = useLocation();
    const loginId = Common.getLoginId();
    const [{}, {}, { setAlerts }] = useLoginStore();

    const onLogoutClick = () => {
        new LoginAPICall().companyLogout({ loginid: Common.getCompanyId() });
        store.dispatch(logout());
        localStorage.removeItem("isDemo");
        navigate("/");
        setAlerts({
            type: "warning",
            message: `${loginId} user is logged out.`,
        });
    };

    const renderMenuItems = (routes) => {
      return routes
          .filter((route) => !excludedPaths.includes(route.path)) // Exclude only parent routes
          .map((route) => {
              if (route.views) {
                  // Check if any subRoute is active
                  const isDropdownActive = route.views.some(
                      (subRoute) =>
                          location.pathname === subRoute.layout + subRoute.path
                  );
  
                  const subItems = route.views.map((subRoute) => {
                      const isActiveLink = location.pathname === subRoute.layout + subRoute.path; // Check if this subRoute is active
  
                      return (
                          <NavDropdown.Item
                              key={subRoute.name}
                              onClick={() =>
                                  navigate(subRoute.layout + subRoute.path)
                              }
                              className={classnames("nav-item", {
                                  "active-nav-menu-item": isActiveLink, // Apply active class only to the active sublink
                              })}
                          >
                              {subRoute.name}
                          </NavDropdown.Item>
                      );
                  });
  
                  return (
                      <NavDropdown
                          key={route.name}
                          title={<>{route.name}</>}
                          className={classnames("nav-item", {
                              "active-dropdown-menu-item": isDropdownActive, // Apply active class to the dropdown if any sublink is active
                          })}
                      >
                          {subItems}
                      </NavDropdown>
                  );
              }
  
              return (
                  <Nav.Link
                      key={route.name}
                      className={classnames("nav-item", {
                          "active-nav-menu-item":
                              location.pathname === route.layout + route.path,
                      })}
                      onClick={() => navigate(route.layout + route.path)}
                  >
                      {route.name}
                  </Nav.Link>
              );
          });
  };
  

    return (
        <Navbar
            fixed="top"
            collapseOnSelect
            expand="xl"
            className="bg-white bg-body-tertiary shadow"
        >
            <Container fluid>
                <Navbar.Brand>
                    <img
                        style={{
                            height: "30px",
                            width: "30px",
                            objectFit: "contain",
                        }}
                        src={`${ERP_CDN_IMAGE_URL}/new-logo.svg`}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop if logo also fails
                            e.target.src = logo;
                        }}
                        alt="APS Three AI"
                    />
                </Navbar.Brand>

                {loginId && (
                    <div className="d-xl-none font-weight-bold me-2">
                        {loginId}
                    </div>
                )}
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className="w-100 d-xl-flex align-items-center justify-content-between">
                        <Nav className="me-auto">{renderMenuItems(routes)}</Nav>
                        <Nav className="align-items-xl-center">
                            {loginId && (
                                <div className="d-none d-xl-block font-weight-bold mr-2">
                                    {loginId}
                                </div>
                            )}
                            <Button
                                onClick={onLogoutClick}
                                className="logout-button"
                            >
                                Logout <IoLogOutOutline />
                            </Button>
                        </Nav>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default AdminNavbar;
