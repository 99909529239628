export const CURRENCY = [
  {
    symbol: "₹",
    name: "Indian Rupee",
    code: "INR",
  },
  {
    symbol: "$",
    name: "US Dollar",
    code: "USD",
  },
  {
    symbol: "CA$",
    name: "Canadian Dollar",
    code: "CAD",
  },
  {
    symbol: "€",
    name: "Euro",
    code: "EUR",
  },

  {
    symbol: "Af",
    name: "Afghan Afghani",
    code: "AFN",
  },
  {
    symbol: "ALL",
    name: "Albanian Lek",
    code: "ALL",
  },
  {
    symbol: "AR$",
    name: "Argentine Peso",
    code: "ARS",
  },
  {
    symbol: "AU$",
    name: "Australian Dollar",
    code: "AUD",
  },
  {
    symbol: "лв",
    name: "Bulgarian Lev",
    code: "BGN",
  },
  {
    symbol: "лв",
    name: "Bahraini Dinar",
    code: "BHD",
  },
  {
    symbol: "FBu",
    name: "Burundian Franc",
    code: "BIF",
  },
  {
    symbol: "BN$",
    name: "Brunei Dollar",
    code: "BND",
  },
  {
    symbol: "Bs",
    name: "Bolivian Boliviano",
    code: "BOB",
  },
  {
    symbol: "$b",
    name: "Brazilian Real",
    code: "BRL",
  },
  {
    symbol: "BWP",
    name: "Botswanan Pula",
    code: "BWP",
  },
  {
    symbol: "Br",
    code: "BYN",
    name_plural: "Belarusian rubles",
  },
  {
    symbol: "BZ$",
    name: "Belize Dollar",
    code: "BZD",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
    code: "CHF",
  },
  {
    symbol: "CL$",
    name: "Chilean Peso",
    code: "CLP",
  },
  {
    symbol: "CN¥",
    name: "Chinese Yuan",
    code: "CNY",
  },
  {
    symbol: "CO$",
    name: "Colombian Peso",
    code: "COP",
  },
  {
    symbol: "₡",
    name: "Costa Rican Colón",
    code: "CRC",
  },
  {
    symbol: "CV$",
    name: "Cape Verdean Escudo",
    code: "CVE",
  },
  {
    symbol: "Kč",
    name: "Czech Republic Koruna",
    code: "CZK",
  },
  {
    symbol: "Dkr",
    name: "Danish Krone",
    code: "DKK",
  },
  {
    symbol: "RD$",
    name: "Dominican Peso",
    code: "DOP",
  },
  {
    symbol: "Ekr",
    name: "Estonian Kroon",

    code: "EEK",
  },
  {
    symbol: "£",
    name: "Egyptian Pound",
    code: "EGP",
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
    code: "GBP",
  },
  {
    symbol: "GEL",
    name: "Georgian Lari",
    code: "GEL",
  },
  {
    symbol: "GH₵",
    name: "Ghanaian Cedi",
    code: "GHS",
  },
  {
    symbol: "FG",
    name: "Guinean Franc",
    code: "GNF",
  },
  {
    symbol: "GTQ",
    name: "Guatemalan Quetzal",
    code: "GTQ",
  },
  {
    symbol: "HK$",
    name: "Hong Kong Dollar",
    code: "HKD",
  },
  {
    symbol: "HNL",
    name: "Honduran Lempira",
    code: "HNL",
  },
  {
    symbol: "kn",
    name: "Croatian Kuna",
    code: "HRK",
  },
  {
    symbol: "Ft",
    name: "Hungarian Forint",
    code: "HUF",
  },
  {
    symbol: "Rp",
    name: "Indonesian Rupiah",
    code: "IDR",
  },
  {
    symbol: "₪",
    name: "Israeli New Sheqel",
    code: "ILS",
  },

  {
    symbol: "IQD",
    name: "Iraqi Dinar",
    code: "IQD",
  },
  {
    symbol: "IRR",
    name: "Iranian Rial",
    code: "IRR",
  },
  {
    symbol: "kr",
    name: "Icelandic Króna",
    code: "ISK",
  },
  {
    symbol: "J$",
    name: "Jamaican Dollar",
    code: "JMD",
  },
  {
    symbol: "JD",
    name: "Jordanian Dinar",
    code: "JOD",
  },
  {
    symbol: "¥",
    name: "Japanese Yen",
    code: "JPY",
  },
  {
    symbol: "Ksh",
    name: "Kenyan Shilling",
    code: "KES",
  },
  {
    symbol: "₩",
    name: "South Korean Won",
    code: "KRW",
  },

  {
    symbol: "	лв",
    name: "Kazakhstani Tenge",
    code: "KZT",
  },
  {
    symbol: "L.L.",
    name: "Lebanese Pound",
    code: "LBP",
  },
  {
    symbol: "SLRs",
    name: "Sri Lankan Rupee",
    code: "LKR",
  },
  {
    symbol: "Lt",
    name: "Lithuanian Litas",
    code: "LTL",
  },
  {
    symbol: "Ls",
    name: "Latvian Lats",
    code: "LVL",
  },
  {
    symbol: "MAD",
    name: "Moroccan Dirham",
    code: "MAD",
  },
  {
    symbol: "MDL",
    name: "Moldovan Leu",
    code: "MDL",
  },
  {
    symbol: "MKD",
    name: "Macedonian Denar",
    code: "MKD",
  },
  {
    symbol: "MMK",
    name: "Myanma Kyat",
    code: "MMK",
  },
  {
    symbol: "MOP$",
    name: "Macanese Pataca",
    code: "MOP",
  },
  {
    symbol: "MURs",
    name: "Mauritian Rupee",
    code: "MUR",
  },
  {
    symbol: "MX$",
    name: "Mexican Peso",
    code: "MXN",
  },
  {
    symbol: "RM",
    name: "Malaysian Ringgit",
    code: "MYR",
  },
  {
    symbol: "MTn",
    name: "Mozambican Metical",
    code: "MZN",
  },
  {
    symbol: "N$",
    name: "Namibian Dollar",
    code: "NAD",
  },
  {
    symbol: "₦",
    name: "Nigerian Naira",
    code: "NGN",
  },
  {
    symbol: "C$",
    name: "Nicaraguan Córdoba",
    code: "NIO",
  },
  {
    symbol: "kr",
    name: "Norwegian Krone",
    code: "NOK",
  },
  {
    symbol: "Rs",
    name: "Nepalese Rupee",
    code: "NPR",
  },
  {
    symbol: "NZ$",
    name: "New Zealand Dollar",
    code: "NZD",
  },
  {
    symbol: "OMR",
    name: "Omani Rial",
    code: "OMR",
  },
  {
    symbol: "B/.",
    name: "Panamanian Balboa",
    code: "PAB",
  },
  {
    symbol: "S/.",
    name: "Peruvian Nuevo Sol",
    code: "PEN",
  },
  {
    symbol: "₱",
    name: "Philippine Peso",
    code: "PHP",
  },
  {
    symbol: "PKRs",
    name: "Pakistani Rupee",
    code: "PKR",
  },
  {
    symbol: "zł",
    name: "Polish Zloty",
    code: "PLN",
  },
  {
    symbol: "₲",
    name: "Paraguayan Guarani",
    code: "PYG",
  },
  {
    symbol: "QR",
    name: "Qatari Rial",
    code: "QAR",
  },
  {
    symbol: "RON",
    name: "Romanian Leu",
    code: "RON",
  },
  {
    symbol: "din.",
    name: "Serbian Dinar",
    code: "RSD",
  },
  {
    symbol: "RUB",
    name: "Russian Ruble",
    code: "RUB",
  },
  {
    symbol: "RWF",
    name: "Rwandan Franc",
    code: "RWF",
  },
  {
    symbol: "SR",
    name: "Saudi Riyal",
    code: "SAR",
  },
  {
    symbol: "SDG",
    name: "Sudanese Pound",
    code: "SDG",
  },
  {
    symbol: "Skr",
    name: "Swedish Krona",
    code: "SEK",
  },
  {
    symbol: "S$",
    name: "Singapore Dollar",
    code: "SGD",
  },
  {
    symbol: "Ssh",
    name: "Somali Shilling",
    code: "SOS",
  },
  {
    symbol: "SY£",
    name: "Syrian Pound",
    code: "SYP",
  },
  {
    symbol: "฿",
    name: "Thai Baht",
    code: "THB",
  },
  {
    symbol: "DT",
    name: "Tunisian Dinar",
    code: "TND",
  },
  {
    symbol: "T$",
    name: "Tongan Paʻanga",
    code: "TOP",
  },
  {
    symbol: "TL",
    name: "Turkish Lira",
    code: "TRY",
  },
  {
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
    code: "TTD",
  },
  {
    symbol: "NT$",
    name: "New Taiwan Dollar",
    code: "TWD",
  },
  {
    symbol: "TSh",
    name: "Tanzanian Shilling",
    code: "TZS",
  },
  {
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    code: "UAH",
  },
  {
    symbol: "USh",
    name: "Ugandan Shilling",
    code: "UGX",
  },
  {
    symbol: "$U",
    name: "Uruguayan Peso",
    code: "UYU",
  },
  {
    symbol: "лв",
    name: "Uzbekistan Som",
    code: "UZS",
  },
  {
    symbol: "Bs",
    name: "Venezuelan Bolívar",
    code: "VEF",
  },
  {
    symbol: "₫",
    name: "Vietnamese Dong",
    code: "VND",
  },
  {
    symbol: "﷼",
    name: "Yemeni Rial",
    code: "YER",
  },
  {
    symbol: "ZWL$",
    name: "Zimbabwean Dollar",
    code: "ZWL",
  },
];
export const TEMPLATELIST = [
  { id: "1", categoryname: "A4" },
  { id: "2", categoryname: "Portable" },
];
export const INVOICE_FORMAT_LIST = [
  { id: "A4", categoryname: "A4" },
  { id: "A2", categoryname: "A2" },
];
export const INVENTORYPAGES = [
  { id: "0", categoryname: "5" },
  { id: "1", categoryname: "10" },
  { id: "2", categoryname: "20" },
  { id: "3", categoryname: "50" },
  { id: "4", categoryname: "100" },
];
export const AUTHFACTORLIST = [
  { id: "1", categoryname: "Email OTP" },
  { id: "2", categoryname: "Mobile OTP" },
];

export const ERRORTYPE = [
  { id: "1", type: "Warning" },
  { id: "2", type: "error" },
];
export const OPTIONS = [
  { id: "1", type: "Yes" },
  { id: "2", type: "No" },
];
export const SHAREREPORT = [
  { id: "1", type: "Branch" },
  { id: "2", type: "Company" },
];
