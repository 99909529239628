import { useEffect, useState } from "react";
// helpers
import Common from "helpers/Common";
// react-select
import Select from "react-select";
// reactstrap
import { Col, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
// underscore
import { isEmpty } from "underscore";
// hooks
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
// helpers
import { notificationMessages } from "helpers/constantMessages";
// varibles
import CustomButton from "components/Button/CustomButton";
import { INVOICE_FORMAT_LIST } from "variables/taxdetail";
import PageLoader from "components/Loader/PageLoader";

const InvoiceFormat = ({ getGenricData, taxDetails }) => {
  const [errors, setErrors] = useState({});
  const [GenricFeildValidate, setGenricFeildValidate] = useState({});
  const [{}, {}, { updateGenricData }] = useCompanyProfileStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [loginId, setLoginId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [loader, setLoader] = useState(false);

  const [formatData, setFormatData] = useState({
    formatIdUser: "",
    formatIdCompany: "",
  });

  useEffect(() => {
    // Fetch loginId from local storage
    const storedLoginId = localStorage.getItem("loginId");
    const storedCompanyId = localStorage.getItem("companyId");

    // Check if loginId exists and is not empty/null
    if (storedLoginId) {
      setLoginId(storedLoginId);
    }
    if (storedCompanyId) {
      setCompanyId(storedCompanyId);
    }
  }, []); // Empty dependency array ensures this runs only once after component mounts

  useEffect(() => {
    try {
      setLoader(true);
      const companyInvoiceFormat = taxDetails?.filter(
        (data) => data.feature_key === companyId + "_company_invoiceformat"
      )[0]?.feature_value;

      const userInvoiceFormat = taxDetails?.filter(
        (data) =>
          data.feature_key === companyId + "_" + loginId + "_user_invoiceformat"
      )[0]?.feature_value;

      setFormatData({
        ...formatData,
        formatIdCompany: {
          value:
            companyInvoiceFormat && companyInvoiceFormat.trim() !== ""
              ? companyInvoiceFormat
              : "A4",
          label:
            companyInvoiceFormat && companyInvoiceFormat.trim() !== ""
              ? companyInvoiceFormat
              : "A4",
        },
        formatIdUser: {
          value:
            userInvoiceFormat && userInvoiceFormat.trim() !== ""
              ? userInvoiceFormat
              : "A4",
          label:
            userInvoiceFormat && userInvoiceFormat.trim() !== ""
              ? userInvoiceFormat
              : "A4",
        },
      });
      setLoader(false);
    } catch (error) {
      // Handle error if necessary
      setLoader(false);
    }
  }, [taxDetails]);

  useEffect(() => {
    async function getData() {
      await getGenricData();
    }
    getData();
  }, []);

  async function saveDetails(e) {
    setLoader(true);

    e.preventDefault();
    let error = false;
    let validatation = { ...GenricFeildValidate };

    if (!formatData.formatIdCompany) {
      error = true;
      validatation.formatIdCompany = "has-danger";
    }
    if (!formatData.formatIdUser) {
      error = true;
      validatation.formatIdUser = "has-danger";
    }

    setGenricFeildValidate(validatation);
    if (!error) {
      let param = [
        {
          feature_key: companyId + "_company_invoiceformat",
          feature_value: formatData.formatIdCompany.label,
        },
        {
          feature_key: companyId + "_" + loginId + "_user_invoiceformat",
          feature_value: formatData.formatIdUser.label,
        },
      ];
      const res = await updateGenricData(param);
      if (res) {
        const response = await getGenricData();
        await setAlerts({
          type: "success",
          message: notificationMessages.invoiceFormat,
        });
      }
    }
    setLoader(false);
  }

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="profile-content-container">
          <Form className="form" onSubmit={saveDetails}>
            <div className="profile-content-title">
              <h5>Company Level</h5>
            </div>
            <Row>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Invoice Format</label>
                  <FormGroup>
                    <Input
                      name="formatIdCompany"
                      placeholder="Select Invoice Format"
                      type="hidden"
                      valid={
                        GenricFeildValidate?.formatIdCompany === "has-success"
                      }
                      invalid={
                        GenricFeildValidate?.formatIdCompany === "has-danger"
                      }
                    />
                    <Select
                      className="profile-custom-select"
                      name="formatIdCompany"
                      value={formatData?.formatIdCompany}
                      onChange={(value) => {
                        let error = { ...GenricFeildValidate };
                        if (!value || isEmpty(value)) {
                          error.formatIdCompany = "has-danger";
                        } else {
                          error.formatIdCompany = "has-success";
                        }
                        setGenricFeildValidate(error);
                        setFormatData({
                          ...formatData,
                          formatIdCompany: value,
                        });
                      }}
                      options={
                        INVOICE_FORMAT_LIST &&
                        INVOICE_FORMAT_LIST.length > 0 &&
                        INVOICE_FORMAT_LIST.map((prop) => {
                          return {
                            value: prop.id,
                            label: prop.categoryname,
                          };
                        })
                      }
                      placeholder="Select Invoice Format"
                      isSearchable={false}
                    />
                    <FormFeedback>Invoice Format is required.</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <div className="mt-3 profile-content-title">
              <h5>User Level</h5>
            </div>
            <Row>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Invoice Format</label>
                  <FormGroup>
                    <Input
                      name="formatIdUser"
                      placeholder="Select Invoice Format"
                      type="hidden"
                      valid={
                        GenricFeildValidate?.formatIdUser === "has-success"
                      }
                      invalid={
                        GenricFeildValidate?.formatIdUser === "has-danger"
                      }
                    />
                    <Select
                      className="profile-custom-select"
                      name="formatIdUser"
                      value={formatData?.formatIdUser}
                      onChange={(value) => {
                        let error = { ...GenricFeildValidate };
                        if (!value || isEmpty(value)) {
                          error.formatIdUser = "has-danger";
                        } else {
                          error.formatIdUser = "has-success";
                        }
                        setGenricFeildValidate(error);
                        setFormatData({ ...formatData, formatIdUser: value });
                      }}
                      options={
                        INVOICE_FORMAT_LIST &&
                        INVOICE_FORMAT_LIST.length > 0 &&
                        INVOICE_FORMAT_LIST.map((prop) => {
                          return {
                            value: prop.id,
                            label: prop.categoryname,
                          };
                        })
                      }
                      placeholder="Select Invoice Format"
                      isSearchable={false}
                    />
                    <FormFeedback>Invoice Format is required.</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
            </Row>

            <div className="mt-lg-2 profile-button-container">
              <CustomButton label="Save Details" type="submit" />
            </div>
          </Form>
        </div>
      )}
    </>
  );
};
export default InvoiceFormat;
