import Common from "helpers/Common";
import React, { useEffect, useState } from "react";
// reactstrap components
import { Card } from "reactstrap";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ChildLoader from "components/Loader/ChildLoader";
import { useActivityStore } from "hooks/Activity";

const ActivityLog = () => {
  const [gridLoader, setGridLoader] = useState(false);
  const [activityLogList, setActivityLogList] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(5);
  const [{}, { getActivityLogList }, {}] = useActivityStore();

  useEffect(() => {
    setGridLoader(true);
    getUserActivityLogList();
  }, [first, rows]);

  const getUserActivityLogList = async () => {
    setGridLoader(true);
    try {
      let activityData = await getActivityLogList({
        params: {
          userID: Common.getCompanyId(),
          pagesize: rows,
          pagenumber: first / rows + 1,
        },
      });

      // console.log(activityData)
      setActivityLogList(activityData);
      setGridLoader(false);
    } catch (error) {
      // console.log("activity list", error);
    }
    setGridLoader(false);
  };

  return (
    <div className="content-dashboard-inventory">
      <div className="title-route-container menu-title-div">
        {" "}
        <h4 className="menu-title-h"> Help & Activity / Activity Logs</h4>
      </div>
      <Card
        body
        color="secondary"
        outline
        style={{
          width: "100%",
        }}
      >
        {gridLoader ? (
          <ChildLoader />
        ) : (
          <>
            <DataTable
              value={activityLogList}
              dataKey="activityLogID"
              tableStyle={{ minWidth: "25rem" }}
              pt={{
                root: { className: "table" },
              }}
              paginator
              first={first}
              rows={rows}
              rowsPerPageOptions={[5, 10, 15]}
              totalRecords={150}
              onPage={(event) => {
                setFirst(event.first);
                setRows(event.rows);
              }}
            >
              <Column field="activityLogID" header="Activity Log ID"></Column>
              <Column field="activityName" header="Activity Name"></Column>
              <Column
                field="activityDescription"
                header="Activity Description"
                style={{ width: "700px" }}
              ></Column>
              <Column
                field="activityDate"
                header="Activity Date"
                dataType="date"
                body={(rowData) => {
                  try {
                    return Common.getFormatedDate(
                      rowData.activityDate,
                      "DD/MM/YYYY hh:mm:ss A",
                      true
                    );
                  } catch (error) {
                    console.error(error.message);
                    return "Invalid Date"; // or any fallback display text
                  }
                }}
              ></Column>
            </DataTable>
          </>
        )}
      </Card>
    </div>
  );
};

export default ActivityLog;
