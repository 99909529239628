import BillDashboard from "views/CreateBill/BillDashboard";
import Login from "views/Login/Login";

import {
  URL_DASHBOARD,
  URL_BILL_DASHBOARD,
  URL_INVENTORY_REPORT,
  URL_INVENTORY_MANAGEMENT_MANUAL,
  URL_INVENTORY_MANAGEMENT_BY_FILE,
  URL_LOGIN,
  URL_BEST_SELLING_USAGE_REPORT,
  URL_LEAST_SELLING_USAGE_REPORT,
  URL_HISTORICAL_DEMAND_REPORT,
  URL_FORECASTING_DEMAND_REPORT,
  URL_FORECASTING_TIME_DEMAND_REPORT,
  URL_REVENUE_REPORT,
  URL_COMPANY_PROFILE,
  URL_SMS,
  URL_REVENUE_REPORT_LINE_CHART,
  URL_REVENUE_REPORT_PIE_CHART,
  URL_INVENTORY_FILE_SUMMARY,
  URL_INVENTORY_FILE_SUMMARY_DETAIL,
  URL_EDIT_BILL,
  URL_PAYMENT,
  URL_INVENTORYHISTORY,
  URL_FEEDBACK,
  URL_COMPANY_PROFILE_V2,
  URL_BILL_V2,
  URL_STRIPE_PAYMENT,
  URL_RAZORPAY_PAYMENT,
  URL_REFUND_BILL,
  URL_SIGNUP_TEST,
  URL_ACCOUNT_RECIEVABLE,
  URL_ACCOUNT_PAYABLE,
  URL_SIGNUP_WITH_DOCUMENTS,
  URL_RAISE_ISSUE,
  URL_CONNECT_US,
  AUTH_URL_DEMO_LOGIN,
} from "helpers/Paths";
import MaximumStockUsageReport from "views/Report/MaximumStockUsageReport";
import MinimumStockUsageReport from "views/Report/MinimumStockUsageReport";
import ForecastingAndDemandReport from "views/Report/ForecastingAndDemandReport";
import HistoricalDemandReport from "views/Report/HistoricalDemandReport";
import InventoryReport from "views/Inventory/InventoryReport";
import RevenueReport from "views/Revenue/RevenueReport";
import CompanyProfile from "views/CompanyProfile/CompanyProfile";
import SMS from "views/Notification/SMS";
import RevenueReportLineChart from "views/Revenue/RevenueReportLineChart";
import AddInventory from "views/Inventory/AddInventory";
import UploadFileInventory from "views/Inventory/UploadFileInventory";
import RevenueReportPieChart from "views/Revenue/RevenueReportPieChart";
import InventoryFileSummary from "views/Inventory/InventoryFileSummary";
import InventoryFileSummaryDetail from "views/Inventory/InventoryFileSummaryDetail";
import Email from "views/Notification/Email";
import { URL_EMAIL } from "helpers/Paths";
import WPSMS from "views/Notification/WPSMS";
import { URL_WHATSAPP_SMS } from "helpers/Paths";
import { URL_OTP } from "helpers/Paths";
import { URL_CHANGE_PASSWORD } from "helpers/Paths";
import Otp from "views/Otp/Otp";
import ChangePassword from "views/ChangePassword/ChangePassword";
import CreateBill from "views/CreateBill/CreateBill";
import { URL_CREATE_BILL, URL_Template } from "helpers/Paths";
import EditBill from "views/CreateBill/EditBill";
import Template from "views/Template/CreateBillTemplate";
import Signup from "views/Signup/Signup";
import { URL_SIGNUP } from "helpers/Paths";
import SignupEmail from "views/Signup/SignupEmail";
import { URL_SIGNUPEMAIL } from "helpers/Paths";
import { URL_REGISTRATION } from "helpers/Paths";
import Registration from "views/Signup/Registration";
import NotificationSchedule from "views/Notification/NotificationSchedule";
import { URL_NOTIFICATIONSCHEDULE, URL_MANAGE_CUSTOMER } from "helpers/Paths";
import forecast_sellingreport from "./assets/img/icons/forecasting-report.svg";
import companyprofile from "./assets/img/icons/portfolio.png";
// import template from "./assets/img/icons/template-menu.svg";
import Payment from "views/payment/Payment";
import payment2 from "./assets/img/icons/mobile-payment.png";
import InventoryHistory from "views/Inventory/Inventoryhistory";
import Dashboard from "views/Dashboard/Dashboard";
import Feedback from "views/Feedback/Feedback";
import {
  URL_AUTOINVENTORY,
  URL_INVENTORYMANUAL,
  URL_INVENTORYDISCOUNTS,
} from "helpers/Paths";
import Inventorymanual from "views/Inventory/Inventorymanual";
import AutoInventory from "views/Inventory/AutoInventory";
import CompanyProfilev2 from "views/CompanyProfile/CompanyProfilev2";
import Bills from "views/Dashboard/Bills";
import { URL_RECENT_BILLS } from "helpers/Paths";
import {
  URL_RECENT_ENVENTORY,
  URL_INVOICE_OCR,
  URL_SPEECH_RECOGNITION,
  URL_DEMAND_FORECAST,
} from "helpers/Paths";
import RecentInventory from "views/Dashboard/RecentInventory";

import TechnicalAlert from "components/Alert/TechnicalAlert";
import { URL_TECHNICAL_ALERT } from "helpers/Paths";
import ActivityLog from "views/Activity/ActivityLog";
import { URL_ACTIVITY_LOGS } from "helpers/Paths";
import AccountRecievable from "views/payment/AccountRecievable";
import AccountPayable from "views/payment/AccountPayable";
import Subscription from "views/Subscription/Subscription";
import CreateBillKeyPage from "views/CreateBillKeyPage/CreateBillKeyPage";
import { URL_SUBSCRIPTION, URL_CreateBillKeyPage } from "helpers/Paths";
import document from "./assets/img/icons/page.png";
import InventoryDiscounts from "views/Inventory/InventoryDiscounts";
import SignupWithDocuments from "views/Signup/SignupWithDocuments";
import DocumentDetailsPage from "views/Signup/DocumentDetailsPage";
import ReorderStockReport from "views/Inventory/ReorderStockReport";
import { LiaFileSignatureSolid } from "react-icons/lia";

import {
  MdAttachMoney,
  MdBusiness,
  MdDashboard,
  MdEmail,
  MdInventory,
  MdNoteAdd,
  MdNotificationsActive,
  MdOutlineHistory,
  MdOutlineReceipt,
  MdOutlineReviews,
  MdPeopleAlt,
  MdAssessment,
  MdManageAccounts,
} from "react-icons/md";
import { PiSquaresFour } from "react-icons/pi";
import { BsCreditCard } from "react-icons/bs";
import { FaBoxes, FaEdit, FaRegChartBar } from "react-icons/fa";
import { BsStopwatch } from "react-icons/bs";
import { HiTemplate } from "react-icons/hi";
import { IoFileTrayFullSharp } from "react-icons/io5";
import { CiDiscount1 } from "react-icons/ci";
import { FiActivity } from "react-icons/fi";
import { AiFillFileAdd } from "react-icons/ai";
import {
  FaChartLine,
  FaHandHoldingDollar,
  FaMoneyBillTrendUp,
  FaUsers,
} from "react-icons/fa6";
import { URL_INVENTORY_EXPIRY_REPORT } from "helpers/Paths";
import { GiMedicines, GiReceiveMoney } from "react-icons/gi";
import NewCreateBill from "views/CreateBill/NewCreateBill";
import SalesReoprt from "views/Analytics/SalesReoprt";
import ForecastReport from "views/Analytics/ForecastReport";
import { URL_FORECAST_REPORT } from "helpers/Paths";
import PlanDetails from "views/PlanDetails/PlanDetails";
import { URL_PlanDetails } from "helpers/Paths";
import NotificationReport from "views/Notification/NotificationReport";
import { URL_NOTIFICATIONSCHEDULE_REPORT } from "helpers/Paths";
import AddModifyCustomer from "views/Customer/AddModifyCustomer";
import DemandForecast from "views/Preview/DemandForecast";
import InvoiceOCR from "views/Preview/InvoiceOCR";
import SpeechRecognition from "views/Preview/SpeechRecognition";
import { VscOpenPreview } from "react-icons/vsc";
import { SlSpeech } from "react-icons/sl";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { AiOutlineStock } from "react-icons/ai";
import { BroadcastNotification } from "views/Notification/BroadcastNotification";
import { URL_BROADCAST_NOTIFICATION } from "helpers/Paths";
import CustomersList from "views/Notification/CustomersList";
import { URL_CUSTOMER_LIST } from "helpers/Paths";
import { URL_SUPPLIER_SIGNUP } from "helpers/Paths";
import SupplierSignup from "views/Signup/SupplierSignup";
import InventoryExpiryReport from "views/Inventory/InventoryExpiryReport";
import AddInventoryNew from "views/Inventory/AddInventoryNew";
import Products from "views/Inventory/Products";
import { URL_INVENTORY_PRODUCTS_LIST } from "helpers/Paths";
import AddProduct from "views/Inventory/AddProduct";
import { URL_INVENTORY_PRODUCTS_ADD } from "helpers/Paths";
import AddInventoryByFile from "views/Inventory/AddInventoryByFile";
import { URL_ADD_INVENTORY_BY_FILE } from "helpers/Paths";
import RaiseIssue from "views/RaiseIssue/RaiseIssue";
import ConnectUs from "views/ConnectUs/ConnectUs";
import { URL_REORDER_STOCK_REPORT } from "helpers/Paths";
import Sitemap from "components/Sitemap/Sitemap";
import { URL_SITEMAP } from "helpers/Paths";
import DemoLogin from "views/Login/DemoLogin";
import { URL_DEMO_LOGIN } from "helpers/Paths";
import BalanceSheetReport from "views/Inventory/BalanceSheetReport";
import { URL_BALANCESHEET_REPORT } from "helpers/Paths";

const routes = [
  // {
  //   path: URL_DASHBOARD,
  //   name: "Dashboard",
  //   icon: dashboard,
  //   component: <Dashboard />,
  //   layout: "/admin",
  // },
  {
    path: URL_DASHBOARD,
    name: "Dashboard",
    icon: <MdDashboard />,
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: URL_LOGIN,
    name: "Login",
    icon: "nc-icon nc-laptop",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: URL_DEMO_LOGIN,
    name: "Demo Login",
    icon: "nc-icon nc-laptop",
    component: <DemoLogin />,
    layout: "/auth",
  },
  {
    path: URL_OTP,
    name: "OTP",
    icon: "nc-icon nc-laptop",
    component: <Otp />,
    layout: "/auth",
  },
  {
    path: URL_CHANGE_PASSWORD,
    name: "Change Password",
    icon: "nc-icon nc-laptop",
    component: <ChangePassword />,
    layout: "/auth",
  },
  {
    path: URL_SIGNUP,
    name: "Signup",
    icon: "nc-icon nc-laptop",
    component: <Signup />,
    layout: "/auth",
  },
  // {
  //   path: URL_SUPPLIER_SIGNUP,
  //   name: "Signup",
  //   icon: "nc-icon nc-laptop",
  //   component: <SupplierSignup />,
  //   layout: "/auth",
  // },
  {
    path: URL_SIGNUP_WITH_DOCUMENTS,
    name: "Signup With Documents",
    icon: "nc-icon nc-laptop",
    component: <SignupWithDocuments />,
    layout: "/auth",
  },

  // {
  //   path: URL_SIGNUP_TEST,
  //   name: "Signup Test",
  //   icon: "nc-icon nc-laptop",
  //   component: <Test />,
  //   layout: "/auth",
  // },
  {
    path: URL_SIGNUPEMAIL,
    name: "Signup Email",
    icon: "nc-icon nc-laptop",
    component: <SignupEmail />,
    layout: "/auth",
  },
  {
    path: URL_REGISTRATION,
    name: "Registration",
    icon: "nc-icon nc-laptop",
    component: <Registration />,
    layout: "/auth",
  },
  //  {
  //   path: URL_TERMS,
  //   name: "Terms",
  //   icon: "nc-icon nc-laptop",
  //   component: <Terms />,
  //   layout: "/auth",
  // },
  // {
  //   path: URL_BILL_DASHBOARD,
  //   name: "Bills",
  //   icon: "nc-icon nc-paper",
  //   component: <BillDashboard />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_CREATE_BILL,
  //   name: "Create Bill",
  //   icon: create_bill,
  //   component: <CreateBill />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_EDIT_BILL,
  //   name: "Edit Bill",
  //   icon: "nc-icon nc-paper",
  //   component: <EditBill />,
  //   layout: "/admin",
  // },
  {
    collapse: true,
    name: "Bills",
    icon: <MdOutlineReceipt />,
    state: "billsCollapse",
    views: [
      {
        path: URL_CREATE_BILL,
        name: "Create Bill",
        icon: <AiFillFileAdd />,
        component: <NewCreateBill />,
        layout: "/admin",
      },

      // {
      //   path: URL_REFUND_BILL,
      //   name: "Refund Bill",
      //   icon: refund_bill,
      //   component: <RefundBill />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_BILL_V2,
      //   name: "Lab Report",
      //   icon: labreport,
      //   component: <Billv2 />,
      //   layout: "/admin",
      // },
      {
        path: URL_BILL_DASHBOARD,
        name: "Bills Report",
        icon: <MdAttachMoney />,
        component: <BillDashboard />,
        layout: "/admin",
      },
      {
        path: URL_Template,
        name: "Combo Template (Offers)",
        icon: <HiTemplate />,
        component: <Template />,
        layout: "/admin",
      },
      // {
      //   path: URL_CREATE_BILL_PREVIEW,
      //   name: "Create Bill Preview",
      //   icon: null,
      //   component: <BillTest />,
      //   layout: "/admin",
      // },
    ],
  },
  {
    collapse: true,
    name: "Inventory",
    icon: <MdInventory />,
    state: "inventoryCollapse",
    views: [
      {
        path: URL_INVENTORY_PRODUCTS_LIST,
        name: "Products List",
        icon: <GiMedicines />,
        component: <Products />,
        layout: "/admin",
      },
      {
        path: URL_INVENTORY_PRODUCTS_ADD,
        name: "Add Products",
        icon: <FaEdit />,
        component: <AddProduct />,
        layout: "/admin",
      },
      {
        path: URL_ADD_INVENTORY_BY_FILE,
        name: "Add Inventory By File",
        icon: <IoFileTrayFullSharp />,
        component: <AddInventoryByFile />,
        layout: "/admin",
      },
      // {
      //   path: URL_INVENTORY_MANAGEMENT_MANUAL,
      //   name: "Inventory Manual",
      //   component: <AddInventoryNew />,
      //   icon: null,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_INVENTORYMANUAL,
      //   name: "Inventory Manual",
      //   component: <Inventorymanual />,
      //   icon: <FaEdit />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_INVENTORY_MANAGEMENT_BY_FILE,
      //   name: "Inventory By File",
      //   icon: <IoFileTrayFullSharp />,
      //   component: <UploadFileInventory />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_INVENTORYHISTORY,
      //   name: "Inventory History",
      //   icon: <MdOutlineHistory />,
      //   component: <InventoryHistory />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_AUTOINVENTORY,
      //   name: "Auto Inventory",
      //   icon: <MdOutlineAutoMode />,
      //   component: <AutoInventory />,
      //   layout: "/admin",
      // },
      {
        path: URL_INVENTORYDISCOUNTS,
        name: "Discounts",
        component: <InventoryDiscounts />,
        icon: <CiDiscount1 />,
        layout: "/admin",
      },
    ],
  },
  // {
  //   path: URL_Template,
  //   name: "Product Template",
  //   icon: template,
  //   component: <Template />,
  //   layout: "/admin",
  // },
  {
    collapse: true,
    name: "Customer",
    icon: <MdPeopleAlt />,
    state: "notificationCollapse",
    views: [
      {
        path: URL_CUSTOMER_LIST,
        name: "Customer List",
        icon: <FaUsers />,
        component: <CustomersList />,
        layout: "/admin",
      },
      {
        path: URL_MANAGE_CUSTOMER,
        name: "Manage Customers",
        icon: <MdManageAccounts />,
        component: <AddModifyCustomer />,
        layout: "/admin",
      },
      {
        path: URL_NOTIFICATIONSCHEDULE_REPORT,
        name: "Notification Report",
        icon: <LiaFileSignatureSolid />,
        component: <NotificationReport />,
        layout: "/admin",
      },
      {
        path: URL_NOTIFICATIONSCHEDULE,
        name: "Schedule Notification",
        icon: <MdNotificationsActive />,
        component: <NotificationSchedule />,
        layout: "/admin",
      },
      // {
      //   path: URL_EMAIL,
      //   name: "Email send",
      //   icon: <MdEmail />,
      //   component: <Email />,
      //   layout: "/admin",
      // },
      {
        path: URL_EMAIL,
        name: "Email send",
        path: URL_BROADCAST_NOTIFICATION,
        name: "Broadcast Notification",
        icon: <MdEmail />,
        component: <BroadcastNotification />,
        layout: "/admin",
      },
      // {
      //   path: URL_WHATSAPP_SMS,
      //   name: "Whatsapp SMS send",
      //   icon: <MdEmail />,
      //   component: <WPSMS />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_SMS,
      //   name: "SMS send",
      //   icon: <MdOutlineMessage />,
      //   component: <SMS />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_FEEDBACK,
      //   name: "Feedback",
      //   component: <Feedback />,
      //   layout: "/admin",
      //   icon: <MdOutlineReviews />,
      // },
      // {
      //   path: URL_ACTIVITY_LOGS,
      //   name: "Activity Logs",
      //   component: <ActivityLog />,
      //   layout: "/admin",
      //   icon: <FiActivity />,
      // },
    ],
  },
  {
    collapse: true,
    name: "Payment",
    icon: <BsCreditCard />,
    state: "paymentCollapse",
    views: [
      {
        path: URL_PAYMENT,
        name: "Create Transaction",
        icon: <MdNoteAdd />,
        component: <Payment />,
        layout: "/admin",
      },
      {
        path: URL_ACCOUNT_PAYABLE,
        name: "Account Payable",
        icon: <FaHandHoldingDollar />,
        component: <AccountPayable />,
        layout: "/admin",
      },
      {
        path: URL_ACCOUNT_RECIEVABLE,
        name: "Account Recievable",
        icon: <GiReceiveMoney />,
        component: <AccountRecievable />,
        layout: "/admin",
      },
      // {
      //   path: URL_PAYMENT,
      //   name: "All Transactions",
      //   icon: null,
      //   component: <Payment />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_COMPANY_PROFILE_V2,
      //   name: "ERP App Setting v2",
      //   component: null,
      //   layout: "/admin",
      //   icon: companyprofile,
      // },
    ],
  },
  {
    collapse: true,
    name: "Analytics",
    icon: <FaRegChartBar />,
    state: "reportCollapse",
    views: [
      {
        path: URL_BALANCESHEET_REPORT,
        name: "Balance Sheet Report",
        icon: <MdOutlineHistory />,
        component: <BalanceSheetReport />,
        layout: "/admin",
      },
      {
        path: URL_INVENTORYHISTORY,
        name: "Inventory History Report",
        icon: <MdOutlineHistory />,
        component: <InventoryHistory />,
        layout: "/admin",
      },
      // {
      //   path: URL_REORDER_STOCK_REPORT,
      //   name: "Re-Order Stock Report",
      //   icon: <MdOutlineHistory />,
      //   component: <ReorderStockReport />,
      //   layout: "/admin",
      // },
      {
        path: URL_HISTORICAL_DEMAND_REPORT,
        name: "Sales Report",
        component: <SalesReoprt />,
        icon: <FaMoneyBillTrendUp size={20} />,
        layout: "/admin",
      },
      {
        path: URL_FORECAST_REPORT,
        name: "Forecast Report",
        component: <ForecastReport />,
        icon: <FaChartLine />,
        layout: "/admin",
      },
      {
        path: URL_INVENTORY_REPORT,
        name: "Inventory Report",
        icon: <FaBoxes />,
        component: <InventoryReport />,
        layout: "/admin",
      },
      {
        path: URL_INVENTORY_EXPIRY_REPORT,
        name: "Inventory Expiry Report",
        icon: <BsStopwatch />,
        component: <InventoryExpiryReport />,
        layout: "/admin",
      },
      // {
      //   path: URL_INVENTORY_REPORT,
      //   name: "Inventory Report",
      //   icon: <FaBoxes />,
      //   component: <InventoryReport />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_FORECASTING_TIME_DEMAND_REPORT,
      //   name: "Forecast (Time Series)",
      //   component: <ForecastingTimeAndDemandReport />,
      //   layout: "/admin",
      //   icon: null,
      // },
      // {
      //   path: URL_REORDER_STOCK_REPORT,
      //   name: "Re-Order Stock",
      //   icon: null,
      //   component: <ReorderStockReport />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_REVENUE_REPORT_LINE_CHART,
      //   name: "Revenue Report Line Chart",
      //   component: <RevenueReportLineChart />,
      //   icon: null,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_REVENUE_REPORT_PIE_CHART,
      //   name: "Revenue Report Pie Chart",
      //   icon: null,
      //   component: <RevenueReportPieChart />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_INVENTORY_FILE_SUMMARY,
      //   name: "Inventory File Summary",
      //   icon: null,
      //   component: <InventoryFileSummary />,
      //   layout: "/admin",
      // },
      // {
      //   path: URL_BEST_SELLING_USAGE_REPORT,
      //   name: "Best-Selling Report",
      //   component: <MaximumStockUsageReport />,
      //   layout: "/admin",
      //   icon: null,
      // },
      // {
      //   path: URL_LEAST_SELLING_USAGE_REPORT,
      //   name: "Least-Selling Report",
      //   component: <MinimumStockUsageReport />,
      //   layout: "/admin",
      //   icon: null,
      // },
      // {
      //   path: URL_FORECASTING_DEMAND_REPORT,
      //   name: "Forecasting Report",
      //   component: <ForecastingAndDemandReport />,
      //   layout: "/admin",
      //   icon: null,
      // },
    ],
  },
  // {
  //   path: URL_COMPANY_PROFILE,
  //   name: "ERP App Setting",
  //   component: <CompanyProfile />,
  //   layout: "/admin",
  //   icon: companyprofile,
  // },
  {
    path: URL_COMPANY_PROFILE_V2,
    name: "ERP App Setting v2",
    component: <CompanyProfilev2 />,
    layout: "/admin",
    icon: companyprofile,
  },

  {
    path: URL_RECENT_BILLS,
    component: <Bills />,
    layout: "/admin",
  },
  {
    path: URL_RECENT_ENVENTORY,
    component: <RecentInventory />,
    layout: "/admin",
  },
  // {
  //   path: URL_SUB_HELP,
  //   component: <QuestionAnswer />,
  //   layout: "/admin",
  // },

  // {
  //   path: URL_STRIPE_PAYMENT,
  //   name: "Stripe Payment",
  //   component: <StripePayment />,
  //   layout: "/admin",
  //   icon: feedback,
  // },
  // {
  //   path: URL_RAZORPAY_PAYMENT,
  //   name: "Razor pay payment",
  //   component: <RazorPayPayment />,
  //   layout: "/admin",
  //   icon: payment,
  // },
  {
    path: URL_COMPANY_PROFILE,
    name: "Company Profile",
    component: <CompanyProfilev2 />,
    layout: "/admin",
    icon: <MdBusiness />,
  },
  // {
  //   collapse: true,
  //   name: "Preview",
  //   icon: <VscOpenPreview />,
  //   state: "previewCollapse",
  //   views: [
  //     {
  //       path: URL_DEMAND_FORECAST,
  //       name: "Demand Forecast",
  //       icon: <AiOutlineStock />,
  //       component: <DemandForecast />,
  //       layout: "/admin",
  //     },
  //     {
  //       path: URL_SPEECH_RECOGNITION,
  //       name: "Speech Recognition",
  //       icon: <SlSpeech />,
  //       component: <SpeechRecognition />,
  //       layout: "/admin",
  //     },
  //     {
  //       path: URL_INVOICE_OCR,
  //       name: "Invoice OCR",
  //       icon: <FaFileInvoiceDollar />,
  //       component: <InvoiceOCR />,
  //       layout: "/admin",
  //     },
  //   ],
  // },
  // {
  //   collapse: true,
  //   name: "Subscription",
  //   icon: <VscOpenPreview />,
  //   state: "previewCollapse",
  //   views: [
  //     {
  //       path: URL_SUBSCRIPTION,
  //       name: "Subscription Details",
  //       component: <Subscription />,
  //       layout: "/admin",
  //       icon: companyprofile,
  //     },
  //     {
  //       path: URL_PlanDetails,
  //       name: "Billing & Usage",
  //       component: <PlanDetails />,
  //       layout: "/admin",
  //       icon: <MdOutlineReviews />,
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: "Help & activity",
    icon: <VscOpenPreview />,
    state: "previewCollapse",
    views: [
      {
        path: URL_COMPANY_PROFILE_V2,
        name: "Company Profile",
        component: null,
        layout: "/admin",
        icon: companyprofile,
      },
      {
        path: URL_SUBSCRIPTION,
        name: "Subscription Details",
        component: <Subscription />,
        layout: "/admin",
        icon: companyprofile,
      },
      {
        path: URL_FEEDBACK,
        name: "Feedback",
        component: <Feedback />,
        layout: "/admin",
        icon: <MdOutlineReviews />,
      },
      {
        path: URL_ACTIVITY_LOGS,
        name: "Activity Logs",
        component: <ActivityLog />,
        layout: "/admin",
        icon: <FiActivity />,
      },
      {
        path: URL_RAISE_ISSUE,
        name: "Raise Issue",
        component: <RaiseIssue />,
        layout: "/admin",
        icon: <MdOutlineReviews />,
      },
      {
        path: URL_CONNECT_US,
        name: "Connect Us",
        component: <ConnectUs />,
        layout: "/admin",
        icon: <FiActivity />,
      },
      {
        path: URL_SITEMAP,
        name: "Sitemap",
        component: <Sitemap />,
        layout: "/admin",
        icon: <FiActivity />,
      },
    ],
  },
  // {
  //   path: URL_REVENUE_REPORT_LINE_CHART,
  //   name: "Revenue Report Line Chart",
  //   icon: "nc-icon nc-chart-bar-32",
  //   component: <RevenueReportLineChart />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_REVENUE_REPORT_PIE_CHART,
  //   name: "Revenue Report Pie Chart",
  //   icon: "nc-icon nc-chart-pie-36",
  //   component: <RevenueReportPieChart />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_INVENTORY_REPORT,
  //   name: "Inventory Report",
  //   icon: "nc-icon nc-box-2",
  //   component: <InventoryReport />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_INVENTORY_MANAGEMENT_MANUAL,
  //   name: "Inventory Manual",
  //   icon: "nc-icon nc-ruler-pencil",
  //   component: <AddInventory />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_INVENTORY_MANAGEMENT_BY_FILE,
  //   name: "Inventory By File",
  //   icon: "nc-icon nc-bullet-list-67",
  //   component: <UploadFileInventory />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_INVENTORY_FILE_SUMMARY,
  //   name: "Inventory File Summary",
  //   icon: "nc-icon nc-single-copy-04",
  //   component: <InventoryFileSummary />,
  //   layout: "/admin",
  // },
  {
    path: URL_INVENTORY_FILE_SUMMARY_DETAIL,
    name: "Inventory File Summary Detail",
    icon: "nc-icon nc-zoom-split",
    component: <InventoryFileSummaryDetail />,
    layout: "/admin",
  },
  {
    path: URL_TECHNICAL_ALERT,
    name: "TechnicalAlert",
    icon: "nc-icon nc-laptop",
    component: <TechnicalAlert />,
    layout: "/alert",
  },
  {
    path: URL_SUBSCRIPTION,
    name: "Subscription",
    icon: <PiSquaresFour />,
    component: <Subscription />,
    layout: "/admin",
  },
  {
    path: URL_PlanDetails,
    name: "Billing & Usage Overview",
    icon: <MdAssessment />,
    component: <PlanDetails />,
    layout: "/admin",
  },
  // {
  //   path: URL_CreateBillKeyPage,
  //   name: "Create Bill Key Test Page",
  //   icon: <PiSquaresFour />,
  //   component: <CreateBillKeyPage />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_CUSTOMER_HELP,
  //   name: "help center",
  //   icon: helpcenter,
  //   component: <CustomerHelp />,
  //   layout: "/admin",
  // },
  {
    path: "/document-details",
    name: "Document Details Page",
    icon: document,
    component: <DocumentDetailsPage />,
    layout: "/auth",
  },
  // {
  //   path: URL_CUSTOM_PAGE,
  //   name: "custome page",
  //   icon: helpcenter,
  //   component: <CustomPage />,
  //   layout: "/admin",
  // },

  // {
  //   path: URL_QR_CODE_GENERATOR,
  //   name: "QR Code",
  //   icon: helpcenter,
  //   component: <QRCodeGenerator />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_BEST_SELLING_USAGE_REPORT,
  //   name: "Best-Selling Report",
  //   component: <MaximumStockUsageReport />,
  //   layout: "/admin",
  //   icon: "nc-icon nc-chart-bar-32",
  // },
  // {
  //   path: URL_LEAST_SELLING_USAGE_REPORT,
  //   name: "Least-Selling Report",
  //   component: <MinimumStockUsageReport />,
  //   layout: "/admin",
  //   icon: "nc-icon nc-button-pause",
  // },
  // {
  //   path: URL_HISTORICAL_DEMAND_REPORT,
  //   name: "Demand Report",
  //   component: <HistoricalDemandReport />,
  //   layout: "/admin",
  //   icon: "nc-icon nc-cart-simple",
  // },
  // {
  //   path: URL_FORECASTING_DEMAND_REPORT,
  //   name: "Forecasting Report",
  //   component: <ForecastingAndDemandReport />,
  //   layout: "/admin",
  //   icon: "nc-icon nc-sound-wave",
  // },

  // {
  //   path: URL_SMS,
  //   name: "SMS send",
  //   icon: "nc-icon nc-chat-33",
  //   component: <SMS />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_EMAIL,
  //   name: "Email send",
  //   icon: "nc-icon nc-email-85",
  //   component: <Email />,
  //   layout: "/admin",
  // },
  // {
  //   path: URL_WHATSAPP_SMS,
  //   name: "Whatsapp SMS send",
  //   icon: "nc-icon nc-send",
  //   component: <WPSMS />,
  //   layout: "/admin",
  // },

  // {
  //   path: URL_NOTIFICATIONSCHEDULE,
  //   name: "NotificationSchedule",
  //   icon: notification,
  //   component: <NotificationSchedule />,
  //   layout: "/admin",
  // },
];

export default routes;
