import React, { useEffect, useRef, useState } from "react";
import "../../assets/styles/Inventory.css";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  Popover,
  PopoverBody,
  Row,
  UncontrolledPopover,
  Card,
  CardBody,
} from "reactstrap";
import CustomButton from "components/Button/CustomButton";
import { Column } from "primereact/column";
import { FaEdit } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { useCreateBillStore } from "hooks/CreateBill";
import "../../assets/styles/AdvanceFilterModal.css";
import ChildLoader from "components/Loader/ChildLoader";
import { DataTable } from "primereact/datatable";
import { useLoginStore } from "hooks/Login";
import tablefilter from "assets/img/icons/createBill/tablefilter.png";
import close from "assets/img/icons/createBill/close.png";
import addicon from "assets/img/icons/createBill/add.png";
import "../../assets/styles/Invoice.css";
import loader from "assets/img/icons/createBill/loader.gif";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { IoClose } from "react-icons/io5";
import { FaFilter } from "react-icons/fa6";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import Common from "helpers/Common";
import { useNavigate } from "react-router-dom";
import { URL_INVENTORY_PRODUCTS_ADD } from "helpers/Paths";
import RecentInventory from "./RecentInventory";

const categories = [
  { key: "productName", label: "Product Name" },
  { key: "brand", label: "Brand" },
  { key: "upc_code", label: "UPC Code" },
  { key: "sku", label: "SKU" },
  { key: "productDescription", label: "Product Description" },
];

const Products = () => {
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [uniqueSKUs, setUniqueSKUs] = useState([]);
  const [uniqueBrands, setUniqueBrands] = useState([]);
  const [uniqueCategoryNames, setUniqueCategoryNames] = useState([]);
  const [uniqueSubCategoryNames, setUniqueSubCategoryNames] = useState([]);
  const [uniquesProductNames, setUniqueProductNames] = useState([]);
  const [uniqueUPCCodes, setUniqueUPCCodes] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    sku: [],
    brand: [],
    categoryName: [],
    subCategoryName: [],
    productName: [],
    upc_code: [],
    netPrice: { min: 0, max: 0 },
    unitPrice: { min: 0, max: 0 },
    taxAmount: { min: 0, max: 0 },
    available_qty: { min: 0, max: 0 },
  });
  const [filters, setFilters] = useState({
    productName: "",
    brand: "",
    upc_code: "",
    sku: "",
    productDescription: "",
  });
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [{}, {}, { setAlerts }] = useLoginStore();

  const pageSize = new CompanyProfileRepository().getPageSize();

  const toggleFilterModal = () => setFilterModal(!filterModal);

  const dt = useRef(null);

  const navigate = useNavigate();

  const togglePopover = (field) => {
    // Close all popovers first
    const resetPopoverOpen = Object.keys(popoverOpen).reduce((acc, key) => {
      acc[key] = false;
      return acc;
    }, {});

    setPopoverOpen({
      ...resetPopoverOpen,
      [field]: !popoverOpen[field],
    });
  };

  const handleFilterChange = (field, value, isChecked) => {
    if (isChecked) {
      // Add to filter
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [field]: [...prevFilters[field], value],
      }));
    } else {
      // Remove from filter
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        [field]: prevFilters[field].filter((item) => item !== value),
      }));
    }
  };

  const handleNumericalFilterChange = (field, type, value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [field]: {
        ...prevFilters[field],
        [type]: parseInt(value),
      },
    }));
  };

  useEffect(() => {
    const SKUs = [...new Set(searchResults.map((item) => item.sku))];
    const brands = [...new Set(searchResults.map((item) => item.brand))];
    const categoryNames = [
      ...new Set(searchResults.map((item) => item.categoryName)),
    ];
    const subCategoryNames = [
      ...new Set(searchResults.map((item) => item.subCategoryName)),
    ];
    const productNamesList = [
      ...new Set(searchResults.map((item) => item.productName)),
    ];
    const upc_code_list = [
      ...new Set(searchResults.map((item) => item.upc_code)),
    ];

    setUniqueSKUs(SKUs);
    setUniqueBrands(brands);
    setUniqueCategoryNames(categoryNames);
    setUniqueSubCategoryNames(subCategoryNames);
    setUniqueUPCCodes(upc_code_list);
    setUniqueProductNames(productNamesList);

    // applyFilters();
  }, [selectedFilters, searchResults]);

  const [{}, { getProductAdvancedSearch }, {}] = useCreateBillStore();

  const applyFilters = () => {
    const filteredResults = searchResults.filter((item) => {
      const skuFilter =
        selectedFilters.sku.length === 0 ||
        selectedFilters.sku.includes(item.sku);
      const brandFilter =
        selectedFilters.brand.length === 0 ||
        selectedFilters.brand.includes(item.brand);
      const categoryNameFilter =
        selectedFilters.categoryName.length === 0 ||
        selectedFilters.categoryName.includes(item.categoryName);
      const subCategoryNameFilter =
        selectedFilters.subCategoryName.length === 0 ||
        selectedFilters.subCategoryName.includes(item.subCategoryName);
      const productNameFilter =
        selectedFilters.productName.length === 0 ||
        selectedFilters.productName.includes(item.productName);
      const upc_codeFilter =
        selectedFilters.upc_code.length === 0 ||
        selectedFilters.upc_code.includes(item.upc_code);

      // Convert item values to numbers if they are strings
      const itemNetPrice = Number(item.netPrice);
      const itemUnitPrice = Number(item.unitPrice);
      const itemTaxAmount = Number(item.taxAmount);
      const itemAvailableQty = Number(item.available_qty);

      const netPriceFilter =
        (selectedFilters.netPrice?.min === undefined ||
          selectedFilters.netPrice?.min === 0 ||
          itemNetPrice >= Number(selectedFilters.netPrice?.min)) &&
        (selectedFilters.netPrice?.max === undefined ||
          selectedFilters.netPrice?.max === 0 ||
          itemNetPrice <= Number(selectedFilters.netPrice?.max));
      const unitPriceFilter =
        (selectedFilters.unitPrice?.min === undefined ||
          selectedFilters.unitPrice?.min === 0 ||
          itemUnitPrice >= Number(selectedFilters.unitPrice?.min)) &&
        (selectedFilters.unitPrice?.max === undefined ||
          selectedFilters.unitPrice?.max === 0 ||
          itemUnitPrice <= Number(selectedFilters.unitPrice?.max));
      const taxAmountFilter =
        (selectedFilters.taxAmount?.min === undefined ||
          selectedFilters.taxAmount?.min === 0 ||
          itemTaxAmount >= Number(selectedFilters.taxAmount?.min)) &&
        (selectedFilters.taxAmount?.max === undefined ||
          selectedFilters.taxAmount?.max === 0 ||
          itemTaxAmount <= Number(selectedFilters.taxAmount?.max));
      const availableQtyFilter =
        (selectedFilters.available_qty?.min === undefined ||
          selectedFilters.available_qty?.min === 0 ||
          itemAvailableQty >= Number(selectedFilters.available_qty?.min)) &&
        (selectedFilters.available_qty?.max === undefined ||
          selectedFilters.available_qty?.max === 0 ||
          itemAvailableQty <= Number(selectedFilters.available_qty?.max));

      // Apply all filters
      return (
        skuFilter &&
        brandFilter &&
        categoryNameFilter &&
        subCategoryNameFilter &&
        productNameFilter &&
        upc_codeFilter &&
        netPriceFilter &&
        unitPriceFilter &&
        taxAmountFilter &&
        availableQtyFilter
      );
    });

    setFilteredData(filteredResults);
    setTableData(filteredResults.slice(0, pageSize));
    setPage(1);
    setIsFilterApplied(true);
  };

  const renderPopoverBody = (field) => {
    const uniqueValues = [...new Set(searchResults.map((item) => item[field]))];

    return (
      <PopoverBody>
        <div
          style={{
            maxHeight: "200px",
            overflowY: "auto",
          }}
          className="scrollbar-custom"
        >
          <div className="filter-options">
            {uniqueValues.map((value, index) => (
              <div key={index} className="filter-option">
                <input
                  type="checkbox"
                  id={`${field}-${value}`}
                  className="filter-checkbox"
                  checked={selectedFilters[field].includes(value)}
                  onChange={(e) =>
                    handleFilterChange(field, value, e.target.checked)
                  }
                />
                <label htmlFor={`${field}-${value}`} className="filter-label">
                  {value}
                </label>
              </div>
            ))}
          </div>
        </div>
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            togglePopover(field);
            applyFilters();
          }}
        >
          Apply
        </Button>

        <Button
          color="secondary"
          size="sm"
          onClick={() => togglePopover(field)}
          style={{ marginLeft: "5px" }}
        >
          Cancel
        </Button>
      </PopoverBody>
    );
  };

  const renderPopoverBodyNumerical = (field) => {
    return (
      <PopoverBody>
        <div style={{ padding: "10px" }}>
          <div>
            <Label htmlFor={`min-${field}`} className="numerical_filter">
              Min:
            </Label>
            <Input
              id={`min-${field}`}
              type="number"
              className="numerical_filter"
              value={selectedFilters[field]?.min ?? ""}
              onChange={(e) => {
                const value =
                  e.target.value === "" ? null : Number(e.target.value);
                handleNumericalFilterChange(field, "min", value);
              }}
              placeholder="Min"
              style={{ marginBottom: "5px" }}
            />
          </div>
          <div>
            <Label htmlFor={`max-${field}`} className="numerical_filter">
              Max:
            </Label>
            <Input
              id={`max-${field}`}
              type="number"
              className="numerical_filter"
              value={selectedFilters[field]?.max ?? ""}
              onChange={(e) => {
                const value =
                  e.target.value === "" ? null : Number(e.target.value);
                handleNumericalFilterChange(field, "max", value);
              }}
              placeholder="Max"
            />
          </div>

          <Button
            color="primary"
            size="sm"
            onClick={() => {
              togglePopover(field);
              applyFilters();
            }}
          >
            Apply
          </Button>

          <Button
            color="secondary"
            size="sm"
            onClick={() => togglePopover(field)}
            style={{ marginLeft: "5px" }}
          >
            Cancel
          </Button>
        </div>
      </PopoverBody>
    );
  };

  const handleLoadMore = () => {
    setLoadingMore(true);
    setPage((prev) => prev + 1);

    if (isFilterApplied) {
      const newValues = filteredData.slice(
        page * pageSize,
        page * pageSize + pageSize
      );

      setTableData([...tableData, ...newValues]);
    } else {
      const newValues = searchResults.slice(
        page * pageSize,
        page * pageSize + pageSize
      );

      setTableData([...tableData, ...newValues]);
    }

    setLoadingMore(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleApplyFilters = async (e) => {
    e.preventDefault();
    setIsFilterApplied(false);
    setLoading(true);

    try {
      // Trim whitespace for each filter parameter
      const trimmedFilters = {
        productName: filters.productName ? filters.productName.trim() : "",
        brand: filters.brand ? filters.brand.trim() : "",
        upC_Code: filters.upc_code ? filters.upc_code.trim() : "",
        productDescription: filters.productDescription
          ? filters.productDescription.trim()
          : "",
        sku: filters.sku ? filters.sku.trim() : "",
      };

      // Check if all filters are empty
      if (
        !trimmedFilters.productName &&
        !trimmedFilters.brand &&
        !trimmedFilters.upC_Code &&
        !trimmedFilters.productDescription &&
        !trimmedFilters.sku
      ) {
        await setAlerts({
          type: "danger",
          message: "Please Input at least one filter.",
        });
        setSearchResults([]);
        setTableData([]);
        setLoading(false);
        return;
      }

      // Pass trimmed filters to the search function
      const data = await getProductAdvancedSearch(trimmedFilters);


      setSearchResults(data);

      const firstPage = data.slice(0, pageSize);

      setTableData([...firstPage]);

      setSelectedFilters({
        sku: [],
        brand: [],
        categoryName: [],
        subCategoryName: [],
        productName: [],
        upc_code: [],
        netPrice: { min: 0, max: 0 },
        unitPrice: { min: 0, max: 0 },
        taxAmount: { min: 0, max: 0 },
        available_qty: { min: 0, max: 0 },
      });

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSelectedFilters({
        sku: [],
        brand: [],
        categoryName: [],
        subCategoryName: [],
        productName: [],
        upc_code: [],
        netPrice: { min: 0, max: 0 },
        unitPrice: { min: 0, max: 0 },
        taxAmount: { min: 0, max: 0 },
        available_qty: { min: 0, max: 0 },
      });
    }
  };

  const handleAddRow = (rowData) => {
    // console.log(index);
    if (index >= 0) {
      // console.log(index);
      updateRowProduct(index, rowData);
    } else {
      // console.log("data added successfully");
      addRow(rowData);
    }

    setFilters({
      productName: "",
      brand: "",
      upc_code: "",
      sku: "",
      productDescription: "",
    });
    setTableData([]);
    setSearchResults([]);
    setSelectedProducts("");
    toggleFilterModal();
    setIsFilterApplied(false);
    setPage(1);
    setSelectedFilters({
      sku: [],
      brand: [],
      categoryName: [],
      subCategoryName: [],
      productName: [],
      upc_code: [],
      netPrice: { min: 0, max: 0 },
      unitPrice: { min: 0, max: 0 },
      taxAmount: { min: 0, max: 0 },
      available_qty: { min: 0, max: 0 },
    });
  };

  const handleRemoveFilter = (filterKey) => {
    setFilters({ ...filters, [filterKey]: "" });
  };

  const headers = {
    productName: "ProductName",
    sku: "SKU",
    brand: "Brand",
    categoryName: "CategoryName",
    subCategoryName: "SubCategoryName",
    netPrice: "NetPrice",
    unitPrice: "UnitPrice",
    upc_code: "UPC Code",
    available_qty: "AvailableQuantity",
    taxAmount: "TaxAmount",
    expiryDate: "ExpiryDate",
  };

  // Action Column => to show edit button
  const ActionColTemplate = ({ rowData }) => {
    return (
      <div className="payment-list-action-col">
        <div
          className="d-flex align-items-center"
          style={{ gap: "10px", cursor: "pointer" }}
          onClick={() =>
            navigate(`/admin${URL_INVENTORY_PRODUCTS_ADD}`, {
              state: { productId: rowData?.productId },
            })
          }
        >
          Edit <FaEdit size={20} />
        </div>
      </div>
    );
  };

  const highlightText = (text, filterText) => {
    if (!filterText || !text) return text; // If no filter or text, return as is

    // Ensure both text and filterText are treated as strings
    const textStr = String(text);
    const filterStr = String(filterText);

    const regex = new RegExp(`(${filterStr})`, "gi"); // Create a case-insensitive regex
    return textStr.replace(
      regex,
      '<span style="background-color: yellow;">$1</span>'
    );
  };

  return (
    <div className="content-dashboard-inventory">
      <div className="title-route-container menu-title-div">
        <h4 className="menu-title-h">Inventory / Products List</h4>
      </div>

      <Card className="inventory-product-card">
        <CardBody style={{ padding: "30px 20px" }}>
          <form id="product-filter-form" name="product-filter-form">
            <Row form className="filter-form">
              {categories.map(({ key, label }) => (
                <Col xs="6" md="6" lg="2" key={key}>
                  <FormGroup key={key}>
                    <Label for={key}>{label}</Label>
                    <Input
                      id={key}
                      type="text"
                      name={key}
                      value={filters[key]}
                      onChange={handleInputChange}
                      placeholder={`Enter ${label}`}
                      className="filter-input"
                    />
                  </FormGroup>
                </Col>
              ))}
              <Col
                xs="6"
                md="6"
                lg="2"
                className="d-flex justify-content-center align-items-center"
                style={{
                  paddingTop: "10px",
                }}
              >
                <CustomButton
                  label={
                    loading ? (
                      <>
                        <img
                          src={`${ERP_CDN_IMAGE_URL}/loader.gif`}
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop if logo also fails
                            e.target.src = loader;
                          }}
                          alt="+"
                          className="create-bill-icon"
                        />
                        <span className="loading-text">Applying...</span>{" "}
                      </>
                    ) : (
                      "Apply Filters"
                    )
                  }
                  disabled={loading}
                  onClick={handleApplyFilters}
                  customStyle="m-0"
                />
              </Col>
            </Row>
          </form>
          <div className="filter-container">
            <div className="badge-container">
              Applied Filters:
              {categories
                .filter(({ key }) => filters[key])
                .map(({ key, label }) => (
                  <div key={key} className="filter-badge">
                    {`${label}: ${filters[key]}`}
                    <IoClose
                      className="badge-close"
                      onClick={() => handleRemoveFilter(key)}
                    />
                  </div>
                ))}
            </div>

            {loading ? (
              <ChildLoader />
            ) : (
              <>
                {searchResults.length > 0 ? (
                  <>
                    <DataTable
                      resizableColumns
                      ref={dt}
                      value={tableData ?? []}
                      dataKey="billID"
                      tableStyle={{ minWidth: "25rem" }}
                      className="custom-table"
                      style={{ minWidth: "25rem" }}
                      readOnly
                      pt={{
                        root: { className: "table" },
                      }}
                      paginator
                      rows={5}
                      rowsPerPageOptions={[5, 10, 15]}
                    >
                      <Column
                        field=""
                        header="Actions"
                        body={(rowData) => (
                          <ActionColTemplate rowData={rowData} />
                        )}
                      />
                      {[
                        "productName",
                        "sku",
                        "brand",
                        "categoryName",
                        "subCategoryName",
                        "upc_code",
                      ].map((field) => (
                        <Column
                          key={field}
                          field={field}
                          headerStyle={{ textAlign: "center" }}
                          bodyStyle={{ textAlign: "center" }}
                          header={
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  padding: "10px 10px",
                                }}
                              >
                                {headers[field]}

                                <FaFilter
                                  id={`Popover-${field}`}
                                  onClick={() => togglePopover(field)}
                                  size={15}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "grey",
                                  }}
                                />

                                <UncontrolledPopover
                                  placement="bottom"
                                  isOpen={popoverOpen[field]}
                                  target={`Popover-${field}`}
                                  toggle={() => togglePopover(field)}
                                >
                                  {renderPopoverBody(field)}
                                </UncontrolledPopover>
                              </div>
                            </>
                          }
                          body={(rowData) => {
                            const value = rowData[field] || "";
                            const filterValue = filters[field];
                            return (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: highlightText(value, filterValue),
                                }}
                              />
                            );
                          }}
                        />
                      ))}

                      {/* Numerical filter columns */}
                      {[
                        "netPrice",
                        "unitPrice",
                        "taxAmount",
                        "available_qty",
                      ].map((field) => (
                        <Column
                          key={field}
                          field={field}
                          headerStyle={{ textAlign: "center" }}
                          bodyStyle={{ textAlign: "center" }}
                          header={
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  padding: "10px 10px",
                                }}
                              >
                                {headers[field]}

                                <FaFilter
                                  id={`Popover-${field}`}
                                  onClick={() => togglePopover(field)}
                                  size={15}
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "grey",
                                  }}
                                />

                                <UncontrolledPopover
                                  placement="bottom"
                                  isOpen={popoverOpen[field]}
                                  target={`Popover-${field}`}
                                  toggle={() => togglePopover(field)}
                                >
                                  {renderPopoverBodyNumerical(field)}
                                </UncontrolledPopover>
                              </div>
                            </>
                          }
                        />
                      ))}

                      <Column
                        bodyStyle={{ textAlign: "center" }}
                        field="expiryDate"
                        dataType="date"
                        body={(rowData) => {
                          try {
                            return Common.getFormatedDate(
                              rowData.expiryDate,
                              "DD/MM/YYYY hh:mm:ss A",
                              true
                            );
                          } catch (error) {
                            console.error(error.message);
                            return "Invalid Date"; // or any fallback display text
                          }
                        }}
                        header="Expiry Date"
                      ></Column>
                    </DataTable>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </CardBody>
      </Card>
      <RecentInventory></RecentInventory>
    </div>
  );
};

export default Products;
