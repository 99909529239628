import Common from "helpers/Common";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import { useCompanyStore } from "hooks/Company";
import { useLoginStore } from "hooks/Login";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/styles/MultiStepRegister.css";
import erp from "assets/img/icons/Auth/erp.png";
import SocialMediaIcons from "./SocialMediaIcons";
import { Input } from "reactstrap";
import axios from "axios";
import { AUTH_URL_LOGIN } from "helpers/Paths";

const SignupWithDocuments = () => {
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [{}, {}, { createCompany, createCompanyUser }] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(false);
  const isLogin = Common.getJwtToken();
  const navigate = useNavigate();
  const [documentType, setDocumentType] = useState("");
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);
  const [frontPreview, setFrontPreview] = useState("");
  const [backPreview, setBackPreview] = useState("");
  const [stage, setStage] = useState("front");

  const handleDocumentChange = (event) => {
    const type = event.target.value;
    setDocumentType(type);
    resetUpload();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (documentType === "MCA document" || documentType === "MCA Pancard") {
      // For MCU documents, immediately set the file and preview
      setFrontFile(file);
      if (file.type === "application/pdf") {
        const tempUrl = URL.createObjectURL(file);
        setFrontPreview(tempUrl);
      } else {
        // Use FileReader for images to convert to DataURL
        const reader = new FileReader();
        reader.onloadend = () => {
          setFrontPreview(reader.result);
        };
        reader.readAsDataURL(file);
      }
      setStage("confirmed"); // For MCU, skip to confirmed stage
    } else {
      // For Aadhar Card and Passport, process front and back images
      const reader = new FileReader();
      reader.onloadend = () => {
        if (stage === "front") {
          setFrontFile(file);
          setFrontPreview(reader.result);
        } else {
          setBackFile(file);
          setBackPreview(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const confirmFront = () => {
    if (documentType === "aadhar card" || documentType === "passport") {
      setStage("back");
    }
  };

  const confirmBack = () => {
    setStage("confirmed");
  };

  const resetUpload = () => {
    setFrontFile(null);
    setBackFile(null);
    setFrontPreview("");
    setBackPreview("");
    setStage("front");
  };

  const fileInputProps = {
    accept: "image/*",
  };

  useEffect(() => {
    checkIfAlreadyLoggedIn();
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const checkIfAlreadyLoggedIn = () => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    }
  };
  const uploadDocumentCompany = async () => {
    setIsLoading(true);
    // console.log("Upload document functionality to be implemented.");
    const formData = new FormData();
    if (frontFile) {
      formData.append("uploaded_file", frontFile);
      formData.append("uploaded_file_type", frontFile.type);
    }

    formData.append("documentType", documentType);

    let apiUrl =
      "https://pythonhelperapi.azurewebsites.net/company-document-ocr/";
    if (documentType === "MCA Pancard") {
      apiUrl = "https://pythonhelperapi.azurewebsites.net/pancard-front/";
    }

    // console.log(apiUrl);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // console.log("Upload successful", response.data);

      setIsLoading(false);

      await setAlerts({
        type: "success",
        message: `${documentType} uploaded successfully.`,
      });

      navigate("/auth/document-details", { state: { details: response.data } });
    } catch (error) {
      setIsLoading(false);
      await setAlerts({
        type: "danger",
        message: "Upload Failed: " + error.message || "Unknown Error",
      });
      console.error("Upload failed", error);
    }
  };

  const uploadDocumentUser = async () => {
    setIsLoading(true);
    // console.log("Upload user document functionality to be implemented.");
    const formData = new FormData();
    if (frontFile) {
      formData.append("frontImage", frontFile);
      formData.append("frontImageType", frontFile.type);
    }
    if (backFile) {
      formData.append("backImage", backFile);
      formData.append("backImageType", backFile.type);
    }

    formData.append("documentType", documentType);
    try {
      const response = await axios.post(
        "https://pythonhelperapi.azurewebsites.net/user-document-ocr/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsLoading(false);
      await setAlerts({
        type: "success",
        message: `${documentType} uploaded successfully.`,
      });

      navigate("/auth/document-details", { state: { details: response.data } });
      // Handle success scenario
    } catch (error) {
      setIsLoading(false);
      await setAlerts({
        type: "danger",
        message: "Upload Failed: " + error.message || "Unknown Error",
      });
      console.error("Upload failed", error);
      // Handle failure scenario
    }
  };

  return (
    <>
      {/* <SocialMediaIcons /> */}
      {/* <div className="register-container-document">
        <div className="card-registration-document">
          <div className="registration-header">
            <h3 className="gradient-text">Welcome to ERP</h3>
            <p className="registration-header text-center mt-0">
              <Link
                to="https://apsthreeai.ai/"
                className="dark-header-title"
                target="_blank"
              >
                <img className="mr-2 login-logo" src={erp} alt="login-logo" />|
                Your Trusted ERP Partner
              </Link>
            </p>
          </div> */}

      <div className="sign-up-with-docs-container">
        <div className="header-container">
          <div className="header-title">Please Select Your Document</div>
          <div className="step-indicator">
            {" "}
            <Input
              id="exampleSelect"
              name="select"
              type="select"
              value={documentType}
              onChange={handleDocumentChange}
              className="custom-select"
            >
              <option value="">Select Document Type</option>
              <option value="aadhar card">Aadhar Card</option>
              <option value="passport">Passport</option>
              <option value="MCA document">MCA Document</option>
              <option value="MCA Pancard">Pancard</option>
            </Input>
          </div>

          <div className="uploader">
            {(documentType === "aadhar card" ||
              documentType === "passport") && (
              <>
                {stage !== "confirmed" && (
                  <>
                    {stage === "front" && (
                      <div className="front-back text-center">
                        Upload Front Page of {documentType}
                      </div>
                    )}
                    {stage === "back" && (
                      <div className="front-back  text-center">
                        Upload Back Page of {documentType}
                      </div>
                    )}
                    <input
                      type="file"
                      onChange={handleFileChange}
                      {...fileInputProps}
                    />
                  </>
                )}
                {frontPreview && stage === "front" && (
                  <>
                    <img
                      src={frontPreview}
                      alt="Front Preview"
                      className="preview"
                    />
                    <button className="custom-login-btn" onClick={confirmFront}>
                      Confirm Front
                    </button>
                  </>
                )}
                {backPreview && stage === "back" && (
                  <>
                    <img
                      src={backPreview}
                      alt="Back Preview"
                      className="preview"
                    />
                    <button className="custom-login-btn" onClick={confirmBack}>
                      Confirm Back
                    </button>
                  </>
                )}
                {stage === "confirmed" && (
                  <>
                    <div className="images-grid">
                      <img src={frontPreview} alt="Front" className="preview" />
                      <img src={backPreview} alt="Back" className="preview" />
                    </div>
                    <button
                      onClick={uploadDocumentUser}
                      disabled={isLoading}
                      className="custom-login-btn"
                    >
                      {isLoading ? "Extracting data..." : "Upload Document"}
                    </button>
                  </>
                )}
              </>
            )}

            {(documentType === "MCA document" ||
              documentType === "MCA Pancard") && (
              <>
                <input
                  type="file"
                  onChange={handleFileChange}
                  {...fileInputProps}
                />
                {frontPreview && (
                  <>
                    <img src={frontPreview} alt="Front" className="preview" />

                    <button
                      onClick={uploadDocumentCompany}
                      disabled={isLoading}
                      className="custom-login-btn"
                    >
                      {isLoading ? "Extracting data..." : "Upload Document"}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
        
        <div>
          <button
            className="custom-login-btn secondary-login-btn"
            onClick={() => navigate(AUTH_URL_LOGIN)} // Redirect on click
          >
            Login
          </button>
        </div>
      </div>
    </>
  );
};

export default SignupWithDocuments;
