import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal,
  ModalBody,
  Container,
  ModalHeader,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useCreateBillStore } from "hooks/CreateBill";
import "../../assets/styles/CreateBill.css";
import { Link, useNavigate } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";
import { ADMIN_URL_CREATE_BILL } from "helpers/Paths";
import ChildLoader from "components/Loader/ChildLoader";
import "index.css";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import { useAzureStore } from "hooks/Azure";
import Common from "helpers/Common";
import { FaDownload, FaEye, FaPlus, FaPrint } from "react-icons/fa6";
import { FaPencilAlt } from "react-icons/fa";
import { RiRefund2Line } from "react-icons/ri";
import "assets/styles/bills/RecentBills.css";
import moment from "moment";
import FilterButton from "views/Report/FilterButton";
import { FaFileCode } from "react-icons/fa6"; // Import the icon for viewing HTML
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { notificationMessages } from "helpers/constantMessages";

const BillDashboard = () => {
  const navigate = useNavigate();

  const [{}, { fetchImagesFromAzure }, {}] = useAzureStore();
  const [{}, { getRecentBill, getBill }, {}] = useCreateBillStore();
  const [{}, {}, { updateGenricData }] = useCompanyProfileStore();

  const [recentBillsData, setRecentBillsData] = useState(null);
  const [selectedRecentBillsData, setSelectedRecentBillsData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loader, setLoader] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState(""); // State to hold the HTML content
  const [selectedFormat, setSelectedFormat] = useState("A4");

  const [invoiceFormat, setinvoiceFormat] = useState("A4"); // State to hold the HTML content
  const [{ taxDetails }, { getGenricData }] = useCompanyProfileStore();

  const [totalCashAmount, setTotalCashAmount] = useState(0);
  const [totalCardAmount, setTotalCardAmount] = useState(0);
  const [totalUPIAmount, setTotalUPIAmount] = useState(0);
  const [totalNetBankingAmount, setTotalNetBankingAmount] = useState(0);
  const [totalRefundAmount, setTotalRefundAmount] = useState(0);

  const dt = useRef(null);

  useEffect(() => {
    const invoiceFormat = JSON.parse(localStorage.getItem("companyProfile"));
    const storedLoginId = localStorage.getItem("loginId");
    const storedCompanyId = localStorage.getItem("companyId");
    if (invoiceFormat) {
      const FORMAT = invoiceFormat.find(
        (item) =>
          item.feature_key ===
          storedCompanyId + "_" + storedLoginId + "_user_invoiceformat"
      );
      if (FORMAT) {
        setinvoiceFormat(FORMAT.feature_value);
        setSelectedFormat(
          FORMAT.feature_value && FORMAT.feature_value.trim() !== ""
            ? FORMAT.feature_value
            : "A4" // Set default selected format
        );
      }
    }
  }, []);

  useEffect(() => {
    async function getData() {
      await getGenricData();
    }
    getData();
  }, []);

  const loadMoreData = () => {
    setLoadingMore(true);
    setPageNumber((prev) => prev + 1);
    getBillsData(pageNumber + 1);
  };

  async function getReport(Date = "", endDate = "", page = 1) {
    setSelectedDate({ fromDate: Date, toDate: endDate });
  }

  const getBillsData = async (page = 1) => {
    if (page === 1) {
      setPageNumber(page);
    }

    try {
      async function getRecentBillsData() {
        let recentBillList = await getRecentBill({
          fromdate: selectedDate.fromDate,
          todate: selectedDate.toDate,
          pagesize: new CompanyProfileRepository().getPageSize(),
          pageNumber: page,
        });

        if (page > 1) {
          setRecentBillsData((prev) => [...prev, ...recentBillList]);
        } else {
          setRecentBillsData(recentBillList);
        }
        setLoader(false);
        setLoadingMore(false);
      }
      getRecentBillsData();
    } catch (error) {
      // for error handling
      setLoadingMore(false);
      setLoader(false);
      alert(error);
    }
  };

  useEffect(() => {
    setLoader(true);
    if (selectedDate) {
      getBillsData();
    }
  }, [selectedDate]);

  useEffect(() => {
    getSalesChart();
  }, []);

  const setTotalWidgetData = () => {
    let totalCardAmount = 0;
    let totalCashAmount = 0;
    let totalUPIAmount = 0;
    let totalNetBankingAmount = 0;
    let totalRefundAmount = 0;
    if (recentBillsData && recentBillsData?.length > 0) {
      recentBillsData.map((item) => {
        switch (item?.paymentMethod?.toLowerCase()) {
          case "cash":
            totalCashAmount += item?.netPayment;
            break;
          case "credit/ debit card":
            totalCardAmount += item?.netPayment;
            break;
          case "upi":
            totalUPIAmount += item?.netPayment;
            break;
          case "net banking":
            totalNetBankingAmount += item?.netPayment;
          default:
            break;
        }

        if (item?.billType?.toLowerCase() === "refund") {
          totalRefundAmount += item?.netPayment;
        }
      });
    }
    setTotalCardAmount(totalCardAmount.toFixed(2));
    setTotalCashAmount(totalCashAmount.toFixed(2));
    setTotalUPIAmount(totalUPIAmount.toFixed(2));
    setTotalNetBankingAmount(totalNetBankingAmount.toFixed(2));
    setTotalRefundAmount(totalRefundAmount.toFixed(2));
  };

  useEffect(() => {
    console.log("totalCardAmount", totalCardAmount, totalCashAmount);
  }, [totalCardAmount, totalCashAmount]);

  useEffect(() => {
    if (recentBillsData && recentBillsData?.length > 0) {
      setTotalWidgetData();
    }
  }, [recentBillsData]);

  const getSalesChart = async function getReport() {
    let labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let salesRevenue = [200, 100, 400, 800, 100, 899, 400];

    const chartDetails = {
      labels,
      datasets: [
        {
          fill: true,
          label: "Sales Revenue",
          data: salesRevenue,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
  };

  const RecentBills = () => {
    //fetch pdf from azure
    const fetchPDF = async (name, type) => {
      let pdfURL = await fetchImagesFromAzure(name, "bill");
      if (type.toLowerCase() === "preview") {
        const response = await fetch(pdfURL);
        // Get the binary data from the response
        const pdfData = await response.blob();
        // Create a Blob from the PDF data
        const file = new Blob([pdfData], { type: "application/pdf" });
        // Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        // Open the URL in a new window
        window.open(fileURL, "_blank");
      } else {
        window.open(pdfURL, "_blank");
      }
    };

    const ActionTemplate = ({ rowData }) => {
      const viewHTML = async () => {
        setLoader(true);
        const fetchBillData = await getBill(rowData.billId);
        // Check if rowData exists and has productInfos, default to an empty array if not
        const productInfos = Array.isArray(fetchBillData.productInfos)
          ? fetchBillData.productInfos
          : [];
        // Check if rowData itself is defined
        if (!fetchBillData) {
          alert("No data available to display!");
          return;
        }

        const htmlContent = `
          <!DOCTYPE html>
          <html>
            <head>
              <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300" />
              <style>
                @page {
                  size: A4 portrait;
                  margin: 0;
                  padding: 0;
                }
                html {
                  font-family: 'Open Sans', sans-serif;
                  color: #1e293b;
                  font-size: 11pt;
                  font-weight: 300;
                  height: 100%;
                }
                body {
                  margin: 0;
                  position: relative;
                  height: 100%;
                }
                .disclaimer {
                  background: #f6f6f6;
                  border-color: #f6f6f6;
                  border-style: solid;
                  border-width: 5mm 2cm;
                  bottom: 0;
                  font-size: 9pt;
                  margin: 0 -2cm .5cm;
                  width: 17cm;
                  display: block;
                  white-space: pre-line;
                }
                aside {
                  display: flex;
                  margin: 7mm 0 14mm;
                  line-height: 1.7rem;
                }
                aside address {
                  font-style: normal;
                  white-space: pre-line;
                }
                aside address#from {
                  color: rgb(48, 47, 48);
                  flex: 1;
                }
                aside address#to {
                  text-align: right;
                }
                table.headertable {
                  width: 100%;
                  padding-left: 1mm;
                }
                table.invoiceheading {
                  width: 90%;
                  padding-left: 50mm;
                }
                table.headertable th {
                  height: 7mm;
                  width: 0mm;
                  text-align: left;
                  padding-top: 1mm;
                  padding-left: 5mm;
                  padding-right: 1mm;
                  padding-bottom: 1mm;
                }
                table.headertable th.empty {
                  width: 1cm;
                }
                table.headertable td.empty {
                  width: 1cm;
                }
                table.headertable td:last-of-type {
                  font-weight: bold;
                }
                .top-left-img,
                .bottom-right-img {
                  position: absolute;
                  width: 125px;
                }
                .top-left-img {
                  top: 0;
                  left: 0;
                }
                .bottom-right-img {
                  bottom: 0;
                  right: 0;
                }
                .container {
                  position: relative;
                  padding: 20px;
                }
                .invoice-header1 {
                  margin-left: 2px;
                  text-align: right;
                  line-height: 2px;
                }
                .invoice-header1 h4 {
                  color: #463cb7;
                  font-size: 2.3vw;
                }
                .invoice-header1 p {
                  font-weight: bold;
                  font-size: 1.4vw;
                }
                .sub-container {
                  margin-top: 15%;
                }
                .invoice-to {
                  line-height: 8px;
                  font-size: 1.4vw;
                }
                .invoice-to > h3 {
                  color: #5d53bf;
                }
                .table-wrapper {
                  overflow-x: auto;
                }
                .invoice-table {
                  width: 100%;
                  border-collapse: collapse;
                  margin-top: 20px;
                  font-size: 1.2vw;
                }
                .invoice-table th,
                .invoice-table td {
                  border: 1px solid #ddd;
                  padding: 1px;
                  text-align: center;
                  vertical-align: middle;
                }
                .invoice-table th {
                  background-color: #463cb7;
                  color: white;
                  font-weight: 600;
                }
                .invoice-table td:first-child {
                  text-align: left;
                }
                .invoice-table tr:nth-child(even) {
                  background-color: #f6f6f6e4;
                }
                .summary-table {
                  font-size: 1.5vw;
                  margin-top: 20px;
                  border-collapse: collapse;
                  border: 1px solid #ddd;
                  width: 45%;
                  margin-left: auto;
                  background-color: #f6f6f6e4;
                }
                .summary-table td,
                .summary-table th {
                  padding: 1px;
                  font-weight: 600;
                }
                .summary-table td:last-child {
                  text-align: right;
                }
                .summary-table tr:last-child {
                  background-color: #463cb7;
                  color: white;
                }
                .totals {
                  color: #463cb7;
                  font-weight: bold;
                  margin-top: 7px;
                  letter-spacing: 0.5px;
                  font-size: 1.4vw;
                }
                .payment_summary {
                  display: flex;
                  justify-content: space-between;
                }
                .payment_summary > div {
                  text-align: right;
                  line-height: 15px;
                  width: 55%;
                  font-size: 1.4vw;
                }
                .terms_conditions > p {
                  font-size: 1.3vw;
                  width: 50%;
                }
                .terms_conditions > h5 {
                  font-size: 1.4vw;
                }
              </style>
        <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="stylesheet" href="styles.css" />
    <title>Document</title>
</head>

<body>
    <img
            src="https://sapdfless001.blob.core.windows.net/document-template-assets/470f67bf-a70a-48d0-9922-2466799e8211/1182/17288801017322.png"
            alt="top-left-img"
            class="top-left-img"
        />
    <img
            src="https://sapdfless001.blob.core.windows.net/document-template-assets/470f67bf-a70a-48d0-9922-2466799e8211/1182/17288801020234.png"
            alt="bottom-right-img"
            class="bottom-right-img"
        />
    <div class="container">
        <div class="invoice-header1">
            <h4>INVOICE ${fetchBillData.invoiceReferenceNumber || ""}</h4>
            <p>${fetchBillData.invoiceDate || ""}</p>
        </div>

        <!-- <aside>
       <address id="from">
       ${fetchBillData.customerName || ""}
       ${fetchBillData.customerContact || ""}   
       ${fetchBillData.customerEmail || ""}  
      </address>
      <address id="to">
       ${fetchBillData.companyName || ""}
       ${fetchBillData.companyPhone || ""}   
       ${fetchBillData.companyEmail || ""}            
      </address>
    </aside> -->
        <div class="sub-container">
            <div class="invoice-to">
                <h4>Invoice to:</h4>
                <h3>${fetchBillData.customerName || ""}</h3>
                <p>${fetchBillData.customerContact || ""}</p>
                <p>${fetchBillData.customerEmail || ""}</p>
            </div>

            <div class="table-wrapper">
                <table class="invoice-table">
                    <thead>
                        <tr>
                            <th style="width: 150px;">Particular</th>
                            <th style="width: 60px;">Batch No</th>
                            <th style="width: 60px;">Expiry</th>
                            <th style="width: 40px;">Qty</th>
                            <th style="width: 50px;">Rate</th>
                            <th style="width: 50px;">Amt</th>
                            <th style="width: 40px;">Discount</th>
                            <th style="width: 60px;">Total</th>
                            <th style="width: 40px;">SGST</th>
                            <th style="width: 40px;">CGST</th>
                            <th style="width: 80px;">
                                Net Amt
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                         ${productInfos
                           .map(
                             (product) => `
                          <tr>
                            <td>${product.productName || ""}</td>
                            <td>${product.batch || ""}</td>
                            <td>${moment(product.expiryDate).format(
                              "DD/MM/YYYY"
                            )}</td>
                            <td>${product.qty || 0}</td>
                            <td>${product.rate || 0}</td>
                            <td>${product.amount || 0}</td>
                            <td>${product.discountAmount || 0}</td>
                            <td>${product.netAmount || 0}</td>
                            <td>${product.sgst || 0}</td>
                            <td>${product.cgst || 0}</td>
                            <td>${product.netAmount || 0}</td>
                          </tr>
                        `
                           )
                           .join("")}
                    </tbody>
                </table>
            </div>

            <table class="summary-table">
                <tbody>
                    <tr>
                        <td>Sub Total (inclusive TAX)</td>
                      <td>${fetchBillData.subTotal || "0.00"}</td>
                    </tr>
                    <tr>
                        <td>Discount</td>
                        <td style="color: green">${
                          fetchBillData.manualDiscountAmount || "0.00"
                        }</td>
                    </tr>
                    <td>Net Payable</td>
                    <td>${fetchBillData.grandTotal || "0.00"}</td>
                    </tr>
                </tbody>
            </table>

            <div class="payment_summary">
                <div></div>
                <div>
                    <p style="font-weight: bold">Invoice from:</p>
                    <h3 style="color: #463cb7">${
                      fetchBillData.companyName || ""
                    }</h3>
                    <p>${fetchBillData.companyPhone || ""}</p>
                    <p>${fetchBillData.companyEmail || ""}</p>
                </div>
            </div>

            <div class="terms_conditions">
                <h5>Terms & Conditions</h5>
                <p>${fetchBillData.footerText || ""}</p>
            </div>
        </div>
    </div>
    </div>
</body>
</html>
        `;

        const htmlContentA2 = `
        <!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="stylesheet" href="style.css" />
         <style>
               * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    display: flex;
    justify-content: center;
}

main {
    max-width: 320px;
    width: 100%;
    padding: 40px 10px;
    background-color: #f0f0f0;
}

p,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    font-size: 0.9rem;
}

.text-center {
    text-align: center;
}

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: #333;
}

.invoice-header1 {
    width: 100%;
    padding: 10px 0;
}

.invoice-meta-data {
    border: 2px dashed #ccc;
    border-left: 0;
    border-right: 0;
    padding: 10px 0;
}

.invoice-body {
    position: relative;
    width: 100%;
    border-style: double;
    border: 2px dashed #ccc;
    outline-offset: -5px;
    border-left: 0;
    border-right: 0;
    padding: 10px 0;
}

.invoice-body::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border: 2px dashed #ccc;
    border-left: 0;
    border-right: 0;
    top: 2px;
}

.invoice-item span:first-child {
    margin-right: 5px;
}

.invoice-item span:last-child {
    margin-left: 5px;
}

.invoice-item table {
    width: 100%;
    font-size: 0.8rem;
    text-align: left;
}

.invoice-item table td,
.invoice-item table th {
    padding: 0 5px;
}

.invoice-item table tr th:first-child,
.invoice-item table tr td:first-child {
    padding-left: 0;
}

.invoice-item table tr th:last-child,
.invoice-item table tr td:last-child {
    padding-right: 0;
}

.invoice-item table .col-10 {
    width: 10%;
}

.invoice-item table .col-15 {
    width: 15%;
}

.invoice-item table .col-25 {
    width: 25%;
}

.invoice-item table .col-35 {
    width: 35%;
}

.border-dashed {
    border: 2px dashed #ccc;
    border-left: 0;
    border-right: 0;
    margin: 10px 0;
    padding: 10px 0;
}

.total-amount-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.net-amount-block {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border: 2px dashed #ccc;
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.net-amount-block::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    border: 2px dashed #ccc;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    top: 4px;
}

.payment-method-block {
    padding: 10px 0;
}

.pharmacist-signature-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    border: 2px dashed #ccc;
    border-left: 0;
    border-right: 0;
}

.signature {
    margin-top: 30px;
    padding-top: 5px;
    border: 2px dashed #ccc;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.branch-details-block {
    width: 100%;
    padding: 10px 0;
}
              </style>
        <title>Invoice</title>
    </head>
   <body>
        <main style="display: contents;">
            <div class="container">
                <div class="invoice-header1 text-center">
                    <h3>Tax Invoice/Bill of Supply</h3>
                    <h5>${fetchBillData.companyName || ""} </h5>
                    <h5>${fetchBillData.branchName || ""} - Store Phone No.: ${
          fetchBillData.companyPhone || ""
        }</h5>
                </div>

                <div class="invoice-meta-data">
                    <p>Invoice No. ${
                      fetchBillData.invoiceReferenceNumber || ""
                    } Dt.${fetchBillData.invoiceDate || ""}</p>
                </div>

                <div style="padding: 10px 0">
                    <p>Patient: ${fetchBillData.customerName || ""}</p>
                </div>

                <div class="invoice-body">
                    <div class="invoice-item">
                        <p>
                            <span><====== Item Description ======></span>
                            <span>Amount</span>
                        </p>
                        <table>
                            <tr>
                                <th class="col-25">Batch No</th>
                                <th class="col-25">Expiry</th>
                                <th class="col-15">Qty</th>
                                <th class="col-25">Rate</th>
                                <th class="col-10">Amount</th>
                            </tr>
                            <tr>
                                <th class="col-25">HSN Code</th>
                                <th colspan="2">SGST(%)</th>
                                <th class="col-35" colspan="2">CGST(%)</th>
                            </tr>
                        </table>
                    </div>

                    <div class="border-dashed">
                       ${productInfos
                         .map(
                           (product) => `
        <div class="invoice-item" style="margin-bottom: 5px">
          <p>
            <span>${product.productName || ""}</span>
            <span>(${product.amount || 0})</span>
          </p>
          <table>
            <tr>
              <td class="col-25">${product.batch || ""}</td>
              <td class="col-25">${
                moment(product.expiryDate).format("DD/MM/YYYY") || ""
              }</td>
              <td class="col-15">${product.qty || 0}</td>
              <td class="col-25">${product.rate || 0}</td>
              <td class="col-10">${product.amount || 0}</td>
            </tr>
            <tr>
              <td class="col-25">${product.hsn || ""}</td>
              <td colspan="2">SGST % ${product.sgst || 0}</td>
              <td class="col-35" colspan="2">
                CGST % ${product.cgst || 0}
              </td>
            </tr>
          </table>
        </div>
      `
                         )
                         .join("")}

                    </div>
                    <div class="total-amount-block">
                        <p>Total Qty. : ${fetchBillData.qty || "0.00"}</p>
                        <p>${fetchBillData.totalAmount || "0.00"}</p>
                    </div>
                </div>

                <div class="net-amount-block">
                    <div class="">
                        <p>Net Amount (Rounded):</p>
                    </div>
                    <div class="">
                        <p>${fetchBillData.grandTotal || "0.00"}</p>
                    </div>
                </div>

                <div class="payment-method-block">
                    <p>Payment By: Cash</p>
                </div>

                <div class="pharmacist-signature-block">
                    <p>THANKS FOR SHOPPING AT ${
                      fetchBillData.companyName || ""
                    }</p>

                    <div class="signature">
                        <p>( Pharmacist )</p>
                    </div>
                </div>

                <div class="branch-details-block">
                    <p>Branch: ${fetchBillData.branchName || ""}</p>
                    <p>${fetchBillData.branchAddress || ""}</p>
                    <p>Phone No.: ${fetchBillData.branchPhone || ""}</p>
                    <p>GSTIN: ${fetchBillData.GSTIN || ""}</p>
                    <h5 class="text-center" style="margin-top: 10px">
                        ********** ACKNOWLEDGEMENT **********
                    </h5>
                    <p>
                        I confirm having received the goods as mentioned in this
                        Sales Invoice (No.${
                          fetchBillData.invoiceReferenceNumber || ""
                        } Date
                        ${
                          fetchBillData.invoiceDate || ""
                        }) as per Doctor's Prescription and in
                        good order and condition.
                    </p>

                    <div style="display: flex; justify-content: center; align-items: center;">
                        <div class="signature">
                            <p>Recipient ( Signature )</p>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    </body>
</html>
`;
        setLoader(false);
        setHtmlContent(selectedFormat == "A2" ? htmlContentA2 : htmlContent);
        setShowModal(true);
      };
      return (
        <div className="d-flex justify-content-around w-100">
          <Link>
            <button
              type="button"
              className="actions-btn"
              title="View HTML"
              onClick={viewHTML}
            >
              <FaFileCode className="table-action-icon" />
            </button>
          </Link>
          <Link
            to={
              rowData.billType.toLowerCase() === "refund" ||
              rowData.editableStatus === 0
                ? "#"
                : `/admin/editBill/${rowData.billId}`
            }
          >
            <button
              type="button"
              title="Edit Bill"
              disabled={
                rowData.billType.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
              }
              className={`actions-btn ${
                rowData.billType.toLowerCase() === "refund" ||
                rowData.editableStatus === 0
                  ? "disabled-action-btn"
                  : ""
              }`}
              style={{
                backgroundColor:
                  rowData.billType.toLowerCase() === "refund" ||
                  rowData.editableStatus === 0
                    ? "#d3d3d3"
                    : "#F8F8FF", // Change to desired background color for disabled state
                color:
                  rowData.billType.toLowerCase() === "refund" ||
                  rowData.editableStatus === 0
                    ? "#a9a9a9"
                    : "#fff", // Change to desired text color for disabled state
                cursor:
                  rowData.billType.toLowerCase() === "refund" ||
                  rowData.editableStatus === 0
                    ? "not-allowed"
                    : "pointer", // Change cursor to indicate disabled state
                opacity:
                  rowData.billType.toLowerCase() === "refund" ||
                  rowData.editableStatus === 0
                    ? "0.6"
                    : "1", // Adjust opacity for disabled state
              }}
            >
              <FaPencilAlt className="table-action-icon" />
            </button>
          </Link>
          <Link
            to={
              rowData.billType.toLowerCase() === "refund"
                ? "#"
                : `/admin/refundBill/${rowData.billId}`
            }
          >
            <button
              type="button"
              title="Refund Bill"
              disabled={rowData.billType.toLowerCase() === "refund"}
              className={`actions-btn ${
                rowData.billType.toLowerCase() === "refund"
                  ? "disabled-action-btn"
                  : ""
              }`}
              style={{
                backgroundColor:
                  rowData.billType.toLowerCase() === "refund"
                    ? "#d3d3d3"
                    : "#F8F8FF", // Change to desired background color for disabled state
                color:
                  rowData.billType.toLowerCase() === "refund"
                    ? "#a9a9a9"
                    : "#fff", // Change to desired text color for disabled state
                cursor:
                  rowData.billType.toLowerCase() === "refund"
                    ? "not-allowed"
                    : "pointer", // Change cursor to indicate disabled state
                opacity:
                  rowData.billType.toLowerCase() === "refund" ? "0.6" : "1", // Adjust opacity for disabled state
              }}
            >
              <RiRefund2Line className="table-action-icon" />
            </button>
          </Link>

          <Link>
            <button
              type="button"
              className="actions-btn"
              onClick={() => fetchPDF(rowData.billId, "preview")}
              title="View Bill PDF"
            >
              <FaEye className="table-action-icon" />
            </button>
          </Link>

          <Link>
            <button
              type="button"
              className="actions-btn"
              onClick={() => fetchPDF(rowData.billId, "download")}
              title="Download Bill PDF"
            >
              <FaDownload className="table-action-icon" />
            </button>
          </Link>
        </div>
      );
    };

    return (
      <>
        <DataTable
          ref={dt}
          value={recentBillsData ?? []}
          selection={selectedRecentBillsData}
          onSelectionChange={(e) => setSelectedRecentBillsData(e.value)}
          dataKey="billId"
          selectionMode="single"
          pt={{
            root: { className: "table" },
          }}
          emptyMessage="No records found"
        >
          <Column
            field="invoiceReferenceNumber"
            header="Reference No"
            body={(rowData) =>
              rowData.invoiceReferenceNumber ? (
                <span title={"Bill Id - " + rowData.billId}>
                  {rowData.invoiceReferenceNumber}
                </span>
              ) : (
                ""
              )
            }
          ></Column>
          <Column
            field="customerName"
            header="Patient Name"
            style={{ maxWidth: "200px" }}
          ></Column>
          <Column field="netPayment" header="Amount"></Column>
          <Column
            align="center"
            field="billType"
            header="Bill Type"
            body={(rowData) => (
              <span
                style={{
                  color:
                    rowData.billType.toLowerCase() === "refund"
                      ? "red"
                      : "black",
                }}
              >
                {rowData.billType}
              </span>
            )}
          ></Column>
          <Column
            align="center"
            field="paymentMethod"
            header="Payment Method"
          ></Column>
          <Column
            field="createDateTime"
            header="Bill Creation Date"
            dataType="date"
            body={(rowData) => {
              try {
                return Common.getFormatedDate(
                  rowData.createDateTime,
                  "DD/MM/YYYY hh:mm:ss A",
                  true
                );
              } catch (error) {
                console.error(error.message);
                return "Invalid Date"; // or any fallback display text
              }
            }}
          ></Column>

          <Column
            header="Actions"
            align="center"
            body={(rowData) => <ActionTemplate rowData={rowData} />}
            exportable={false}
          />
        </DataTable>
        <Col className="text-center">
          {loadingMore ? (
            <ChildLoader />
          ) : (
            <LoadMore
              totalData={recentBillsData?.length}
              handleLoadMore={() => loadMoreData()}
            />
          )}
        </Col>
      </>
    );
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleRadioChange = async (e) => {
    const selectedValue = e.target.value;
    setSelectedFormat(selectedValue);
    const storedCompanyId = localStorage.getItem("companyId");
    const storedLoginId = localStorage.getItem("loginId");

    // Prepare the parameter to save the selected format
    let param = [
      {
        feature_key:
          storedCompanyId + "_" + storedLoginId + "_user_invoiceformat",
        feature_value: selectedValue, // Use the new selected radio value
      },
    ];
    // Call saveDetails on radio button change
    await saveDetails(param);
  };

  // Updated saveDetails to accept params
  async function saveDetails(param) {
    setLoader(true);

    try {
      const res = await updateGenricData(param);
      if (res) {
        const response = await getGenricData();
        await setAlerts({
          type: "success",
          message: notificationMessages.invoiceFormat,
        });
      }
    } catch (error) {
      // Handle error if necessary
      console.error("Error saving data:", error);
    } finally {
      setLoader(false); // Stop the loader
    }
  }

  return (
    <>
      <div className="content-dashboard-inventory">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="title-route-container menu-title-div"
        >
          <h4 className="menu-title-h">Bills / Bills Report</h4>
          <div className="payment-header-btn-container">
            <FilterButton
              getReport={getReport}
              setLoadingFilter={setLoadingFilter}
            />
          </div>
        </div>

        {/* Total Payment Amount row [Start] */}
        <Row className="mb-4">
          <Col lg={3} md={6} xs={6} className="custom-col">
            <Card className="dashboard-card shadow-effect dashboard-card-bg-style">
              <CardBody className="d-flex flex-column justify-content-between">
                <Row>
                  <Col
                    lg={8}
                    md={6}
                    sm={6}
                    xs={6}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="card-title">Cash</div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="card-value net-profit-card-value"
                  >
                    ₹ <span>{totalCashAmount}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={6} xs={6} className="custom-col">
            <Card className="dashboard-card shadow-effect dashboard-card-bg-style">
              <CardBody className="d-flex flex-column justify-content-between">
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="card-title">Credit/Debit Card</div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="card-value net-profit-card-value"
                  >
                    ₹ <span>{totalCardAmount}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={6} xs={6} className="custom-col">
            <Card className="dashboard-card shadow-effect dashboard-card-bg-style">
              <CardBody className="d-flex flex-column justify-content-between">
                <Row>
                  <Col
                    lg={8}
                    md={6}
                    sm={6}
                    xs={6}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="card-title">UPI</div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="card-value net-profit-card-value"
                  >
                    ₹ <span>{totalUPIAmount}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={6} xs={6} className="custom-col">
            <Card className="dashboard-card shadow-effect dashboard-card-bg-style">
              <CardBody className="d-flex flex-column justify-content-between">
                <Row>
                  <Col
                    lg={8}
                    md={6}
                    sm={6}
                    xs={6}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="card-title">Net Banking</div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="card-value net-profit-card-value"
                  >
                    ₹ <span>{totalNetBankingAmount}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={6} xs={6} className="custom-col">
            <Card className="dashboard-card shadow-effect dashboard-card-bg-style">
              <CardBody className="d-flex flex-column justify-content-between">
                <Row>
                  <Col
                    lg={8}
                    md={6}
                    sm={6}
                    xs={6}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="card-title">Total Refund</div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="card-value text-danger"
                  >
                    ₹ <span className="text-danger">{totalRefundAmount}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Total Payment Amount row [End] */}

        <Row>
          <Col>
            <Card>
              <CardBody>
                <div
                  className="title-row"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h6
                    className="tile-title"
                    style={{
                      flex: "1 1 auto",
                      marginBottom: "10px",
                    }}
                  >
                    Recent Bills
                  </h6>

                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "5px",
                      flexWrap: "wrap", // Allows wrapping on smaller screens
                    }}
                  >
                    <FormGroup
                      className="view-format-group"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row", // Ensure row alignment
                        marginRight: "20px",
                        whiteSpace: "nowrap", // Prevent wrapping
                      }}
                    >
                      <legend
                        style={{
                          marginRight: "10px",
                          fontSize: "18px",
                          marginBottom: "0px",
                          // Add spacing between the legend and inputs
                        }}
                      >
                        HTML View Format:
                      </legend>
                      <FormGroup check inline style={{ marginRight: "5px" }}>
                        <Label
                          check
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "17px",
                          }}
                        >
                          <Input
                            style={{
                              transform: "scale(1.5)",
                              marginRight: "8px", // Adjust margin for radio button spacing
                            }}
                            type="radio"
                            name="viewFormat"
                            value="A2"
                            checked={selectedFormat === "A2"}
                            onChange={handleRadioChange}
                          />{" "}
                          A2
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label
                          check
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "17px",
                          }}
                        >
                          <Input
                            style={{
                              transform: "scale(1.5)",
                              marginRight: "8px",
                            }}
                            type="radio"
                            name="viewFormat"
                            value="A4"
                            checked={selectedFormat === "A4"}
                            onChange={handleRadioChange}
                          />{" "}
                          A4
                        </Label>
                      </FormGroup>
                    </FormGroup>
                  </div>

                  <button
                    onClick={() => navigate(ADMIN_URL_CREATE_BILL)}
                    className="create-bill-btn"
                    style={{
                      whiteSpace: "nowrap", // Prevent button text from wrapping
                      marginBottom: "10px", // Add spacing for mobile
                    }}
                  >
                    Create Bill <FaPlus />
                  </button>
                </div>

                {loader ? (
                  <PageLoader />
                ) : (
                  <>
                    <Row>
                      <Col>
                        <RecentBills />
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <Modal
        isOpen={showModal}
        toggle={handleCloseModal}
        size={"lg"}
        fullscreen={"lg"}
      >
        <ModalHeader
          style={{
            paddingTop: "5px",
          }}
          toggle={handleCloseModal}
        >
        </ModalHeader>
        <ModalBody>
          {/* Render the HTML content */}
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </ModalBody>
      </Modal>
    </>
  );
};
export default BillDashboard;
