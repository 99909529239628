import logo from "assets/img/new-logo-with-text-light.svg";
import PHONE from "assets/img/landing-page/icons/phone.png";
import GMAIL from "assets/img/landing-page/icons/gmail.png";
import INTERNET from "assets/img/landing-page/icons/internet.png";
import LINKEDIN from "assets/img/landing-page/icons/linkedin.png";
import WHATSAPP from "assets/img/landing-page/icons/whatsApp.png";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const Footer = () => {
  const SOCIAL_MEDIA_LINKS = [
    {
      name: "LinkedIn",
      icon: `${ERP_CDN_IMAGE_URL}/linkedin.png`
        ? `${ERP_CDN_IMAGE_URL}/linkedin.png`
        : LINKEDIN,
      href: "https://www.linkedin.com/in/apsthreeaillp/",
    },
    {
      name: "Whatsapp",
      icon: `${ERP_CDN_IMAGE_URL}/whatsApp.png`
        ? `${ERP_CDN_IMAGE_URL}/whatsApp.png`
        : WHATSAPP,
      href: "https://wa.me/+916356561125",
    },
    {
      name: "Website",
      icon: `${ERP_CDN_IMAGE_URL}/internet.png`
        ? `${ERP_CDN_IMAGE_URL}/internet.png`
        : INTERNET,
      href: "https://apsthreeai.ai/",
    },
    {
      name: "Phone",
      icon: `${ERP_CDN_IMAGE_URL}/phone.png`
        ? `${ERP_CDN_IMAGE_URL}/phone.png`
        : PHONE,
      href: "tel:+916356561125",
    },
    {
      name: "Gmail",
      icon: `${ERP_CDN_IMAGE_URL}/gmail.png`
        ? `${ERP_CDN_IMAGE_URL}/gmail.png`
        : GMAIL,
      href: "mailto:admin@apsthreeai.ai",
    },
  ];

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-logo-container">
          <a href="https://apsthreeai.ai/" target="_blank">
            <img
              src={`${ERP_CDN_IMAGE_URL}/new-logo-with-text-light.svg`}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop if logo also fails
                e.target.src = logo;
              }}
              alt="APS THREEAI"
            />
          </a>
        </div>
        <div className="social-media">
          {SOCIAL_MEDIA_LINKS.map(({ name, icon, href }, index) => (
            <a key={index} href={href} target="_blank">
              <img src={icon} alt={name} />
            </a>
          ))}
        </div>
        <a href="tel:+916356561125" target="_blank" className="contact-us-btn">
          Contact Us
        </a>
      </div>
      <div className="copyright">
        &copy; 2024, made with by APS THREEAI LLP, Inc. All rights reserved
      </div>
    </div>
  );
};

export default Footer;
