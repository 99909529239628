import React, { useState, useRef } from "react";
import {
    Modal,
    ModalBody,
    Button,
    Input,
    Form,
    FormGroup,
    Label,
    FormFeedback,
} from "reactstrap";
import logo from "assets/img/new-logo-with-text-dark.svg";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import {
    AUTH_URL_DEMO_LOGIN,
    AUTH_URL_SIGNUP,
    AUTH_URL_LOGIN,
} from "helpers/Paths";
import Common from "helpers/Common";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomButton from "components/Button/CustomButton";
import { useNotificationStore } from "hooks/Notification";
import { IoClose } from "react-icons/io5"; // Import a close icon
import PageLoader from "components/Loader/PageLoader";

const Navbar = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [consent, setConsent] = useState(false);
    const [emailValidation, setEmailValidation] = useState("");
    const [mobileValidation, setMobileValidation] = useState(""); // State to track mobile validation
    const [consentValidation, setConsentValidation] = useState("");
    const [{}, { SendSignupEmail }, {}] = useNotificationStore(); // Move this inside the component
    const [loader, setLoader] = useState(false);
    const navbarRef = useRef(null);
    const logoRef = useRef(null);
    const demoButtonRef = useRef(null);
    const signInButtonRef = useRef(null);
    const createAccountButtonRef = useRef(null);
    const navigate = useNavigate();
    const mobileInputValidClass = "form-control";

    useGSAP(() => {
        const tl = gsap.timeline();

        tl.from(logoRef.current, {
            x: -50,
            opacity: 0,
            duration: 0.6,
        });

        return () => {
            tl.kill();
        };
    }, []);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);

        // Reset fields when closing the modal
        if (!isModalOpen) {
            resetForm();
        }
    };

    const resetForm = () => {
        setEmail("");
        setMobile("");
        setEmailValidation("");
        setMobileValidation("");
        setConsentValidation("");
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        // Validate email when changed
        if (Common.verifyLength(value, 5) && Common.verifyEmail(value)) {
            setEmailValidation("has-success"); // Email is valid
        } else {
            setEmailValidation("has-danger"); // Email is invalid
        }
    };

    const handleMobileChange = (e) => {
        setMobile("+" + e);
        // Simple validation for mobile number (you can modify it based on your requirements)
        if (e.length >= 10) {
            setMobileValidation("has-success");
        } else {
            setMobileValidation("has-danger");
        }
    };

    const handleLogin = async (e) => {
        setLoader(true);
        e.preventDefault(); // Prevent default form submission

        let isValid = true;

        // Validate email field
        if (!Common.verifyLength(email, 5) || !Common.verifyEmail(email)) {
            setEmailValidation("has-danger");
            isValid = false;
            setLoader(false);
        } else {
            setEmailValidation("has-success");
        }

        // Validate mobile field
        if (mobile === "" || mobile.length == 0) {
            setMobileValidation("has-danger");
            isValid = false;
            setLoader(false);
        } else {
            setMobileValidation("has-success");
        }

        if (consent === false) {
            setConsentValidation("has-danger");
            isValid = false;
            setLoader(false);
        } else {
            setConsentValidation("has-success");
        }

        // Proceed only if both fields are valid
        if (isValid) {
            let adminEmail = "admin@apsthreeai.ai";
            let emailFormData = new FormData();
            emailFormData.append("EmailToAddress", adminEmail);
            emailFormData.append("EmailSubject", `Demo Login`);
            emailFormData.append(
                "EmailBody",
                `Email: ${email}\nMobile: ${mobile}`
            );

            try {
                let res = await SendSignupEmail(emailFormData);
                if (res) {
                    setLoader(false);
                    toggleModal();
                    navigate(AUTH_URL_DEMO_LOGIN); // Navigate only if email is sent successfully
                } else {
                    console.error("Failed to send email", res);
                    // Optionally, show an error message to the user
                }
            } catch (error) {
                console.error("Error sending email", error);
                // Handle the error (e.g., show a notification)
            }
        }
        setLoader(false);
    };

    return (
        <nav className="navbar-container" ref={navbarRef}>
            <div className="nav-logo-container" ref={logoRef}>
                <a ref={logoRef} href="https://apsthreeai.ai/" target="_blank">
                    <img
                        src={`${ERP_CDN_IMAGE_URL}/new-logo-with-text-dark.svg`}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = logo;
                        }}
                        alt="APS THREEAI"
                    />
                </a>
            </div>

            <div className="nav-buttons">
                <button
                    ref={demoButtonRef}
                    className="nav-btn nav-signin-btn"
                    onClick={toggleModal}
                >
                    Demo Sign In
                </button>

                <button
                    ref={createAccountButtonRef}
                    className="nav-btn nav-signin-btn"
                    onClick={() => navigate(AUTH_URL_SIGNUP)}
                >
                    Create Account
                </button>

                <button
                    ref={signInButtonRef}
                    className="nav-btn nav-login-btn"
                    onClick={() => navigate(AUTH_URL_LOGIN)}
                >
                    Sign In
                </button>
            </div>

            {/* Modal for Demo Login */}
            <Modal size="md" centered isOpen={isModalOpen} toggle={toggleModal}>
                <ModalBody
                    style={{
                        height: loader ? "450px" : "",
                    }}
                >
                    {loader ? (
                        <PageLoader />
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IoClose
                                    onClick={toggleModal}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "24px",
                                    }}
                                />
                            </div>
                            <h5
                                style={{
                                    justifyContent: "center",
                                    display: "flex",
                                    textAlign: "center",
                                }}
                            >
                                Share with us your Email and Phone and
                                experience the ERP System
                            </h5>
                            <div className="d-flex justify-content-center align-items-center pb-3">
                                <Form style={{ width: "80%" }}>
                                    <FormGroup className="custom-input-container">
                                        <Label
                                            for="emailId"
                                            className="label-addcustomer"
                                        >
                                            Email ID
                                        </Label>
                                        <Input
                                            type="email"
                                            name="emailId"
                                            id="emailId"
                                            placeholder="Enter Email ID"
                                            value={email}
                                            onChange={handleEmailChange}
                                            maxLength={100}
                                            className={emailValidation} // Add class based on validation
                                        />
                                        {emailValidation === "has-danger" && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                Please enter a valid email
                                                address.
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="custom-input-container">
                                        <Label
                                            for="phoneNumber"
                                            className="label-addcustomer"
                                        >
                                            Phone Number{" "}
                                        </Label>
                                        <PhoneInput
                                            containerClass="phone-input-custom"
                                            buttonClass="flag-search-btn-custom"
                                            dropdownClass="flag-dropdown-custom"
                                            country={"in"}
                                            inputClass={mobileInputValidClass}
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            value={mobile}
                                            enableSearch={true}
                                            placeholder="+91 00000-00000"
                                            prefix="+"
                                            onChange={(e) => {
                                                handleMobileChange(e);
                                            }}
                                            enableLongNumbers={true} // Allows longer phone numbers
                                        />
                                        {mobileValidation === "has-danger" && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    marginTop: "5px",
                                                }}
                                            >
                                                Please enter a valid phone
                                                number.
                                            </div>
                                        )}
                                    </FormGroup>

                                    <FormGroup className="custom-input-container consent-input-container">
                                        <Input
                                            type="checkbox"
                                            name="consent"
                                            id="consent"
                                            onChange={(e) => {
                                                setConsent(e.target.checked);
                                            }}
                                        />
                                        <label htmlFor="consent">
                                            I consent to receive promotional
                                            offers and be contacted about ERP
                                            systems and pricing.
                                        </label>
                                        {consentValidation === "has-danger" && (
                                            <div
                                                style={{
                                                    color: "red",
                                                    marginTop: "5px",
                                                    fontSize: "12px",
                                                }}
                                            >
                                                Please accept the terms to proceed.
                                            </div>
                                        )}
                                    </FormGroup>

                                    <div
                                        className="mt-3"
                                        style={{
                                            justifyContent: "center",
                                            display: "flex",
                                        }}
                                    >
                                        <CustomButton
                                            onClick={handleLogin}
                                            label="Login"
                                            customStyle="w-100"
                                        />
                                    </div>
                                </Form>
                            </div>
                        </>
                    )}
                </ModalBody>
            </Modal>
        </nav>
    );
};

export default Navbar;
