import BaseRepository from "./BaseRepository";
import ProductQytAPICall from "service/ProductQytAPICall";

class ProductQytRepository extends BaseRepository {

    getProductQytData = async (params) => {
        return await new ProductQytAPICall().getProductQytDetails(params);
    };
}

export default ProductQytRepository;