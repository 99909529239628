import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useCreateBillStore } from "hooks/CreateBill";
import "../../assets/styles/CreateBill.css";
import PageLoader from "components/Loader/PageLoader";
import "index.css";
import FilterButton from "views/Report/FilterButton";
import CustomButton from "components/Button/CustomButton";

const BalanceSheetReport = () => {
  const [{}, { getBalanceSheet }, {}] = useCreateBillStore();

  const [invoiceSummarySheetList, setInvoiceSummarySheetList] = useState([]);
  const [billFullSummaryList, setBillFullSummaryList] = useState([]);
  const [selectedRecentBillsData, setSelectedRecentBillsData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]); // Manage expanded rows with an array
  const [allExpanded, setAllExpanded] = useState(false);

  const dt = useRef(null);

  async function getReport(Date = "", endDate = "", page = 1) {
    setSelectedDate({ fromDate: Date, toDate: endDate });
  }

  useEffect(() => {
    setLoader(true);
    if (selectedDate) {
      getBalanceSheetData();
    }
  }, [selectedDate]);

  const getBalanceSheetData = async () => {
    try {
      const List = await getBalanceSheet({
        fromdate: selectedDate.fromDate,
        todate: selectedDate.toDate,
      });
      setInvoiceSummarySheetList(List.invoiceSummarySheetList);
      setBillFullSummaryList(List.billFullSummaryList);
      setLoader(false);
      // Expand all rows initially
      setExpandedRows(List.invoiceSummarySheetList.map((item) => item.billId));
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  // CSS for grid items
  const gridItemStyle = {
    flex: "1",
    paddingRight: "20px",
  };

  const rowExpansionTemplate = (data) => {
    const billDetails = billFullSummaryList.find(
      (bill) => bill.billId === data.billId
    );

    return (
      <div
        style={{
          border: "1px solid #ddd",
          borderRadius: "5px",
          padding: "8px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          fontSize: "0.85rem",
        }}
      >
        {billDetails ? (
          <>
            {/* First Row */}
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Invoice Sell Quantity:</strong>{" "}
                {billDetails.invoiceSellQuantity}
              </p>
              <p>
                <strong>Invoice Refund Quantity:</strong>{" "}
                {billDetails.invoiceRefundQuantity}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Net Sale Quantity:</strong>{" "}
                {billDetails.invoiceNetSellQuantity}
              </p>
              <p>
                <strong>Net Buy Price:</strong> {billDetails.netBuyPrice}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Net Sale Price:</strong> {billDetails.netSellPrice}
              </p>
              <p>
                <strong>Price Without Tax:</strong>{" "}
                {billDetails.sellPriceWithoutTax ?? "N/A"}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Profit Per Quantity:</strong>{" "}
                {billDetails.profitPerQuantity}
              </p>
              <p>
                <strong>Create Date:</strong> {billDetails.createDateTime}
              </p>
            </div>

            {/* Second Row */}
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Customer ID:</strong> {billDetails.customerId}
              </p>
              <p>
                <strong>Product Name:</strong>{" "}
                {billDetails.productName ?? "N/A"}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Product ID:</strong> {billDetails.productID}
              </p>
              <p>
                <strong>Tax 1:</strong> {billDetails.tax1 ?? "N/A"}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Tax 1 Percentage:</strong>{" "}
                {billDetails.isTax1Percentage ? "Yes" : "No"}
              </p>
              <p>
                <strong>Tax 1 Description:</strong>{" "}
                {billDetails.tax1Description ?? "N/A"}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Tax 2:</strong> {billDetails.tax2 ?? "N/A"}
              </p>
              <p>
                <strong>Tax 2 Percentage:</strong>{" "}
                {billDetails.isTax2Percentage ? "Yes" : "No"}
              </p>
            </div>

            {/* Third Row */}
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Tax 2 Description:</strong>{" "}
                {billDetails.tax2Description ?? "N/A"}
              </p>
              <p>
                <strong>Total Product Price:</strong>{" "}
                {billDetails.totalProductPrice ?? "N/A"}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Total Tax Amount:</strong>{" "}
                {billDetails.totalTaxAmount ?? "N/A"}
              </p>
              <p>
                <strong>Total Payable Amount:</strong>{" "}
                {billDetails.totalPayableAmount ?? "N/A"}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Discount 1:</strong> {billDetails.discount1 ?? "N/A"}
              </p>
              <p>
                <strong>Discount 1 Description:</strong>{" "}
                {billDetails.discount1Description ?? "N/A"}
              </p>
            </div>
            <div
              style={{
                flex: "1",
                minWidth: "24%",
                paddingRight: "20px",
                textAlign: "center",
              }}
            >
              <p>
                <strong>Total Discount:</strong>{" "}
                {billDetails.totalDiscount ?? "N/A"}
              </p>
              <p>
                <strong>Net Payable:</strong> {billDetails.netPayable ?? "N/A"}
              </p>
            </div>
          </>
        ) : (
          <p>No details available.</p>
        )}
      </div>
    );
  };

  const expandAll = () => {
    let _expandedRows = {};
    invoiceSummarySheetList.forEach(
      (item) => (_expandedRows[item.billId] = true)
    );
    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(null);
  };

  const header = (
    <div
      className="flex justify-content-center gap-2"
      style={{
        display: "flex",
        gap: "10px",
      }}
    >
      <CustomButton label="Expand All" onClick={expandAll} />
      <CustomButton label="Collapse All" onClick={collapseAll} />
    </div>
  );
  return (
    <div className="content-dashboard-inventory">
      <div className="title-route-container menu-title-div">
        <h4 className="menu-title-h m-0">Analytics / Balance Sheet Report</h4>
        <div className="payment-header-btn-container">
          <FilterButton
            getReport={getReport}
            setLoadingFilter={setLoadingFilter}
          />
        </div>
      </div>

      <Row>
        <Col>
          <Card>
            <CardBody>
              {loader ? (
                <PageLoader />
              ) : (
                <DataTable
                  ref={dt}
                  value={invoiceSummarySheetList}
                  expandedRows={expandedRows}
                  onRowToggle={(e) => setExpandedRows(e.data)}
                  rowExpansionTemplate={rowExpansionTemplate}
                  dataKey="billId"
                  paginator
                  rows={5}
                  rowsPerPageOptions={[5, 10, 15]}
                  stripedRows
                  emptyMessage="No records found"
                  className="text-center"
                  header={header}
                >
                  <Column
                    expander
                    style={{
                      width: "5em",
                      border: "1px solid #ddd",
                    }}
                  />
                  <Column
                    align="center"
                    field="billId"
                    header="Bill ID"
                    style={{
                      width: "13em",
                      border: "1px solid #ddd",
                      width: "15em",
                    }}
                  />
                  <Column
                    align="center"
                    field="netBuyAmount"
                    header="Total Buy Amount"
                    style={{
                      width: "20em",
                      border: "1px solid #ddd",
                    }}
                  />
                  <Column
                    align="center"
                    field="netSellAmount"
                    header="Total Sell Amount"
                    style={{
                      border: "1px solid #ddd",
                    }}
                  />
                  <Column
                    align="center"
                    field="netProfitAmount"
                    header="Total Profit Amount"
                    style={{ border: "1px solid #ddd" }}
                  />
                </DataTable>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default BalanceSheetReport;
