import { useEffect, useState } from "react";
// helpers
import Common from "helpers/Common";
// react-select
import Select from "react-select";
// reactstrap
import { Col, Form, FormFeedback, FormGroup, Input, Row } from "reactstrap";
// underscore
import { isEmpty } from "underscore";
// hooks
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
// helpers
import { notificationMessages } from "helpers/constantMessages";
import PhoneInput from "react-phone-input-2";
// variables
import CustomButton from "components/Button/CustomButton";
import {
  CURRENCY,
  ERRORTYPE,
  INVENTORYPAGES,
  TEMPLATELIST,
} from "variables/taxdetail";
import PageLoader from "components/Loader/PageLoader";

const TaxDetail = ({ getGenricData, taxDetails }) => {
  const [taxData, setTaxData] = useState({
    gstValue: "",
    cgstValue: "",
    sgstValue: "",
    templateId: "",
    factorId: "",
    iventoryPageId: "",
    errorTypeId: "",
    email: "",
    phoneNumber: "",
    ShareEmailId: "",
    ShareNumberId: "",
    billCopyId: "",
    billReportId: "",
    currency: "",
    upiId: "",
    expiryDays: "",
  });

  const [GenricFeildValidate, setGenricFeildValidate] = useState({});
  const [{}, {}, { updateGenricData }] = useCompanyProfileStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const initializeData = (key) =>
      taxDetails?.find((data) => data.feature_key === key)?.feature_value || "";

    const loadTaxData = () => {
      setLoader(true); // Start loader
      try {
        const newTaxData = {
          templateId: { label: initializeData("print_invoice_template") || "" },
          iventoryPageId: {
            label: initializeData("Inventory_Page_Size") || "",
          },
          errorTypeId: { label: initializeData("bill_qty_error_type") || "" },
          email: initializeData("admin_email_id") || "",
          phoneNumber: initializeData("admin_phone_number") || "",
          currency: { label: initializeData("currency_value") || "" },
          upiId: initializeData("admin_upi_id") || "",
          expiryDays: initializeData("expiry_days") || "",
        };

        // Update state only once, ensuring all data is ready
        setTaxData(newTaxData);
      } catch (error) {
        console.error("Error initializing tax data:", error);
      } finally {
        setLoader(false); // Stop loader in both success and failure cases
      }
    };

    if (taxDetails?.length) {
      loadTaxData(); // Initialize tax data if `taxDetails` is available
    }
  }, [taxDetails]);

  const saveInvoiceDetails = async (e) => {
    e.preventDefault();
    setLoader(true); // Start loader

    const params = [
      {
        feature_key: "print_invoice_template",
        feature_value: taxData.templateId?.label,
      },
      {
        feature_key: "Inventory_Page_Size",
        feature_value: taxData.iventoryPageId?.label,
      },
      {
        feature_key: "bill_qty_error_type",
        feature_value: taxData.errorTypeId?.label,
      },
      {
        feature_key: "admin_email_id",
        feature_value: taxData.email,
      },
      {
        feature_key: "admin_phone_number",
        feature_value: "+" + taxData.phoneNumber,
      },
    ];

    try {
      const res = await updateGenricData(params);
      if (res) {
        setAlerts({
          type: "success",
          message: notificationMessages.genricSettingsSuccess,
        });

        // Call resetGenricSettings to refetch the updated data
        await resetGenricSettings(e);
      }
    } catch (error) {
      console.error("Error updating data:", error);
      setAlerts({
        type: "error",
        message: "An error occurred while saving invoice details.",
      });
    } finally {
      setLoader(false); // Stop loader in both success and failure cases
    }
  };

  const saveBillDetails = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoader(true); // Start loader

    const params = [
      {
        feature_key: "currency_value",
        feature_value: taxData.currency?.label,
      },
      {
        feature_key: "admin_upi_id",
        feature_value: taxData.upiId,
      },
    ];

    try {
      const res = await updateGenricData(params);
      if (res) {
        setAlerts({
          type: "success",
          message: notificationMessages.genricSettingsSuccess,
        });

        // Call resetGenricSettings to refetch the updated data
        await resetGenricSettings(e);
      }
    } catch (error) {
      console.error("Error saving bill details:", error);
      setAlerts({
        type: "error",
        message: "An error occurred while saving bill details.",
      });
    } finally {
      setLoader(false); // Stop loader in both success and failure cases
    }
  };

  const saveInventoryDetails = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoader(true); // Start loader

    const params = [
      { feature_key: "expiry_days", feature_value: taxData.expiryDays },
    ];

    try {
      const res = await updateGenricData(params);
      if (res) {
        setAlerts({
          type: "success",
          message: notificationMessages.genricSettingsSuccess,
        });

        // Call resetGenricSettings to refetch the updated data
        await resetGenricSettings(e);
      }
    } catch (error) {
      console.error("Error saving inventory details:", error);
      setAlerts({
        type: "error",
        message: "An error occurred while saving inventory details.",
      });
    } finally {
      setLoader(false); // Stop loader in both success and failure cases
    }
  };

  const resetGenricSettings = async (e) => {
    setLoader(true);
    e.preventDefault();
    await getGenricData();
    setLoader(false);
  };

  const handlePhoneChange = (value) => {
    setTaxData({ ...taxData, phoneNumber: value });
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="profile-content-container">
          <Form className="form">
            <div className="profile-content-title">
              <h5>Invoice Settings</h5>
            </div>
            <Row>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Print Invoice Template</label>
                  <FormGroup>
                    <Select
                      className="profile-custom-select"
                      value={taxData.templateId}
                      onChange={(value) =>
                        setTaxData({ ...taxData, templateId: value })
                      }
                      options={TEMPLATELIST.map((prop) => ({
                        value: prop.id,
                        label: prop.categoryname,
                      }))}
                      placeholder="Select Template"
                      isSearchable={false}
                    />
                    <FormFeedback>Template is required</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Page Count</label>
                  <FormGroup>
                    <Select
                      className="profile-custom-select"
                      name="iventoryPageId"
                      value={taxData?.iventoryPageId}
                      onChange={(value) => {
                        let error = { ...GenricFeildValidate };
                        if (value && isEmpty(value)) {
                          error.iventoryPageId = "has-danger";
                        } else {
                          error.iventoryPageId = "has-success";
                        }
                        setGenricFeildValidate(error);
                        setTaxData({ ...taxData, iventoryPageId: value });
                      }}
                      options={
                        INVENTORYPAGES &&
                        INVENTORYPAGES.length > 0 &&
                        INVENTORYPAGES.map((prop, key) => {
                          return {
                            value: prop.id,
                            label: prop.categoryname,
                          };
                        })
                      }
                      placeholder="Select Page"
                      isSearchable={false}
                    />
                    <FormFeedback>page is required</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Bill Quality Error</label>
                  <FormGroup>
                    <Select
                      className="profile-custom-select"
                      name="errotType"
                      value={taxData?.errorTypeId}
                      onChange={(value) => {
                        let error = { ...GenricFeildValidate };
                        if (value && isEmpty(value)) {
                          error.errorTypeId = "has-danger";
                        } else {
                          error.errorTypeId = "has-success";
                        }
                        setGenricFeildValidate(error);
                        setTaxData({ ...taxData, errorTypeId: value });
                      }}
                      options={
                        ERRORTYPE &&
                        ERRORTYPE.length > 0 &&
                        ERRORTYPE.map((prop, key) => {
                          return {
                            value: prop.id,
                            label: prop.type,
                          };
                        })
                      }
                      placeholder="Select Error Type"
                      isSearchable={false}
                    />
                    <FormFeedback>Error Type is required</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Admin Email</label>
                  <FormGroup>
                    <Input
                      placeholder="example@mail.com"
                      type="text"
                      value={taxData?.email}
                      valid={GenricFeildValidate?.email === "has-success"}
                      invalid={GenricFeildValidate?.email === "has-danger"}
                      onChange={(e) => {
                        let error = { ...GenricFeildValidate };
                        if (!Common.verifyLength(e.target.value, 1)) {
                          error.email = "has-danger";
                        } else {
                          error.email = "has-success";
                        }
                        setGenricFeildValidate(error);
                        setTaxData({
                          ...taxData,
                          email: e.target.value,
                        });
                      }}
                    />
                    <FormFeedback>Invalid Email</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Admin Phonenumber</label>
                  <FormGroup>
                    <PhoneInput
                      containerClass="custom-phone-input-container"
                      buttonClass="profile-flag-search-btn"
                      dropdownClass="profile-flag-dropdown"
                      inputProps={{
                        autoFocus: true,
                      }}
                      country={"in"}
                      placeholder="Enter Mobile Number"
                      value={taxData.phoneNumber}
                      onChange={handlePhoneChange}
                      enableLongNumbers={true} // Allows longer phone numbers
                    />
                    <FormFeedback>Phonenumber is required</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div
              className="mt-lg-2 profile-button-container"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <CustomButton
                label="Save Invoice Settings"
                onClick={saveInvoiceDetails}
              />
              <CustomButton
                label="Reset"
                onClick={resetGenricSettings}
              />
            </div>

            <div className="mt-3 profile-content-title">
              <h5>Bill Settings</h5>
            </div>
            <Row>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Select Currency</label>
                  <FormGroup>
                    <Select
                      className="profile-custom-select"
                      value={taxData.currency}
                      onChange={(value) =>
                        setTaxData({ ...taxData, currency: value })
                      }
                      options={
                        CURRENCY &&
                        CURRENCY.length > 0 &&
                        CURRENCY.map((prop, key) => {
                          return {
                            value: prop.symbol,
                            label: prop.code + " - " + prop.name,
                          };
                        })
                      }
                      placeholder="Select Template"
                      isSearchable={false}
                    />
                    <FormFeedback>Template is required</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Admin UPI Id</label>
                  <FormGroup>
                    <Input
                      placeholder="UPI ID : <username>@<bank> or <mobile_number>@<bank>"
                      type="text"
                      value={taxData?.upiId}
                      valid={GenricFeildValidate?.upiId === "has-success"}
                      invalid={GenricFeildValidate?.upiId === "has-danger"}
                      onChange={(e) => {
                        let error = { ...GenricFeildValidate };
                        if (!Common.verifyLength(e.target.value, 1)) {
                          error.upiId = "has-danger";
                        } else {
                          error.upiId = "has-success";
                        }
                        setGenricFeildValidate(error);
                        setTaxData({
                          ...taxData,
                          upiId: e.target.value,
                        });
                      }}
                    />
                    <FormFeedback>Invalid UPI Id</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div
              className="mt-lg-2 profile-button-container"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <CustomButton
                label="Save Bill Settings"
                onClick={saveBillDetails}
              />
              <CustomButton label="Reset" onClick={resetGenricSettings} />
            </div>

            <div className="mt-3 profile-content-title">
              <h5>Inventory Settings</h5>
            </div>
            <Row>
              <Col md="4">
                <div className="custom-input-container">
                  <label>Expiry Days</label>
                  <FormGroup>
                    <Input
                      type="text"
                      value={taxData.expiryDays}
                      onChange={(e) =>
                        setTaxData({ ...taxData, expiryDays: e.target.value })
                      }
                      valid={GenricFeildValidate.expiryDays !== "has-danger"}
                      invalid={GenricFeildValidate.expiryDays === "has-danger"}
                    />
                    <FormFeedback>Expiry Days is required</FormFeedback>
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <div
              className="mt-lg-2 profile-button-container"
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <CustomButton
                label="Save Inventory Settings"
                onClick={saveInventoryDetails}
              />
              <CustomButton
                label="Reset"
                onClick={resetGenricSettings}
              />
            </div>
          </Form>
        </div>
      )}
    </>
  );
};

export default TaxDetail;
