import React, { useState } from "react";
import { MdSms } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import "index.css";
import "../../assets/styles/notification/CustomerNotification.css";
import { Card, Col, Row } from "reactstrap";
import Email from "./Email";
import WPSMS from "./WPSMS";
import SMS from "./SMS";

const items = [
  {
    id: 0,
    icon: <FaEnvelope size={20} />,
    title: "Email",
  },
  {
    id: 1,
    icon: <IoLogoWhatsapp size={20} />,
    title: "Whatsapp",
  },
  {
    id: 2,
    icon: <MdSms size={20} />,
    title: "SMS",
  },
];

export const BroadcastNotification = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      {/* <div className="customer-container">
                <div className="title-route-container">
                    <h4 className="title">Customer/ Notification Broadcast / {items.find((item) => item.id == activeIndex)?.title || ''}</h4>
                </div>

                <Card
                    outline
                    style={{
                        width: "100%",
                        height: "100vh",

                    }}>
                    <Row className="mt-4 w-100">
                        <Col sm="12" md="12" lg="2" xl="2">
                            <Row className="customer-custom-tabs">
                                {items.slice(0, 8).map((item) => (
                                    <Col sm="12" md="12" lg="12" xl="12">
                                        <div
                                            key={item.id}
                                            className={`customer-custom-item ${activeIndex === item.id ? "highlight-tab" : ""
                                                }`}
                                            onClick={() => setActiveIndex(item.id)}
                                        // ref={(el) => (itemRefs.current[item.id] = el)}
                                        >
                                            <p><span className="mr-2">{item.icon}</span> {item.title}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </div> */}
      <div className="content-dashboard-inventory">
        <div className="title-route-container menu-title-div">
          <h4 className="menu-title-h">
            Customer / Notification Broadcast /{" "}
            {items.find((item) => item.id == activeIndex)?.title || ""}
          </h4>
        </div>

        <Card className="customer-custom-card">
          {/* <Row className="w-100">
                        <Col sm="4" md="4" lg="4" xl="4">
                            <p className="customer-custom-title">Company Profile</p>
                        </Col>
                        <Col sm="8" md="8" lg="8" xl="8">
                            <div className="search-container">
                                <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search your item here..."
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />

                                <CustomButton
                                    label="Search"
                                    onClick={handleSearch}
                                />
                            </div>
                        </Col>
                    </Row> */}

          <Row className="w-100">
            <Col sm="12" md="12" lg="2" xl="2">
              <Row className="customer-custom-tabs">
                {items.slice(0, 8).map((item) => (
                  <Col sm="12" md="12" lg="12" xl="12">
                    <div
                      key={item.id}
                      className={`customer-custom-item ${
                        activeIndex === item.id ? "highlight-tab" : ""
                      }`}
                      onClick={() => setActiveIndex(item.id)}
                      // ref={(el) => (itemRefs.current[item.id] = el)}
                    >
                      <p>
                        <span className="mr-2">{item.icon}</span> {item.title}
                      </p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col
              sm="12"
              md="12"
              lg="10"
              xl="10"
              className="customer-content-details"
            >
              <div className="h-100">
                {activeIndex === 0 && <Email />}
                {activeIndex === 1 && <WPSMS />}
                {activeIndex === 2 && <SMS />}
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
