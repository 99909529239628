import axios from "axios";
import CODES from "./StatusCodes";
import Common from "./Common";
import store from "store/store";
import { setAlerts } from "store/slice/Login";
import { ALERT_TECHNICAL_ALERT, AUTH_URL_LOGIN } from "./Paths";
import LoginAPICall from "service/LoginAPICall";
import { logout } from "store/slice/Login";

const METHOD = {
    GET: "get",
    POST: "post",
    PUT: "put",
    DELETE: "delete",
    PATCH: "patch"
};

const BASEURL = process.env.REACT_APP_API_URL;

// CHECK BELOW FOR SAMPLE DATA TO BE PASSED
class APIHandler {
    isLoggedIn = true;

    constructor(version) {
        this.baseURL = BASEURL;
        this.getAuthenticationInfo();
    }

    getAuthenticationInfo() {
        /**
         * set default authentication true so we can use it make dynamic further
         * Author: Devanshi Patel
         */
        if (Common.getJwtToken()) {
            this.isLoggedIn = true;
        }
    }

    // URL FOR API
    // REFER SAMPLE JSON AT BOTTOM FOR DATA VALUES
    get(url, data) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.GET, url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                });
        });
    }

    post(url, data) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.POST, url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    patch(url, data) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.PATCH, url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                });
        });
    }

    delete(url, data) {
        return new Promise((resolve, reject) => {
            this.api(METHOD.DELETE, url, data)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                });
        });
    }

    api(method, url, data) {
        return new Promise((resolve, reject) => {
            let axiosConfig = {};
            axiosConfig.method = method;

            if (url === undefined || url === "undefined") {
                axiosConfig.url = this.baseURL;
            }
            else {
                axiosConfig.url = this.baseURL + url;
            }

            axiosConfig.headers = this.setHeaders(data);
            axiosConfig.data = {};
            if (data) {
                if (data.params) axiosConfig.params = data.params;
                if (data.data && data.data.auth)
                    axiosConfig.auth = data.data.auth;
                if (data.data) axiosConfig.data = data.data;
            }

            axios(axiosConfig)
                .then((response) => {
                    if (response.status === CODES.SUCCESS) {
                        resolve(response.data);
                    } else if (response.status === CODES.UNAUTHORIZED) {
                        if (Common.getCompanyId()) {
                            new LoginAPICall().companyLogout({ loginid: Common.getCompanyId() });
                            store.dispatch(logout());
                        }

                        setTimeout(() => {
                            window.location.href = AUTH_URL_LOGIN + '?message=' + Common.encryptQueryParam('UnAuthorized User!!')
                        }, 2000);
                    } else if (response.status === CODES.SERVER_ERROR) {
                        if (data && !data.skipToast) {
                            store.dispatch(setAlerts({ type: 'danger', message: 'Internal Server Error!!' }))
                            let ErrMessage = (axiosConfig.url + 'status code : ' + CODES.SERVER_ERROR + ' Internal Server Error!!').toString()
                            // setTimeout(() => {
                            //     window.location.href = ALERT_TECHNICAL_ALERT + '?message=' + Common.encryptQueryParam(ErrMessage)
                            // }, 5000);
                        }
                        if (data && data.skipErrorHandling) reject(response.data);
                    } else {
                        /**
                         * Redirect on alert page if not getting any reponse after 50 seconds
                         * Author: Devanshi Patel
                         */
                        let ErrMessage = (axiosConfig.url).toString()
                        // setTimeout(() => {
                        //     window.location.href = ALERT_TECHNICAL_ALERT + '?message=' + Common.encryptQueryParam(ErrMessage)
                        // }, 100000);
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        if (error.response.status === CODES.UNAUTHORIZED) {
                            if (Common.getCompanyId()) {
                                new LoginAPICall().companyLogout({ loginid: Common.getCompanyId() });
                                store.dispatch(logout());
                            }

                            setTimeout(() => {
                                window.location.href = AUTH_URL_LOGIN + '?message=' + Common.encryptQueryParam('UnAuthorized User!!')
                            }, 2000);
                        } else if (error.response.status === CODES.SERVER_ERROR) {
                            if (data && !data.skipToast) {
                                store.dispatch(setAlerts({ type: 'danger', message: 'Internal Server Error!!' }))
                                let ErrMessage = (axiosConfig.url + 'status code : ' + CODES.SERVER_ERROR + ' Internal Server Error!!').toString()
                                // setTimeout(() => {
                                //     window.location.href = ALERT_TECHNICAL_ALERT + '?message=' + Common.encryptQueryParam(ErrMessage)
                                // }, 5000);
                            }
                            // alert('Internal Server Error!!')
                            if (data && data.skipErrorHandling) reject(error.response);
                        } else if (error.response.status === CODES.BAD_REQUEST) {
                            if (error?.response?.data?.responseException?.exceptionMessage) {
                                store.dispatch(setAlerts({ type: 'danger', message: error?.response?.data?.responseException?.exceptionMessage }))
                            } else {
                                store.dispatch(setAlerts({ type: 'danger', message: 'Bad Request!!' }))
                                let ErrMessage = (axiosConfig.url + 'status code : ' + CODES.BAD_REQUEST + ' Bad Request!!').toString()
                                // setTimeout(() => {
                                //     window.location.href = ALERT_TECHNICAL_ALERT + '?message=' + Common.encryptQueryParam(ErrMessage)
                                // }, 5000);
                            }
                            reject(error.response);
                        } else {
                            store.dispatch(setAlerts({ type: 'danger', message: error.response.statusText }))
                            resolve(error.response);
                        }
                    } else {
                        /**
                         * Redirect on alert page if not getting any reponse after 50 seconds
                         * Author: Devanshi Patel
                         */
                        let ErrMessage = (axiosConfig.url).toString()
                        // setTimeout(() => {
                        //     window.location.href = ALERT_TECHNICAL_ALERT + '?message=' + Common.encryptQueryParam(ErrMessage)
                        // }, 100000);
                    }
                });
        });
    }

    setHeaders(data) {
        let headers = {};
        headers["accept-language"] = "en";
        headers["Content-Type"] = "application/json";
        headers['commonKey'] = Common.getCommonKey()

        if (data) {
            if (data.isMultipart) {
                headers["Content-Type"] = "multipart/form-data";
            }

            if (data.headers) {
                for (var key in data.headers) {
                    if (data.headers.hasOwnProperty(key)) {
                        headers[key] = data.headers[key];
                    }
                }
            }
        }

        if (this.isLoggedIn !== false && !(data && data.skipAuth)) {
            headers['Authorization'] = Common.getJwtToken()
            headers['MyToken '] = Common.getJwtToken()
            headers['LoginId'] = Common.getLoginId()
            headers['companyId'] = Common.getCompanyId()
            headers['userId'] = Common.getUserId()
        }
        return headers;
    }
}

/**
 * Sample Data JSON for API
 * AUthor: Devanshi Patel
 * let sample_data = {
        // ADDITIONAL HEADERS IF REQUIRED
        headers :{
            'Content-type'  : 'xx-urlencode',
        },

        // IF USER ID AND TOKEN SHOULDN'T BE PASSED IN HEADERS (USER FOR AFTER LOGIN API)
        // DEFAULT : FALSE;
        skipAuth    : false,

        // IF Default error handling needs to be overridden
        // DEFAULT : FALSE;
        skipErrorHandling    : false,

        // FOR SENDING FILES OR FORM DATA REQUEST
        // DEFAULT : FALSE;
        isMultipart : true,

        // `PARAMS` ARE THE URL PARAMETERS TO BE SENT WITH THE REQUEST
        params : {
            user_id     : 10,
            name        : "lorem",
            page        : 3,
            sort_by     : 'name'
        },

        // POST DATA
        data : {
            firstName   : 'Lorem',
            lastName    : 'Ipsum'
        },
    }
 */

export default APIHandler;
