import { useEffect, useRef, useState } from "react";
import "../../assets/styles/MultiStepRegister.css";
import { useNotificationStore } from "hooks/Notification";
import { useLoginStore } from "hooks/Login";
import Common from "helpers/Common";

const OtpInput = ({
  length = 6,

  mobileNumber,
  otpVerify,
  setOtpVerify,
  handleNext,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  const [{}, { sendOTP, verifyOTP, sendOTPGeneral }, {}] =
    useNotificationStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [OTPCount, setOTPCount] = useState(3);
  const [locationInfo, setLocationInfo] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");
  const inputRefs = useRef([]);
  const [resendTimer, setResendTimer] = useState(0); // State for the resend timer

  useEffect(() => {
    const getLocation = async () => {
      try {
        const location = await Common.fetchGeolocation();
        setLocationInfo(location);
      } catch (error) {
        setLocationInfo("Unknown Location");
      }
    };

    getLocation();
  }, []);

  useEffect(() => {
    let timerInterval;
    if (resendTimer > 0) {
      timerInterval = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [resendTimer]);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch("https://api64.ipify.org?format=json");
        if (!response.ok) {
          throw new Error("Failed to fetch IP address");
        }
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIPAddress();
  }, []); // Empty array ensures useEffect runs only once on component mount

  useEffect(() => {
    const fetchDeviceInfo = () => {
      const browserInfo = Common.getBrowserInfo();
      const osInfo = Common.getOSInfo();
      const deviceString = `${browserInfo} (${osInfo})`;
      setDeviceInfo(deviceString);
    };

    fetchDeviceInfo();
  }, []);

  const getOTP = async (number) => {
    if (number) {
      let param = {
        location: locationInfo,
        ipAddress: ipAddress,
        device: deviceInfo,
        mobileNumber: "+" + number,
      };
      try {
        let res = await sendOTPGeneral(param);
        if (res && res.isOTPSent === false) {
          setAlerts({ type: "danger", message: res.success });
          return false;
        } else {
          setAlerts({ type: "success", message: "OTP sent successfully." });
          setResendTimer(30); // Start the countdown when OTP is sent
          return true;
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        return false;
      }
    }
  };

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    // allow only one input
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) sendOTPHandler(newOtp);

    // Move to next input if current field is filled
    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    const newOtp = [...otp];

    // Distribute the pasted value across the OTP inputs
    for (let i = 0; i < paste.length && i < length; i++) {
      newOtp[i] = paste[i];
    }

    setOtp(newOtp);

    // Focus the last filled input
    const lastFilledIndex = Math.min(paste.length, length) - 1;
    if (inputRefs.current[lastFilledIndex]) {
      inputRefs.current[lastFilledIndex].focus();
    }
  };

  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);

    // optional
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      // Move focus to the previous input field on backspace
      inputRefs.current[index - 1].focus();
    }
  };

  const sendOTPHandler = async (otp = "") => {
    setIsLoading(true); // Set loading state when function starts
    const otpString = otp.join("");
    // console.log(otpString);

    // if (otpString.length === 6 && mobileNumber.length === 12) {
    if (otpString.length === 6) {
      let res = await verifyOTP({
        location: locationInfo,
        ipAddress: ipAddress,
        device: deviceInfo,
        mobileNumber: "+" + mobileNumber,
        otp: otpString,
      });
      // setOtp(value)

      if (res && res?.success == "approved") {
        setOtpVerify(true);
        setIsLoading(false); // Reset loading when function completes

        if (handleNext) {
          handleNext();
        }
      } else {
        await setAlerts({ type: "danger", message: "Please enter valid OTP" });
        setOtp(new Array(length).fill(""));
        setOTPCount((prevCount) => {
          const newCount = prevCount - 1;
          if (newCount === 0) {
            localStorage.clear();
            window.location.reload();
          }
          return newCount;
        });
        setIsLoading(false); // Reset loading even on failure
      }
    }
    setIsLoading(false); // Reset loading even on failure
  };

  return (
    <>
      {/* <div className="header">
        <div className="verification-text">2. OTP Verification</div>
        <div className="step-indicator">Step 2 - 4</div>
      </div> */}
      <p style={{ textAlign: "center" }}>
        Enter OTP sent to <b>+{mobileNumber}</b>
      </p>
      <div className="otpBox">
        {otp.map((value, index) => {
          return (
            <input
              key={index}
              type="text"
              ref={(input) => (inputRefs.current[index] = input)}
              value={value}
              onChange={(e) => handleChange(index, e)}
              onPaste={(e) => handlePaste(e)}
              onClick={() => handleClick(index)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="otpInput"
              maxLength={6}
            />
          );
        })}
      </div>

      <button
        className="custom-login-btn"
        onClick={() => sendOTPHandler(otp)}
        disabled={isLoading || otpVerify} // Disable if loading or OTP verified
        style={{
          cursor: isLoading || otpVerify ? "none" : "auto",
        }}
      >
        {isLoading ? (
          <>
            <span className="spinner"></span> Verifying...
          </>
        ) : (
          "Verify OTP"
        )}
      </button>
      <div
        style={{
          fontWeight: "550",
        }}
        className="text-center text-size-small mt-2"
      >
        {OTPCount} Attempts remaining.
      </div>

      {!otpVerify && (
        <div className="sign-up-text text-center">
          Didn't receive the verification OTP?{" "}
          <div className="linkResendOTP">
            {resendTimer > 0 ? (
              <p
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  color: "#6c757d",
                  backgroundColor: "#f8f9fa",
                  borderRadius: "10px",
                  border: "1px solid #6c757d",
                  cursor: "not-allowed",
                  textAlign: "center",
                  opacity: 0.65,
                }}
              >
                Resend OTP in {resendTimer} seconds
              </p>
            ) : (
              <a
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  color: "#fff",
                  backgroundColor: "#434ce6",
                  borderRadius: "10px",
                  textDecoration: "none",
                  cursor: "pointer",
                  textAlign: "center",
                  transition: "background-color 0.3s",
                }}
                onClick={() => getOTP(mobileNumber)}
              >
                Resend OTP
              </a>
            )}
          </div>
          {/* <a
            style={{ color: "#1F77D0", cursor: "pointer", fontWeight: "bold" }}
            onClick={() => getOTP(mobileNumber)}
          >
            Resend again
          </a> */}
        </div>
      )}
    </>
  );
};

export default OtpInput;
