import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  FormFeedback,
} from "reactstrap";
import "../../assets/styles/CreateBill.css";
import { useEffect, useRef, useState } from "react";
import { useCreateBillStore } from "hooks/CreateBill";
import { useLoginStore } from "hooks/Login";
import Common from "helpers/Common";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomButton from "components/Button/CustomButton";
import PageLoader from "components/Loader/PageLoader";

const AddCustomer = ({ setFormChanged }) => {
  const addCustomerRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [formData, setFormData] = useState({
    customerName: "",
    activeStatus: 1,
    emailId: "",
    phoneNumber: "",
    description: "",
    pincode: "",
    address1: "",
    address2: "",
    street_Area: "",
    city: "",
    state: "",
    country: "India",
  });
  const [formDataValidate, setFormDataValidate] = useState({});
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [loader, setLoader] = useState(false);
  const [{}, {}, { createCustomer }] = useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountryList();
  }, []);

  const getCountryList = () => {
    let countryData = localStorage.getItem("countryList");
    if (!countryData) {
      fetch("https://restcountries.com/v3.1/all")
        .then((response) => response.json())
        .then((data) => {
          const countryNames = data.map((country) => country.name.common);
          countryNames.sort(); // Sort the countries alphabetically

          localStorage.setItem("countryList", JSON.stringify(countryNames));

          setCountries(countryNames);
        })
        .catch((error) => console.error("Error fetching countries:", error));
    } else {
      setCountries(JSON.parse(countryData));
    }

    // if (props.customerName && addCustomerRef.current) {
    //   addCustomerRef.current.focus(); // Focus the button when no results are found
    //   props.setNoResultFound();
    //   setFormData({
    //     ...formData,
    //     customerName: props.customerName,
    //   });
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormChanged(true); // Notify parent of form change
    let error = { ...formDataValidate };
    if (
      (name === "customerName" && !Common.verifyLength(value, 1)) ||
      (name === "emailId" &&
        (!value.includes("@") || !Common.verifyEmail(value))) ||
      (name === "phoneNumber" && !Common.verifyLength(value, 10))
    ) {
      error[name] = "has-danger";
    } else {
      error[name] = "has-success";
    }
    setFormDataValidate(error);
  };

  const handleMobileChange = (e) => {
    setFormData({
      ...formData,
      phoneNumber: "+" + e,
    });
    if (e.length < 12) {
      setIsMobileValid(false);
    } else {
      setIsMobileValid(true);
    }
    setFormChanged(true); // Notify parent of form change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    let validate = { ...formDataValidate };

    if (!formData.customerName) {
      error = true;
      validate.customerName = "has-danger";
    }

    // if (!isMobileValid) {
    //   error = true;
    //   validate.phoneNumber = "has-danger";
    // }

    setFormDataValidate(validate);

    if (formData?.phoneNumber?.length < 12) {
      setIsMobileValid(false);
    } else {
      setIsMobileValid(true);
    }

    try {
      if (!error) {
        setLoader(true);
        const response = await createCustomer(formData);
        if (response?.data == false) {
          setLoader(false);
          await setAlerts({
            type: "danger",
            message: response?.message,
          });
        }
        if (response?.data == true) {
          setLoader(false);
          reset();
          await setAlerts({
            type: "success",
            message: "Customer successfully created",
          });
        }
        formData.customerName = "";
        formData.activeStatus = 0;
        formData.emailId = "";
        formData.phoneNumber = "";
        formData.description = "";
        formData.pincode = "";
        formData.address1 = "";
        formData.address2 = "";
        formData.street_Area = "";
        formData.city = "";
        formData.state = "";
        formData.country = "";
        setModal(!modal);
      }
    } catch (error) {
      console.error("Error creating customer:", error);
      setLoader(false);
      await setAlerts({ type: "danger", message: "Error creating customer" });
    }
  };

  const reset = () => {
    setFormDataValidate({});
    setIsMobileValid(true);
    getCountryList();
    setFormData({
      customerName: "",
      activeStatus: 1,
      emailId: "",
      phoneNumber: "",
      description: "",
      pincode: "",
      address1: "",
      address2: "",
      street_Area: "",
      city: "",
      state: "",
      country: "India",
    });
    setFormChanged(false); // Reset formChanged state
  };

  const preventPincodeInput = (e) => {
    let value = e.target.value.trim(); // Trim any leading or trailing spaces
    const isNegative = value.startsWith("-");

    // Remove non-digit characters
    value = value?.replace(/\D/g, "");

    // Limit to 10 digits
    value = value?.slice(0, 5);

    // If it's negative, append the negative sign
    if (isNegative) {
      value = "-" + value;
    }

    e.target.value = value;
  };

  const mobileInputInvalidClass = "form-control is-invalid form-control";
  const mobileInputValidClass = "form-control";

  return (
    <Container>
      {loader ? (
        <PageLoader />
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row form>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="customerName" className="label-addcustomer">
                  Customer Name
                </Label>
                <span className="text-danger ml-1">*</span>
                <Input
                  type="text"
                  name="customerName"
                  id="customerName"
                  value={formData.customerName}
                  placeholder="Enter Customer Name"
                  onChange={handleChange}
                  valid={formDataValidate?.customerName === "has-success"}
                  invalid={formDataValidate?.customerName === "has-danger"}
                  maxLength={100}
                />
                <FormFeedback>Customer name is required.</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="phoneNumber" className="label-addcustomer">
                  Phone Number{" "}
                </Label>
                {/* <span className="text-danger ml-1">*</span> */}
                <PhoneInput
                  containerClass="phone-input-custom"
                  buttonClass="flag-search-btn-custom"
                  dropdownClass="flag-dropdown-custom"
                  country={"in"}
                  inputClass={mobileInputValidClass}
                  // inputStyle={{
                  //   borderColor: !isMobileValid ? "red" : "",
                  // }}
                  name="phoneNumber"
                  id="phoneNumber"
                  enableSearch={true}
                  placeholder="+91 00000-00000"
                  // valid={isMobileValid === "has-success"}
                  prefix="+"
                  // invalid={!isMobileValid === "has-danger"}
                  onChange={(e) => {
                    handleMobileChange(e);
                  }}
                  enableLongNumbers={true} // Allows longer phone numbers

                />
                {/* <span
                  style={{
                    color: "red",
                    fontSize: "12px",
                    display: !isMobileValid ? "inline" : "none",
                  }}
                >
                  Phone Number with correct format is required.
                </span> */}
                <FormFeedback>Please enter valid number.</FormFeedback>
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="emailId" className="label-addcustomer">
                  Email ID
                </Label>
                <Input
                  type="email"
                  name="emailId"
                  id="emailId"
                  placeholder="Enter Email ID"
                  onChange={handleChange}
                  maxLength={100}
                  className={formDataValidate.emailId}

                />
                {formDataValidate.emailId === "has-danger" && (
                  <div style={{ color: "red", marginTop: "5px" }}>
                    Please enter a valid email address.
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="address1" className="label-addcustomer">
                  Address 1
                </Label>
                <Input
                  type="text"
                  name="address1"
                  id="address1"
                  placeholder="Enter Address 1"
                  onChange={handleChange}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="address2" className="label-addcustomer">
                  Address 2
                </Label>
                <Input
                  type="text"
                  name="address2"
                  id="address2"
                  placeholder="Enter Address 2"
                  onChange={handleChange}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="street_Area" className="label-addcustomer">
                  Street/Area
                </Label>
                <Input
                  type="text"
                  name="street_Area"
                  id="street_Area"
                  placeholder="Enter Street/Area"
                  onChange={handleChange}
                  maxLength={100}
                />
              </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="pincode" className="label-addcustomer">
                  Pincode
                </Label>
                <Input
                  name="pincode"
                  id="pincode"
                  placeholder="Enter Pincode"
                  onChange={handleChange}
                  type="number"
                  min="0"
                  onKeyDown={preventPincodeInput}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="city" className="label-addcustomer">
                  City
                </Label>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  placeholder="Enter City"
                  onChange={handleChange}
                  maxLength={100}
                />
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="state" className="label-addcustomer">
                  State
                </Label>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  placeholder="Enter State"
                  onChange={handleChange}
                  maxLength={100}
                />
              </FormGroup>
            </Col>

            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="country" className="label-addcustomer">
                  Country
                </Label>
                <Input
                  type="select"
                  name="country"
                  id="country"
                  onChange={handleChange}
                  value={formData?.country}
                >
                  <option value="" disabled selected>
                    Select your country
                  </option>
                  {countries.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col xs="12" md="6" lg="4">
              <FormGroup>
                <Label for="description" className="label-addcustomer">
                  Description
                </Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  placeholder="Enter Description"
                  onChange={handleChange}
                  maxLength={100}
                  className="description-text-area"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Col
              xs="12"
              md="6"
              lg="4"
              className="d-flex align-items-end justify-content-end"
            >
              <CustomButton
                label="Submit"
                type="submit"
                customStyle="add-customer-submit-btn"
              />
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};

export default AddCustomer;
