import React, { useEffect, useState } from "react";
import Stepper from "./Stepper";
import { Card } from "reactstrap";
import "../../assets/styles/MultiStepRegister.css";
import "../../assets/styles/Stepper.css";

import mobile from "assets/img/icons/Auth/mobile.png";
import verify from "assets/img/icons/Auth/verify.png";
import company from "assets/img/icons/Auth/company.png";
import finish from "assets/img/icons/Auth/finish1.png";
import success from "assets/img/icons/Auth/success.gif";
import yes from "assets/img/icons/Auth/yes.png";
import MobileInput from "./MobileInput";
import OtpInput from "./OTPInput";
import CompanyInput from "./CompanyInput";
import FinishInput from "./FinishInput";
import Common from "helpers/Common";
import { Link, useNavigate } from "react-router-dom";
import { useLoginStore } from "hooks/Login";
import { useCompanyStore } from "hooks/Company";
import { AUTH_URL_LOGIN, ADMIN_URL_DASHBOARD } from "helpers/Paths";
import { isEmpty } from "underscore";
import SocialMediaIcons from "./SocialMediaIcons";
import erp from "assets/img/icons/Auth/erp.png";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";

const Signup = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isComplete, setIsComplete] = useState(false);
  const [{ }, { }, { setAlerts }] = useLoginStore();
  const [{ }, { }, { createCompany, createCompanyUser }] = useCompanyStore();
  const isLogin = Common.getJwtToken();
  const navigate = useNavigate();
  const [otpVerify, setOtpVerify] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    companyID: 0,
    companyName: "",
    loginID: "",
    CIN: "",
    loginPassword: "",
    confirmPassword: "",
    registerMobileNumber: "",
    email_address: "",
    activeStatus: 1,
    role_id: 1,
  });

  useEffect(() => {
    checkIfAlreadyLoggedIn();
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const checkIfAlreadyLoggedIn = () => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    }
  };

  const submitHandler = async (e) => {
    // console.log(formData);
    e.preventDefault();

    if (!formData.loginID) {
      await setAlerts({ type: "danger", message: "Login ID is required" });
      return;
    }
    if (!formData.loginPassword) {
      await setAlerts({
        type: "danger",
        message: "Login Password is required",
      });
      return;
    }
    if (!formData.confirmPassword) {
      await setAlerts({
        type: "danger",
        message: "Confirm Password is required",
      });
      return;
    }

    if (formData.loginPassword !== formData.confirmPassword) {
      await setAlerts({ type: "danger", message: "Password does not match" });
      return;
    }
    try {
      let companyRes = await createCompany({
        companyName: formData?.companyName,
        phoneNumber: "+" + formData?.registerMobileNumber,
      });

      if (companyRes?.companyID == null) {
        await setAlerts({
          type: "danger",
          message: companyRes?.message,
        });
        setTimeout(() => {
          handleNext(false);
        }, 200);
        return;
      }
      if (companyRes && !isEmpty(companyRes)) {
        let param = { ...formData };
        param.companyID = companyRes?.companyID;
        param.registerMobileNumber = "+" + param.registerMobileNumber;
        setTimeout(() => {
          reset(e);
        }, 2000);

        let companyUserRes = await createCompanyUser(param);
        if (companyUserRes && companyUserRes?.isSuccess == true) {
          // console.log("Successfully created company", companyUserRes && companyUserRes == true);
          setIsSuccess(true);
          handleNext();
          await setAlerts({
            type: "success",
            message: "Account successfully created",
          });

          // To Trigger Google Conversion Event
          trackGoogleConversion();

          setTimeout(() => {
            navigate(AUTH_URL_LOGIN);
          }, 5000);
        }
        if (companyUserRes?.isSuccess == false) {
          await setAlerts({
            type: "danger",
            message: companyUserRes?.message,
          });
          setTimeout(() => {
            handleNext(false);
          }, 200);
        }
      }
      reset(e);
    } catch (error) {
      setIsSuccess(false);
      await setAlerts({ type: "danger", message: "Error creating account" });
    }
    // handleNext();
  };

  const reset = (e) => {
    e.preventDefault();
    // setPhoneNumber('')
    setOtpVerify(false);
    formData.companyID = "";
    formData.companyName = "";
    formData.loginID = "";
    formData.loginPassword = "";
    formData.registerMobileNumber = "";
    formData.email_address = "";
  };

  const handleNext = (isOTPFalse) => {
    if (isOTPFalse == false) {
      setCurrentStep(1);
    } else {
      setCurrentStep((prevStep) => {
        if (prevStep === steps.length) {
          setIsComplete(true);
          return prevStep;
        } else {
          return prevStep + 1;
        }
      });
    }
  };

  const steps = [
    {
      id: 0,
      title: "Enter Mobile",
      icon: mobile,
      Component: (
        <MobileInput
          setFormData={setFormData}
          otpVerify={otpVerify}
          formData={formData}
          handleNext={handleNext}
        />
      ),
    },
    {
      id: 1,
      title: "Verify OTP",
      icon: verify,
      Component: (
        <OtpInput
          handleNext={handleNext}
          mobileNumber={formData.registerMobileNumber}
          setOtpVerify={setOtpVerify}
          otpVerify={otpVerify}
        />
      ),
    },
    {
      id: 2,
      title: "Company",
      icon: company,
      Component: (
        <CompanyInput
          handleNext={handleNext}
          formData={formData}
          setFormData={setFormData}
          setOtpVerify={setOtpVerify}
          otpVerify={otpVerify}
          mobileNumber={formData.registerMobileNumber}
        />
      ),
    },
    {
      id: 3,
      title: "Finish",
      icon: finish,
      Component: (
        <FinishInput
          handleNext={handleNext}
          formData={formData}
          setFormData={setFormData}
          setOtpVerify={setOtpVerify}
          otpVerify={otpVerify}
          submitHandler={submitHandler}
        />
      ),
    },
  ];

  const ActiveComponent = steps[currentStep - 1]?.Component || (() => null);

  // Google Ads conversion tracking code
  const trackGoogleConversion = () => {
    window.gtag('event', 'conversion', {
      send_to: 'AW-16685553251/BiwHCJvMstIZEOOspZQ-',
    });
  };

  useEffect(() => {
    // Google Ads conversion tracking code
    window.gtag('event', 'conversion', {
      send_to: 'AW-16685553251',
    });
  }, []);

  return (
    <>
      {isSuccess ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={`${ERP_CDN_IMAGE_URL}/success.gif`}
              onError={(e) => {
                e.target.onerror = null; // Prevent infinite loop if logo also fails
                e.target.src = success;
              }}
              alt="Success"
            />
          </div>
        </>
      ) : (
        <div className="login-form-container">
          <div className="stepper-container">
            <Stepper
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              steps={steps}
              isComplete={isComplete}
              setIsComplete={setIsComplete}
            />
          </div>
          {ActiveComponent}
        </div>
      )}
    </>
  );
};

export default Signup;
