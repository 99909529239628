import Common from "helpers/Common";
import {
  ADMIN_URL_DASHBOARD,
  AUTH_URL_OTP,
  AUTH_URL_SIGNUP,
} from "helpers/Paths";
import { useLoginStore } from "hooks/Login";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Form, FormFeedback, FormGroup, Label, Spinner } from "reactstrap";
import { isEmpty, isObject } from "underscore";
import "../../assets/styles/MultiStepRegister.css";
import "../../assets/styles/Stepper.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import success from "assets/img/icons/Auth/success.gif";
import CustomInput from "components/Input/CustomInput";
import { useNotificationStore } from "hooks/Notification";
import moment from "moment";
import { FaUser } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import LoginOTP from "./LoginOTP";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
import PageLoader from "components/Loader/PageLoader";

const DemoLogin = () => {
  const [userName, setUserName] = useState("DEMOACCOUNT");
  const [password, setPassword] = useState("123456");
  const [loader, setLoader] = useState(false);
  const [{ companyDetail }, {}, { companyLogin, setAlerts }] = useLoginStore();
  const [passwordType, setPasswordType] = useState("password");
  const [loginType, setLoginType] = useState("");
  const [loginValidate, setLoginValidate] = useState({});
  const navigate = useNavigate();
  const isLogin = Common.getJwtToken();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpVerify, setOtpVerify] = useState(false);
  const [isOTPsend, setIsOTPsend] = useState(false);
  const [loginTypeInput, setLoginTypeInput] = useState("email");
  const [{}, { sendOTP, verifyOTP, sendOTPGeneral }, {}] =
    useNotificationStore();
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({ replace: true });
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [locationInfo, setLocationInfo] = useState("");
  const [ipAddress, setIpAddress] = useState("");
  const [deviceInfo, setDeviceInfo] = useState("");
  const [countryCodeName, setCountryCodeName] = useState("IN");
  const [countryCode, setCountryCode] = useState("91"); // Default to India country code

  useEffect(() => {
    if (loginTypeInput == "mobile") {
      const getLocation = async () => {
        try {
          const location = await Common.fetchGeolocation();
          setLocationInfo(location);
        } catch (error) {
          setLocationInfo("Unknown Location");
        }
      };

      getLocation();
    }
  }, [loginTypeInput]);

  useEffect(() => {
    if (loginTypeInput == "mobile") {
      const fetchIPAddress = async () => {
        try {
          const response = await fetch("https://api64.ipify.org?format=json");
          if (!response.ok) {
            throw new Error("Failed to fetch IP address");
          }
          const data = await response.json();
          setIpAddress(data.ip);
        } catch (error) {
          console.error("Error fetching IP address:", error);
        }
      };
      fetchIPAddress();
    }
  }, [loginTypeInput]); // Empty array ensures useEffect runs only once on component mount

  useEffect(() => {
    if (loginTypeInput == "mobile") {
      const fetchDeviceInfo = () => {
        const browserInfo = Common.getBrowserInfo();
        const osInfo = Common.getOSInfo();
        const deviceString = `${browserInfo} (${osInfo})`;
        setDeviceInfo(deviceString);
      };
      fetchDeviceInfo();
    }
  }, [loginTypeInput]);

  useEffect(() => {
    let message = searchParams.get("message");
    if (message) {
      message = Common.decryptQueryParam(message.replace(/ /g, "+"));
      setAlerts({
        type: "danger",
        message: message,
      });
    }

    checkIfAlreadyLoggedIn();
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  }, []);

  const checkIfAlreadyLoggedIn = () => {
    if (isLogin) {
      navigate(ADMIN_URL_DASHBOARD);
    } else {
      if (!Common.getCommonKey()) {
        const commonKey = Common.getFormatedDate(moment(), "YYYYMMDDHHmmssSSS");
        localStorage.setItem("commonKey", commonKey);
      }
    }
  };

  useEffect(() => {
    if (
      companyDetail &&
      isObject(companyDetail) &&
      !isEmpty(companyDetail) &&
      companyDetail.type === "success"
    ) {
      navigate(ADMIN_URL_DASHBOARD);
    }
  }, [companyDetail]);

  useEffect(() => {
    handleLogin();
  }, []);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleChangeFormat = () => {
    setLoginTypeInput((prev) => {
      if (prev === "email") {
        return "mobile";
      } else {
        return "email";
      }
    });
  };

  const handlePhoneChange = async (value, data) => {
    setCountryCodeName(data?.countryCode.toUpperCase());
    let error = { ...loginValidate };
    if (!Common.verifyLength(value, 12)) {
      error.mobileNumber = "has-danger";
    } else {
      error.mobileNumber = "has-success";
    }

    setLoginValidate(error);
    setPhoneNumber(value);
  };

  const getOTP = async (number) => {
    if (number) {
      let param = {
        location: locationInfo,
        ipAddress: ipAddress,
        device: deviceInfo,
        mobileNumber: "+" + number,
      };
      try {
        let res = await sendOTPGeneral(param);
        if (res && res.isOTPSent === false) {
          setAlerts({ type: "danger", message: res.success });
          return false;
        } else {
          return true;
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        return false;
      }
    } else {
      return false;
    }
  };

  const sendOTPHandler = async () => {
    const phoneNumberParsed = parsePhoneNumberFromString(
      phoneNumber,
      countryCodeName // "IN" is the default country
    );
    if (!phoneNumberParsed || !phoneNumberParsed.isValid()) {
      setAlerts({
        type: "danger",
        message: "Invalid Phone Number",
      });
      return;
    } else {
      let isOTPSent = await getOTP(phoneNumber);
      if (isOTPSent) {
        setIsOTPsend(true);
      } else {
        setIsOTPsend(false);
        setAlerts({
          type: "danger",
          message: "Please Enter Correct Phone Number",
        });
      }
    }

    // if (phoneNumber.length === 12) {
    //   let isOTPSent = await getOTP(phoneNumber);
    //   if (isOTPSent) {
    //     setIsOTPsend(true);
    //   } else {
    //     setIsOTPsend(false);
    //   }
    // } else {
    //   setAlerts({
    //     type: "danger",
    //     message: "Please Enter Correct Phone Number",
    //   });
    //   return;
    // }
  };

  const handleLogin = async () => {
    setLoader(true);
    let param = {
      loginId: "DEMOACCOUNT",
      loginPassword: "123456",
    };
    let res = await companyLogin(param);

    // console.log(res);

    if (res?.payload?.type === "fail") {
      setIsSuccess(false);
      setLoader(false);
      await setAlerts({ type: "danger", message: res.payload.message });
    } else {
      setIsSuccess(true);
      setLoader(false);
      localStorage.setItem("isDemo", true); // Add key to local storage on success
    }
  };

  return (
    <>
      <PageLoader />
      {/*------------ NEW LOGIN PAGE -------------- */}
    </>
  );
};

export default DemoLogin;
