import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { FormGroup } from "reactstrap";
import "../../assets/styles/MultiStepRegister.css";
import { AUTH_URL_LOGIN } from "helpers/Paths";
import { Link, useNavigate } from "react-router-dom";
import { URL_SIGNUP_WITH_DOCUMENTS } from "helpers/Paths";
import Common from "helpers/Common";
import ReCAPTCHA from "react-google-recaptcha";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const MobileInput = ({ setFormData, otpVerify, formData, handleNext }) => {
    const [{}, {}, { setAlerts }] = useLoginStore();
    const [{}, { sendOTP, verifyOTP, sendOTPSignup }, {}] =
        useNotificationStore();
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [countryCode, setCountryCode] = useState("91"); // Default to India country code
    const [countryCodeName, setCountryCodeName] = useState("IN");
    const [locationInfo, setLocationInfo] = useState("");
    const [ipAddress, setIpAddress] = useState("");
    const [deviceInfo, setDeviceInfo] = useState("");
    const [captchaVerified, setCaptchaVerified] = useState(false);

    useEffect(() => {
        const getLocation = async () => {
            try {
                const location = await Common.fetchGeolocation();
                setLocationInfo(location);
            } catch (error) {
                setLocationInfo("Unknown Location");
            }
        };
        getLocation();
    }, []);

    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const response = await fetch(
                    "https://api64.ipify.org?format=json"
                );
                if (!response.ok) throw new Error("Failed to fetch IP address");
                const data = await response.json();
                setIpAddress(data.ip);
            } catch (error) {
                console.error("Error fetching IP address:", error);
            }
        };
        fetchIPAddress();
    }, []);

    useEffect(() => {
        const fetchDeviceInfo = () => {
            const browserInfo = Common.getBrowserInfo();
            const osInfo = Common.getOSInfo();
            setDeviceInfo(`${browserInfo} (${osInfo})`);
        };
        fetchDeviceInfo();
    }, []);

    const getOTP = async (number) => {
        if (number) {
            let param = {
                location: locationInfo,
                ipAddress: ipAddress,
                device: deviceInfo,
                mobileNumber: "+" + number,
                // IsCompanySignup: true,
            };
            try {
                let res = await sendOTPSignup(param);
                if (res && res.success && res.isOTPSent == false) {
                    setAlerts({ type: "danger", message: res.success });
                    handleNext(false);
                }
            } catch (error) {
                console.error("Error sending OTP:", error);
                return false;
            }
        }
    };

    const handlePhoneChange = (value, data) => {
        setCountryCodeName(data?.countryCode.toUpperCase());
        setPhoneNumber(value);
        setCountryCode(data?.dialCode); // Set country code when country is changed
        setFormData({
            ...formData,
            registerMobileNumber: value,
            countryCode: data?.dialCode, // Capture country code
        });
    };

    const sendOTPHandler = async () => {
        // Check if the phone number starts with the correct country code

        // // Parse the adjusted phone number
        // const phoneNumberParsed = parsePhoneNumberFromString(
        //   adjustedPhoneNumber,
        //   countryCodeName
        // );

        // // Log the parsed phone number to see what's being returned
        // console.log("Parsed Phone Number:", phoneNumberParsed);

        // Check if phone number is valid
        // if (!phoneNumberParsed || !phoneNumberParsed.isValid()) {
        //   setAlerts({
        //     type: "danger",
        //     message: "Invalid Phone Number",
        //   });
        //   return;
        // }

        if (!phoneNumber) {
            await setAlerts({
                type: "danger",
                message: "Please enter the mobile number.",
            });
            return;
        }

        // Handle CAPTCHA verification
        if (captchaVerified === false) {
            await setAlerts({
                type: "danger",
                message: "Please verify captcha",
            });
            return;
        }

        // Send OTP if phone number is valid
        getOTP(
            // phoneNumberParsed?.number.startsWith("+")
            //   ? phoneNumberParsed.number.substring(1)
            //   : phoneNumberParsed?.number
            phoneNumber
        );

        handleNext();
    };

    const onChangeCaptcha = (value) => setCaptchaVerified(value !== null);

    return (
        <>
            <FormGroup className="has-label">
                <PhoneInput
                    containerClass="react-tel-input-custom"
                    buttonClass="flag-search-btn-custom"
                    dropdownClass="flag-dropdown-custom"
                    inputProps={{ autoFocus: true }}
                    country={"in"} // Default to India
                    placeholder="Enter Mobile Number"
                    value={phoneNumber}
                    onChange={handlePhoneChange}
                    disabled={otpVerify}
                    enableLongNumbers={true}
                />
            </FormGroup>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ReCAPTCHA
                    sitekey="6Lcj7NAZAAAAAPTLGuMNtZIuSdxhFFHdgb4LizcG"
                    onChange={onChangeCaptcha}
                />
            </div>

            <div className="d-flex flex-column" style={{ gap: "10px" }}>
                <button className="custom-login-btn" onClick={sendOTPHandler}>
                    Send OTP
                </button>

                <button
                    className="custom-login-btn secondary-login-btn"
                    onClick={() => navigate(AUTH_URL_LOGIN)} // Redirect on click
                >
                    Login
                </button>
            </div>
        </>
    );
};

export default MobileInput;
