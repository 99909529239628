import { useSelector } from "react-redux";
import NotificationRepository from "repository/NotificationRepository";
import store from "../store/store";
import { getNotificationList } from "store/slice/Notification";

export function useNotificationStore() {
  /**
   * Here is list of use selectors which are relates to notification
   * Author: Devanshi Patel
   */

  const notificationList = useSelector(
    (state) => state.entities.Notification.notificationList
  );

  let data = {
    notificationList,
  };
  let fetch = {
    /**
     * Here is list of fetch methods which are used for get data from API or Repositories related to notification
     * Author: Devanshi Patel
     * dispatch is used for reducer or thunk method
     * if redux not needed use repository method
     */
    sendSMS: async (params) => {
      return await new NotificationRepository().sendSMS(params);
    },
    sendEmail: async (params) => {
      return await new NotificationRepository().sendEmail(params);
    },
    sendWPSMS: async (params) => {
      return await new NotificationRepository().sendWPSMS(params);
    },
    sendOTP: async (params) => {
      return await new NotificationRepository().sendOTP(params);
    },
    sendOTPSignup: async (params) => {
      return await new NotificationRepository().sendOTPSignup(params);
    },
    sendOTPGeneral: async (params) => {
      return await new NotificationRepository().sendOTPGeneral(params);
    },
    sendOTPForgetPassword: async (params) => {
      return await new NotificationRepository().sendOTPForgetPassword(params);
    },
    verifyOTP: async (params) => {
      return await new NotificationRepository().verifyOTP(params);
    },
    getNotificationStatus: async () => {
      return await new NotificationRepository().getNotificationStatus();
    },
    getNotificationList: async (params) => {
      return await store.dispatch(getNotificationList(params));
    },
    getNotificationById: async (params) => {
      return await new NotificationRepository().getNotificationById(params);
    },
    SendSignupEmail: async (params) => {
      return await new NotificationRepository().SendSignupEmail(params);
    },
  };
  let set = {
    /**
     * Here is list of fetch methods which are used for set or update data from API or Repositories related to notification
     * Author: Devanshi Patel
     */

    createNotification: async (params) => {
      return await new NotificationRepository().createNotification(params);
    },
    updateNotification: async (params) => {
      return await new NotificationRepository().updateNotification(params);
    },
    updateNotificationStatus: async (params) => {
      return await new NotificationRepository().updateNotificationStatus(
        params
      );
    },
  };
  return [data, fetch, set];
}
