import NotificationAPICall from "service/NotificationAPICall";
import BaseRepository from "./BaseRepository";
import Common from "helpers/Common";

class NotificationRepository extends BaseRepository {
  sendSMS = async (params) => {
    return await new NotificationAPICall().sendSMS(params);
  };

  sendEmail = async (params) => {
    return await new NotificationAPICall().sendEmail(params);
  };

  SendSignupEmail = async (params) => {
    return await new NotificationAPICall().SendSignupEmail(params);
  };

  sendWPSMS = async (params) => {
    return await new NotificationAPICall().sendWPSMS(params);
  };

  sendOTP = async (params) => {
    return await new NotificationAPICall().sendOTP(params);
  };

  sendOTPSignup = async (params) => {
    return await new NotificationAPICall().sendOTPSignup(params);
  };

  sendOTPGeneral = async (params) => {
    return await new NotificationAPICall().sendOTPGeneral(params);
  };

  sendOTPForgetPassword = async (params) => {
    return await new NotificationAPICall().sendOTPForgetPassword(params);
  };

  verifyOTP = async (params) => {
    return await new NotificationAPICall().verifyOTP(params);
  };
  getNotificationStatus = async () => {
    let res = await Common.getCompanyProfileSetting();
    return {
      SMSNotification: Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "sms_enabled"
      )[0],
      EmailNotification: Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "email_enabled"
      )[0],
      WhatsappNotification: Common.getValueByKeyWithSpecificValueFromKey(
        res,
        "feature_key",
        "whatsup_enabled"
      )[0],
    };
  };
  getNotificationList = async (params) => {
    return await new NotificationAPICall().getNotificationList(params);
  };
  createNotification = async (params) => {
    return await new NotificationAPICall().createNotification(params);
  };
  updateNotification = async (params) => {
    return await new NotificationAPICall().updateNotification(params);
  };
  getNotificationById = async (params) => {
    return await new NotificationAPICall().getNotificationById(params);
  };
  updateNotificationStatus = async (params) => {
    return await new NotificationAPICall().updateNotificationStatus(params);
  };
}

export default NotificationRepository;
