import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useEffect, useRef, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import "../../assets/styles/notification/CustomerNotification.css";
import { RiInformationLine } from "react-icons/ri"; // Import the info icon
import { IoMdCloseCircle } from "react-icons/io";
import { ERP_CDN_IMAGE_URL } from "helpers/utils";
// reactstrap components
import {
  Label,
  Button,
  Input,
  Col,
  Row,
  FormGroup,
  CardTitle,
  FormFeedback,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { isEmpty } from "underscore";
import { useCompanyStore } from "hooks/Company";
import Select from "react-select";
import CustomButton from "components/Button/CustomButton";

const NotificationWPSchedule = (props) => {
  const fileUploadRef = useRef();
  const ref = useRef();
  const [file, setFile] = useState(null);
  const [
    {},
    { getNotificationList },
    { createNotification, updateNotification },
  ] = useNotificationStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [{ customerList }, {}, {}] = useCompanyStore();

  const [loader, setLoader] = useState(false);
  const [notificationValidate, setNotificationValidate] = useState({});
  const [notificationData, setNotificationData] = useState({});
  const [selectedFile, setSelectedFile] = useState("");
  const [customerEmail, setCustomerEmail] = useState(null);
  const [fileName, setFileName] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileType, setFileType] = useState(""); // To keep track of file type

  useEffect(() => {
    if (props.notificationData) {
      setNotificationData(props.notificationData);
      setCustomerEmail(props.notificationData.customerEmail);
    }
  }, [props.notificationData]);

  const getCustomerList = () => {
    let custList = [];
    custList.push({
      value: "ALL",
      label: "ALL",
    });
    let list =
      customerList &&
      !isEmpty(customerList) > 0 &&
      customerList.map((prop, key) => {
        return {
          value: prop.customerID,
          label: prop.customerName + " (" + prop.phoneNumber + ")",
        };
      });
    custList = custList.concat(list);
    return custList;
  };

  const onCustomerChange = (selectedItems) => {
    // Check if the "Select All" option is selected
    if (selectedItems.some((item) => item.value === "ALL")) {
      let filteredOptions = getCustomerList();
      setCustomerEmail(
        filteredOptions.filter((option) => option.value !== "ALL")
      );
    } else {
      setCustomerEmail(selectedItems);
    }

    let error = { ...notificationValidate };

    if (selectedItems && selectedItems.length > 0) {
      error.customerEmail = "has-success";
    } else {
      error.customerEmail = "has-danger";
    }
    setNotificationValidate(error);
  };

  const onRemoveFile = () => {
    setFileName("");
    if (fileUploadRef.current) {
      fileUploadRef.current.value = null;
    }
  };

  const onFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const allowedTypes = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (allowedTypes.includes(file.type)) {
        setFileName(file.name);
        setSelectedFile(event.target.files[0]);
        const reader = new FileReader();

        reader.onloadend = () => {
          setFilePreview(reader.result);
          setFileType(file.type);
        };

        // Read the file as a data URL for preview
        reader.readAsDataURL(file);
      } else {
        alert("Please upload a valid file type (Image, PDF, Word)");
        setSelectedFile(null);
        setFileName("");
        setFilePreview("");
      }
    }
  };

  const handlePreview = () => {
    if (filePreview) {
      setIsModalOpen(true); // Open the modal to show the preview
    } else {
      alert("No image selected for preview.");
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen); // Toggle modal visibility
  };

  const WPNotificationSave = async (e) => {
    setLoader(true);
    e.preventDefault();

    let error = false;
    let validate = { ...notificationValidate };
    if (!notificationData.notificationSettingID) {
      if (notificationValidate && isEmpty(notificationValidate)) {
        error = true;
        validate.scheduleName = "has-danger";
        validate.customerEmail = "has-danger";
        validate.frequency = "has-danger";
        validate.fromDate = "has-danger";
        validate.toDate = "has-danger";
        validate.subject = "has-danger";
        validate.body = "has-danger";
      } else {
        if (
          !notificationValidate.scheduleName ||
          notificationValidate.scheduleName === "has-danger"
        ) {
          error = true;
          validate.scheduleName = "has-danger";
        }
        if (
          !notificationValidate.customerEmail ||
          notificationValidate.customerEmail === "has-danger"
        ) {
          error = true;
          validate.customerEmail = "has-danger";
        }
        if (
          !notificationValidate.frequency ||
          notificationValidate.frequency === "has-danger"
        ) {
          error = true;
          validate.frequency = "has-danger";
        }
        if (
          !notificationValidate.fromDate ||
          notificationValidate.fromDate === "has-danger"
        ) {
          error = true;
          validate.fromDate = "has-danger";
        }
        if (
          !notificationValidate.toDate ||
          notificationValidate.toDate === "has-danger"
        ) {
          error = true;
          validate.toDate = "has-danger";
        }
        if (
          !notificationValidate.subject ||
          notificationValidate.subject === "has-danger"
        ) {
          error = true;
          validate.subject = "has-danger";
        }
        if (
          !notificationValidate.body ||
          notificationValidate.body === "has-danger"
        ) {
          error = true;
          validate.body = "has-danger";
        }
      }
    } else {
      if (notificationValidate.scheduleName === "has-danger") {
        error = true;
        validate.scheduleName = "has-danger";
      }
      if (notificationValidate.customerEmail === "has-danger") {
        error = true;
        validate.customerEmail = "has-danger";
      }
      if (notificationValidate.frequency === "has-danger") {
        error = true;
        validate.frequency = "has-danger";
      }
      if (notificationValidate.fromDate === "has-danger") {
        error = true;
        validate.fromDate = "has-danger";
      }
      if (notificationValidate.toDate === "has-danger") {
        error = true;
        validate.toDate = "has-danger";
      }
      if (notificationValidate.subject === "has-danger") {
        error = true;
        validate.subject = "has-danger";
      }
      if (notificationValidate.body === "has-danger") {
        error = true;
        validate.body = "has-danger";
      }
    }

    setNotificationValidate(validate);

    if (!error) {
      let formData = new FormData();
      let customerList = [];
      let isSelectedAll = false;
      customerEmail.map((v) => {
        customerList.push(v.value);
        if (v.value === "ALL") {
          isSelectedAll = true;
        }
      });

      formData.append("NotificationTypeID", 3);
      formData.append("ScheduleName", notificationData.scheduleName);
      formData.append("FrequencyID", notificationData.frequency);
      formData.append("FromDate", notificationData.fromDate);
      formData.append("ToDate", notificationData.toDate);
      formData.append("Subject", notificationData.subject);
      formData.append("Body", notificationData.body);
      formData.append("To", isSelectedAll ? "ALL" : customerList);

      if (selectedFile) {
        formData.append("attchedFile", selectedFile);
      }

      let res = "";
      if (notificationData.notificationSettingID) {
        formData.append(
          "notificationSettingID",
          notificationData.notificationSettingID
        );
        res = await updateNotification(formData);
      } else {
        res = await createNotification(formData);
      }

      if (res) {
        await setAlerts({
          type: "success",
          message: notificationMessages.notificationSCheduleSuccess,
        });
        await getNotificationList({ NotificationTypeID: 3 });
      }
      reset(e);
    }
    setLoader(false);
  };

  const fileUploadBtnClick = (e) => {
    fileUploadRef.current.click();
  };

  const reset = (ev) => {
    ev.preventDefault();
    ref.current.reset();
    setNotificationData({});
    setNotificationValidate({});
    setCustomerEmail(null);
    setFileName("");
    setSelectedFile("");
    setFile(null);
  };

  const removeImage = () => {
    setFile(null);
    setFileName("");
  };

  const uploadProduct = (e) => {
    let fileSize = e.target.files[0].size / 1024 / 1024;
    if (fileSize > 2) {
      alert("Please select image size lesser than 2MB.");
      e.target.value = "";
    } else {
      const file = event.target.files[0];
      let fileName =
        Common.getCompanyId() +
        "_" +
        Date.now() +
        "." +
        e.target.files[0].name.split(".")[1];
      setFile(new File([e.target.files[0]], fileName));
      setFileName(file.name);
      setSelectedFile(e.target.files[0]);
    }
  };

  const getFrequencyList = () => {
    return [
      { label: "Daily", value: 1 },
      { label: "Weekly", value: 2 },
      { label: "Week Day", value: 3 },
      { label: "Week Off", value: 4 },
      { label: "Monthly", value: 5 },
    ];
  };

  return (
    <>
      <Form className="form mt-4" innerRef={ref}>
        <Row>
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="has-label customer-input-container">
              <label>Schedule Name *</label>
              <Input
                placeholder="Subject"
                type="text"
                valid={notificationValidate?.scheduleName === "has-success"}
                invalid={notificationValidate?.scheduleName === "has-danger"}
                value={notificationData?.scheduleName}
                onChange={(e) => {
                  let error = { ...notificationValidate };
                  if (!Common.verifyLength(e.target.value, 1)) {
                    error.scheduleName = "has-danger";
                  } else {
                    error.scheduleName = "has-success";
                  }
                  setNotificationValidate(error);
                  setNotificationData({
                    ...notificationData,
                    scheduleName: e.target.value,
                  });
                }}
                maxLength={100}
              />
              <FormFeedback>Schedule name is required.</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="customer-input-container">
              <Label for="payment">Customer List *</Label>
              <Input
                name="customerList"
                placeholder="customer list"
                type="hidden"
                valid={notificationValidate?.customerEmail === "has-success"}
                invalid={notificationValidate?.customerEmail === "has-danger"}
              />
              <Select
                isMulti
                name="customerList"
                className="basic-multi-select customer-custom-select"
                classNamePrefix="select"
                value={customerEmail}
                options={getCustomerList()}
                placeholder="Select Customers"
                onChange={onCustomerChange}
              />
              <FormFeedback>
                Select atleast one customer from list.
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="custom-input-container">
              <Label for="payment">Frequency *</Label>
              <Select
                id="payment"
                name="payment"
                className="basic-multi-select customer-custom-select"
                classNamePrefix="select"
                options={getFrequencyList()}
                valid={notificationValidate?.frequency === "has-success"}
                invalid={notificationValidate?.frequency === "has-danger"}
                value={
                  notificationData?.frequency
                    ? getFrequencyList().find(
                        (option) => option.value === notificationData.frequency
                      )
                    : null
                }
                onChange={(e) => {
                  let error = { ...notificationValidate };
                  // Check if a valid option is selected
                  if (!e || !e.value) {
                    error.frequency = "has-danger"; // Set danger if no option is selected
                  } else {
                    error.frequency = "has-success"; // Set success if an option is selected
                  }

                  setNotificationValidate(error);
                  setNotificationData({
                    ...notificationData,
                    frequency: e.value, // Store the value in state
                  });
                }}
              />
              <FormFeedback>select any one frequency.</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <Row className="d-flex justify-content-between">
              <Col lg={6} sm={12} md={6}>
                <FormGroup className="customer-input-container">
                  <Label>From Date *</Label>
                  <Input
                    id="exampleDate"
                    name="date"
                    placeholder="from Date"
                    type="date"
                    valid={notificationValidate?.fromDate === "has-success"}
                    invalid={notificationValidate?.fromDate === "has-danger"}
                    value={notificationData?.fromDate}
                    onChange={(e) => {
                      let error = { ...notificationValidate };
                      if (!Common.verifyLength(e.target.value, 1)) {
                        error.fromDate = "has-danger";
                      } else {
                        error.fromDate = "has-success";
                      }
                      setNotificationValidate(error);
                      setNotificationData({
                        ...notificationData,
                        fromDate: e.target.value,
                      });
                    }}
                  />
                  <FormFeedback>From date is required.</FormFeedback>
                </FormGroup>
              </Col>
              <Col lg={6} sm={12} md={6}>
                <FormGroup className="customer-input-container">
                  <Label>To Date *</Label>
                  <Input
                    id="exampleDate"
                    name="date"
                    placeholder="To Date"
                    type="date"
                    valid={notificationValidate?.toDate === "has-success"}
                    invalid={notificationValidate?.toDate === "has-danger"}
                    value={notificationData?.toDate}
                    onChange={(e) => {
                      let error = { ...notificationValidate };
                      if (!Common.verifyLength(e.target.value, 1)) {
                        error.toDate = "has-danger";
                      } else {
                        error.toDate = "has-success";
                      }
                      setNotificationValidate(error);
                      setNotificationData({
                        ...notificationData,
                        toDate: e.target.value,
                      });
                    }}
                  />
                </FormGroup>
                <FormFeedback>To date is required.</FormFeedback>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="has-label custom-input-container">
              <label>Subject *</label>
              <Input
                placeholder="Subject"
                type="text"
                valid={notificationValidate?.subject === "has-success"}
                invalid={notificationValidate?.subject === "has-danger"}
                value={notificationData?.subject}
                onChange={(e) => {
                  let error = { ...notificationValidate };
                  if (!Common.verifyLength(e.target.value, 1)) {
                    error.subject = "has-danger";
                  } else {
                    error.subject = "has-success";
                  }
                  setNotificationValidate(error);
                  setNotificationData({
                    ...notificationData,
                    subject: e.target.value,
                  });
                }}
                maxLength={100}
              />
              <FormFeedback>Subject is required.</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <FormGroup className="has-label customer-input-container">
              <label>Body *</label>
              <Input
                placeholder="Subject"
                className="textarea"
                type="textarea"
                rows="1"
                valid={notificationValidate?.body === "has-success"}
                invalid={notificationValidate?.body === "has-danger"}
                value={notificationData?.body ? notificationData?.body : ""}
                onChange={(e) => {
                  let error = { ...notificationValidate };
                  if (!Common.verifyLength(e.target.value, 1)) {
                    error.body = "has-danger";
                  } else {
                    error.body = "has-success";
                  }
                  setNotificationValidate(error);
                  setNotificationData({
                    ...notificationData,
                    body: e.target.value,
                  });
                }}
                maxLength={200}
              />
              <FormFeedback>Body is required.</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        {notificationData?.attachedFileName && (
          <Row className="">
            <Col lg={4} sm={12} md={4}>
              <FormGroup className="has-label">
                <label>File Name: {notificationData.attachedFileName}</label>
              </FormGroup>
            </Col>
          </Row>
        )}
        {/* <Row className="mt-3" style={{ display: "flex", alignItems: "center" }}>
          <Col
            sm="12"
            md="9"
            lg="6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Ensures proper spacing between buttons
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              {/* Choose File button */}
        {/* <Button
                style={{
                  height: "40px",
                  width: "150px",
                  display: "inline-block",
                  borderRadius: "10px 0 0 10px",
                }}
                className="custom-button-secondary"
                onClick={(e) => {
                  e.preventDefault();
                  fileUploadRef.current.click(); // Programmatically trigger the file input
                }}
              >
                <span
                  style={{
                    color: "white",
                    fontFamily: "'DM Sans', sans-serif",
                  }}
                >
                  Choose File
                </span>
              </Button> */}

        {/* Hidden file input */}
        {/* <input
                ref={fileUploadRef}
                onChange={onFileChange}
                type="file"
                name="file"
                style={{ display: "none" }}
              /> */}

        {/* File name display */}
        {/* <div
                className="upload-file-name"
                style={{
                  height: "40px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1,
                  border: "1px solid #ccc", // Adds a border to separate file name and buttons
                  borderRadius: "0 10px 10px 0",
                }}
              >
                {fileName || "No file selected"}
              </div> */}

        {/* Remove button */}
        {/* {fileName && (
                <Button
                  onClick={onRemoveFile}
                  color="danger"
                  style={{
                    marginLeft: "10px",
                    height: "40px",
                    display: "inline-block",
                  }}
                >
                  Remove
                </Button>
              )} */}

        {/* Preview button */}
        {/* {fileName && (
                <Button
                  onClick={handlePreview}
                  style={{
                    marginLeft: "10px",
                    height: "40px",
                    display: "inline-block",
                    backgroundColor: "#6c757d", // Match the grey color of your example
                  }}
                >
                  Preview
                </Button>
              )} */}
        {/* </div>
          </Col> */}

        {/* Allowed file types moved below the buttons */}
        {/* <Col
            sm="12"
            md="12"
            lg="12"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <RiInformationLine
              style={{ marginRight: "5px", color: "#007bff" }}
            />
            <span style={{ fontSize: "12px", color: "gray" }}>
              Allowed file types: .png, .jpg, .jpeg, .pdf, .doc, .docx
            </span>
          </Col>
        </Row>  */}

        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col
            sm="12"
            md="9"
            lg="6"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between", // Ensures proper spacing between buttons
            }}
          >
            <FormGroup className="custom-input-container">
              <input
                accept="image/x-png,image/svg,image/jpeg,image/jpg"
                onChange={(e) => uploadProduct(e)}
                type="file"
                name="file"
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12} md={6}>
            <div className="inventory-image-preview">
              <div className="inventory-img-container">
                {file ? (
                  <>
                    <img
                      className="displayImg"
                      src={URL.createObjectURL(file)}
                      alt="no pic"
                    />
                    <IoMdCloseCircle
                      style={{
                        top: "-15px",
                        right: "70px",
                      }}
                      size={20}
                      className="remove-inventory-img-btn"
                      onClick={removeImage}
                    />
                  </>
                ) : (
                  <img
                    className="displayImg"
                    src={`${ERP_CDN_IMAGE_URL}/placeholder.jpeg`}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop if logo also fails
                      e.target.src = Placeholder;
                    }}
                    alt="nopic"
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <div
              className="customer-button-container"
              style={{
                justifyContent: "center",
              }}
            >
              <CustomButton label="Save Details" onClick={WPNotificationSave} />
            </div>
          </Col>
        </Row>
      </Form>

      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>File Preview</ModalHeader>
        <ModalBody>
          {fileType.startsWith("image/") ? (
            <img
              src={filePreview}
              alt="Preview"
              style={{ width: "100%", height: "auto" }}
            />
          ) : fileType === "application/pdf" ? (
            <iframe
              src={filePreview}
              style={{ width: "100%", height: "500px" }}
              title="PDF Preview"
            />
          ) : (
            <div>Preview not available for this file type.</div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default NotificationWPSchedule;
