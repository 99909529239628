import React, { useEffect, useRef, useState } from "react";
import recent from "assets/img/icons/createBill/template.png";
import { Tooltip } from "reactstrap";
import { useTemplateStore } from "hooks/Template";
import CustomButton from "components/Button/CustomButton";
import { HiOutlineTemplate } from "react-icons/hi";

const TemplateButton = ({
  tooltipOpenRecentProduct,
  toggleTooltipRecent,
  handleTemplateSelect,
}) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [templateList, setTemplateList] = useState([])

  const [{ }, { getTemplateList }, { }] = useTemplateStore();
  const popupRef = useRef();

  const retrieveTemplatesList = async () => {
    let data = await getTemplateList({ isActive: 1 });
    setTemplateList(data);
  };

  const togglePopup = () => {
    retrieveTemplatesList()
    setPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupVisible(false);
    }
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="filter-container-recent" ref={popupRef}>
        {/* <button
          style={{
            background: "transparent",
            border: "none",
            marginBottom: "10px",
          }}
          id="recentProduct"
          type="button"
          onClick={togglePopup}
        >
          <img
            src={recent}
            alt="Recent Product List (Template)"
            className="bill-icon"
          />
        </button> */}

        <CustomButton
          label="Templates"
          id="recentProduct"
          icon={<HiOutlineTemplate />}
          customStyle="custom-primary-button custom-filter-btn"
          onClick={togglePopup}
        />

        {/* <Tooltip
          autohide={true}
          flip={true}
          placement="top"
          isOpen={tooltipOpenRecentProduct}
          target="recentProduct"
          toggle={toggleTooltipRecent}
          style={{ backgroundColor: "#000000", color: "#ffffff" }}
        >
          Templates
        </Tooltip> */}

        {isPopupVisible && (
          <>
            <div className="filter-popup-recent">
              <div
                style={{
                  maxHeight: "200px",
                  overflowY: "auto",
                  width: "100%",
                }}
                className="scrollbar-custom"
              >

                <div className="template-list-container">
                  {templateList &&
                    templateList.length > 0 ?
                    templateList.map((item, index) => (
                      <div
                        key={index}
                        className="list-item-template-style"
                        onClick={() => { handleTemplateSelect(item.templateID); closePopup(); }}
                      >
                        {item.templateName}
                      </div>
                    )) :
                    <div className="text-center">
                      No Template Found
                    </div>
                  }
                </div>
              </div>

            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TemplateButton;
