import { notificationMessages } from "helpers/constantMessages";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
import { useEffect, useState } from "react";
import { FaCommentSms, FaEnvelope } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io5";
import { CustomInput, FormGroup, Input, Label, Col, Row } from "reactstrap";
import Select from "react-select";
import moment from "moment-timezone";
import Common from "helpers/Common";

const TimeZoneSettings = ({ taxDetails, getGenricData }) => {
  const [{}, { getTimeZone }, { saveCustomerEnableServicesDetails }] =
    useCompanyProfileStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  const [emailEnable, setEmailEnable] = useState(false);
  const [smsEnable, setSMSEnable] = useState(false);
  const [whatsappEnable, setWhatsappEnable] = useState(false);
  const [companyTzSelect, setCompanyTzSelect] = useState(null);
  const [userTzSelect, setUserTzSelect] = useState(null);
  const [timezones, setTimezones] = useState([]);

  const getTimezonesWithOffsets = () => {
    const timezonesArray = moment.tz.names(); // Get all timezone names

    // Map through the timezones and calculate their offsets
    return timezonesArray.map((tz) => {
      const offsetInMinutes = moment.tz(tz).utcOffset(); // Get offset in minutes
      const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
      const minutes = Math.abs(offsetInMinutes) % 60;
      const sign = offsetInMinutes >= 0 ? "+" : "-";
      const formattedOffset = `${sign}${String(hours).padStart(
        2,
        "0"
      )}:${String(minutes).padStart(2, "0")}`;

      return {
        value: tz, // Value to be stored
        label: `${tz} (UTC${formattedOffset})`, // Label to be shown in the dropdown
      };
    });
  };

  useEffect(() => {
    const tzList = getTimezonesWithOffsets();
    setTimezones(tzList);
  }, []);

  useEffect(() => {
    try {
      setEmailEnable(
        taxDetails?.filter((data) => data.feature_key == "email_enabled")[0]
          .feature_value == "YES"
          ? true
          : false
      );
      setSMSEnable(
        taxDetails?.filter((data) => data.feature_key == "sms_enabled")[0]
          .feature_value == "YES"
          ? true
          : false
      );
      setWhatsappEnable(
        taxDetails?.filter((data) => data.feature_key == "whatsup_enabled")[0]
          .feature_value == "YES"
          ? true
          : false
      );

      let timeZone = getTimeZone();

      if (timeZone?.companyTimeZone) {
        const offsetInMinutes = moment
          .tz(timeZone?.companyTimeZone)
          .utcOffset(); // Get offset in minutes
        const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const minutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes >= 0 ? "+" : "-";
        const formattedOffset = `${sign}${String(hours).padStart(
          2,
          "0"
        )}:${String(minutes).padStart(2, "0")}`;

        setCompanyTzSelect({
          value: timeZone?.companyTimeZone, // Value to be stored
          label: `${timeZone?.companyTimeZone} (UTC${formattedOffset})`,
        });
      }

      if (timeZone?.userTimeZone) {
        const offsetInMinutes = moment.tz(timeZone?.userTimeZone).utcOffset(); // Get offset in minutes
        const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
        const minutes = Math.abs(offsetInMinutes) % 60;
        const sign = offsetInMinutes >= 0 ? "+" : "-";
        const formattedOffset = `${sign}${String(hours).padStart(
          2,
          "0"
        )}:${String(minutes).padStart(2, "0")}`;

        setUserTzSelect({
          value: timeZone?.userTimeZone, // Value to be stored
          label: `${timeZone?.userTimeZone} (UTC${formattedOffset})`,
        });
      }
    } catch (error) {}
  }, [taxDetails]);

  const saveCustomerDetails = async (value, type, isTimezone = false) => {
    let res = {};
    let param = {};
    if (isTimezone) {
      param = {
        feature_key:
          type === "companyTz"
            ? "company_level_timezone"
            : `${Common.getUserId()}_user_level_timezone`,
        feature_value: value,
      };
    }
    res = await saveCustomerEnableServicesDetails(param);
    if (res) {
      await getGenricData();
      await setAlerts({
        type: "success",
        message: notificationMessages.customerProfileSettings,
      });
    }
  };

  return (
    <div className="profile-content-container">
      <div className="profile-content-title">
        <h5>Time Zone Settings</h5>
      </div>
      <Row>
        <Col sm="12" md="4">
          <FormGroup className="profile-notification-setting-item">
            <div className="custom-input-container">
              <label>Select Company Timezone</label>
              <Input
                name="companyTz"
                placeholder="Select Company Timezone"
                type="hidden"
                id="companyTz"
              />
              <Select
                className="profile-custom-select"
                // classNamePrefix="react-select"
                placeholder="Select Company Timezone"
                name="companyTz"
                value={companyTzSelect}
                options={timezones}
                onChange={(e) => {
                  setCompanyTzSelect(e);
                  saveCustomerDetails(e.value, "companyTz", true);
                }}
              />
            </div>
          </FormGroup>
        </Col>
        <Col sm="12" md="4">
          <FormGroup className="profile-notification-setting-item">
            <div className="custom-input-container">
              <label>Select User Timezone</label>
              <Input
                name="userTz"
                placeholder="Select User Timezone"
                type="hidden"
                id="userTz"
              />
              <Select
                className="profile-custom-select"
                // classNamePrefix="react-select"
                placeholder="Select User Timezone"
                name="userTz"
                value={userTzSelect}
                options={timezones}
                onChange={(e) => {
                  setUserTzSelect(e);
                  saveCustomerDetails(e.value, "userTz", true);
                }}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default TimeZoneSettings;
