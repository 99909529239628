import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Container,
  Input,
  Button,
} from "reactstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// core components
import PageLoader from "components/Loader/PageLoader";
import { usePaymentStore } from "hooks/Payment";
import ChildLoader from "components/Loader/ChildLoader";
import { RiFileExcel2Fill } from "react-icons/ri";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import CustomButton from "components/Button/CustomButton";
import autoTable from "jspdf-autotable";

const AccountRecievable = () => {
  const [{}, { getAccountRecievableDetails }, {}] = usePaymentStore();
  const [accountRecData, setAccountRecData] = useState([]);

  const [amount, setAmount] = useState(0);
  const [fromDate, setfromDate] = useState(null);
  const [toDate, settoDate] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loader, setLoader] = useState(false);
  const dt = useRef(null);

  const pageSize = 10;

  const [pageNumber, setPageNumber] = useState(1);

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getAccountRecievable(pageNumber + 1);
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getAccountRecievable = async (page = 1) => {
    setLoader(true);
    if (page === 1) {
      setPageNumber(1);
    }

    const today = new Date();

    let param = {
      amount: amount == 0 ? null : amount,
      from_date: "2024-03-10",
      to_date: formatDate(today),
      pagesize: pageSize,
      pagenumber: page,
    };

    let Data = await getAccountRecievableDetails(param);

    if (page > 1) {
      setAccountRecData([...accountRecData, ...Data]);
    } else {
      setAccountRecData(Data);
    }

    setLoadingMore(false);
    setLoader(false);
  };

  const exportToPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "A4",
    });

    doc.setFontSize(12);
    doc.text("Account Recievable Report", 40, 40);

    autoTable(doc, {
      startY: 60,
      head: [
        [
          "Payment ID",
          "Service Name",
          "Status",
          "Contact Person",
          "Invoice Number",
          "Amount",
          "Date",
        ],
      ],
      body: accountRecData.map((data) => [
        data.transactionPaymentID,
        data.transactionServiceName,
        data.invoiceStatus,
        data.customerName,
        data.invoiceNumber,
        data.invoiceAmount,
        data.invoiceDate,
      ]),
      theme: "grid",
      styles: {
        cellPadding: 5,
        fontSize: 8,
        halign: "center",
      },
      headStyles: {
        fillColor: [52, 152, 219],
        textColor: [255, 255, 255],
      },
      footStyles: {
        fillColor: [52, 152, 219],
      },
    });

    doc.save("account_recievable_report.pdf");
  };

  const exportToExcel = () => {
    const worksheetData = [
      [
        "Payment ID",
        "Service Name",
        "Status",
        "Contact Person",
        "Invoice Number",
        "Amount",
        "Date",
      ],
      ...accountRecData.map((data) => [
        data.transactionPaymentID,
        data.transactionServiceName,
        data.invoiceStatus,
        data.customerName,
        data.invoiceNumber,
        data.invoiceAmount,
        data.invoiceDate,
      ]),
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      "Account Recievable Report"
    );

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, "account_recievable_report.xlsx");
  };

  useEffect(() => {
    setLoader(true);
    getAccountRecievable();
  }, []);

  const preventNegativeValues = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="content-dashboard-inventory">
          <div className="title-route-container menu-title-div">
            <h4 className="menu-title-h m-2">Payment / Account Recievable</h4>
          </div>

          <Card className="payment-data-card">
            <CardBody>
              <div className="payment-card-title-container">
                <h5></h5>

                <div className="payment-header-btn-container">
                  <button onClick={exportToExcel} className="export-xls-btn">
                    Export XLS <RiFileExcel2Fill size={20} />
                  </button>
                  <button onClick={exportToPDF} className="export-pdf-btn">
                    Export PDF <BsFileEarmarkPdfFill size={20} />
                  </button>
                </div>
              </div>
              <Row xs="2" className="mb-3">
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>Amount</Label>
                    <Input
                      type="number"
                      min="0"
                      maxLength={10}
                      placeholder="Enter Amount"
                      onChange={(e) => setAmount(e.target.value)}
                      onKeyDown={preventNegativeValues}
                      onPaste={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>From Date</Label>
                    <Input
                      id="fromDate"
                      name="date"
                      placeholder="From Date"
                      type="date"
                      value={fromDate}
                      onChange={(e) => {
                        setfromDate(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <FormGroup className="customer-input-container">
                    <Label style={{ display: "flex" }}>To Date</Label>
                    <Input
                      id="toDate"
                      name="date"
                      placeholder="From Date"
                      type="date"
                      value={toDate}
                      onChange={(e) => {
                        settoDate(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col
                  xs={12}
                  md={3}
                  lg={3}
                  className="d-flex justify-content-start align-items-end"
                  style={{ marginBottom: "10px" }}
                >
                  <CustomButton
                    label="Search"
                    onClick={() => getAccountRecievable()}
                  />
                </Col>
              </Row>
              {loader ? (
                <PageLoader />
              ) : (
                <Row>
                  <Col xs={12} md={12} lg={12} className="p-0">
                    <DataTable
                      tableStyle={{ minWidth: "25rem" }}
                      size="large"
                      resizableColumns
                      columnResizeMode="expand"
                      paginator
                      rowsPerPageOptions={[5, 10, 15]}
                      rows={5}
                      pt={{
                        root: { className: "table" },
                      }}
                      value={accountRecData ?? []}
                      stripedRows
                      scrollable
                      scrollHeight="500px"
                      ref={dt}
                    >
                      <Column
                        field="transactionPaymentID"
                        header="Payment ID"
                        align="center"
                      />
                      <Column
                        field="transactionServiceName"
                        header="Service Name"
                        align="center"
                      />
                      <Column
                        field="invoiceStatus"
                        header="Status"
                        align="center"
                      />
                      <Column
                        field="customerName"
                        header="Contact Person"
                        align="center"
                      />
                      <Column
                        field="invoiceNumber"
                        header="Invoice Number"
                        align="center"
                      />
                      <Column
                        field="invoiceAmount"
                        header="Amount"
                        align="center"
                        bodyStyle={{ fontWeight: "600", color: "#000000" }}
                      />
                      <Column field="invoiceDate" header="Date" align="left" />
                    </DataTable>
                  </Col>
                </Row>
              )}
              {loadingMore && <ChildLoader />}
            </CardBody>
          </Card>
        </div>
      )}{" "}
    </>
  );
};
export default AccountRecievable;
