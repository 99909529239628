import React, { useRef, useState } from "react";

// reactstrap components
import {
  Label,
  Button,
  Card,
  CardBody,
  Input,
  Col,
  Row,
  FormGroup,
  CardTitle,
  Popover,
  PopoverBody,
  Container,
} from "reactstrap";
import { AutoComplete } from "primereact/autocomplete";
import { MdDelete } from "react-icons/md";
import { useEffect } from "react";
import { useCreateBillStore } from "hooks/CreateBill";
import { isEmpty } from "underscore";
import { useTemplateStore } from "hooks/Template";
import { BsCurrencyRupee } from "react-icons/bs";
import TemplateList from "./TemplateList";
import { useLoginStore } from "hooks/Login";
import "index.css";
import deleteicon from "assets/img/icons/createBill/deleteicon.png";
import addicon from "assets/img/icons/createBill/add.png";
import percentage from "assets/img/icons/createBill/percentage3.png";
import rupee from "assets/img/icons/createBill/rupee.png";
import AdvanceFilterModal from "views/CreateBill/AdvanceFilterModal";
import getSymbolFromCurrency from "currency-symbol-map";
import CreateBillRepository from "repository/CreateBillRepository";
import ChildLoader from "components/Loader/ChildLoader";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoAddCircleOutline } from "react-icons/io5";
import "assets/styles/CreateBill.css";
import "assets/styles/bills/CreateBillTemplate.css";
import PlusMinusButton from "components/Button/PlusMinusButton";
import CustomButton from "components/Button/CustomButton";
import moment from "moment";
import Common from "helpers/Common";
import { RiAddFill, RiArrowRightLine } from "react-icons/ri";
import { Tooltip } from "react-tooltip";

const CreateBillTemplate = () => {
  const debounceTimer = useRef(null);
  const currency = new CreateBillRepository().getCurrencyAbbriviation();
  const [qty, setQty] = useState();
  const [rate, setRate] = useState();
  const [amount, setAmount] = useState(0);
  const [discount, setDiscount] = useState();
  const [productCostBeforeTax, setProductCostBeforeTax] = useState(0);
  const [SGST, setSGST] = useState();
  const [CGST, setCGST] = useState();
  const [productCost, setProductCost] = useState(0);
  const [particulars, setParticulars] = useState("");
  const [rows, setRows] = useState([]);
  const [manualDiscount, setManualDiscount] = useState(null);
  const [manualDiscountAmount, setManualDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [igstAmount, setIgstAmount] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [filteredProduct, setFilteredProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [selectedRowProducts, setSelectedRowProducts] = useState(null);
  const [popoverOpen, setPopoverOpen] = useState(null);
  const [showInRupees, setShowInRupees] = useState(false);
  const [igstPercentage, setIgstPercentage] = useState(18);
  const [templateName, setTemplateName] = useState("");
  const [{}, { getProductSearchList }, {}] = useCreateBillStore();
  const [productSearch, setProductSearch] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [expiryDays, setExpiryDays] = useState(null);

  const formatDate = (dateString) => {
    if (dateString !== null) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // +1 because months are 0-indexed.
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }
  };

  const [{ templateDetail }, {}, { createBillTemplate, updateBillTemplate }] =
    useTemplateStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  const togglePopover = (index) => {
    if (popoverOpen === index) {
      setPopoverOpen(null);
    } else {
      setPopoverOpen(index);
    }
  };

  useEffect(() => {
    // Fetch expiry_days from localStorage
    const companyProfile = JSON.parse(localStorage.getItem("companyProfile"));

    if (companyProfile) {
      const expiryDaysObj = companyProfile.find(
        (item) => item.feature_key === "expiry_days"
      );
      if (expiryDaysObj) {
        setExpiryDays(Number(expiryDaysObj.feature_value)); // Ensure it's a number
      }
    }
  }, []);

  const retriveProducts = async (queryText, type = "add", index = 0) => {
    if (queryText.length >= 2) {
      // Check if at least 2 characters are entered
      let productList = await getProductSearchList(queryText);

      if (productList && !isEmpty(productList)) {
        setFilteredProducts(productList);

        // console.log(productList);

        if (productList.length === 1) {
          if (type === "add") {
            addRow(productList[0]);
            resetFields();
            setProductSearch("");
            setFilteredProducts([]);
          } else if (type === "update") {
            const updatedProduct = productList[0];

            // console.log(index, updatedProduct);
            updateRowProduct(index, updatedProduct);
            resetFields();
            setProductSearch("");
            setFilteredProducts([]);
          }
        }
      } else {
        /**
         * display no result found when no data get in response
         * Author: Devanshi Patel
         */
        setFilteredProducts([
          {
            productName: (
              <span style={{ cursor: "none", padding: "70px" }}>
                no result found
              </span>
            ),
          },
        ]);
      }
    } else {
      /**
       * Display error when only 1 character is type for product search auto complete
       * Author: Devanshi Patel
       */
      setTimeout(() => {
        setAlerts({
          type: "danger",
          message: "Minimum 2 characters are needed for product search",
        });
      }, 2000);
    }
  };

  const filterProduct = (event, type = "add", index = 0) => {
    const query = event.query;

    // Clear the existing timer if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      retriveProducts(query, type, index);
    }, 1000); // 1s delay
  };
  const updateRowProduct = (index, product) => {
    if (!product.productName || !product.unitPrice || !product.productId) {
      setAlerts({ type: "danger", message: "Please Select a Product" });
      return;
    }

    const productCostBeforeTaxCal =
      product?.unitPrice - (parseFloat(product?.product_discount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * product?.tax2) / 100;
    const CGSTCost = (productCostBeforeTaxCal * product?.tax1) / 100;

    // Update the row with new product details
    const newRow = {
      productName: product?.productName,
      qty: 1,
      rate: product?.unitPrice,
      amount: product?.unitPrice,
      discount: parseFloat(product?.product_discount) || 0,
      productCostBeforeTax: productCostBeforeTaxCal,
      SGST: product?.tax2,
      availableQty: product?.available_qty,
      expiryDate: product?.expiryDate || null,
      CGST: product?.tax1,
      productCost: productCostBeforeTaxCal + SGSTCost + CGSTCost,
    };
    const updatedRows = [...rows];
    updatedRows[index] = newRow; // Replace the row at the given index
    setRows(updatedRows);
  };

  const parseAndValidate = (val) => {
    const parsedValue = parseFloat(val);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = Math.max(0, value || 0); // Set to 0 if value is null or empty

    const thisRow = updatedRows[index];
    thisRow.amount = thisRow.qty * thisRow.rate;

    const discountAmount = parseAndValidate(thisRow.discountAmount);

    thisRow.productCostBeforeTax = thisRow.amount - discountAmount;
    thisRow.productCost =
      thisRow.productCostBeforeTax +
      (thisRow.productCostBeforeTax * thisRow.SGST) / 100 +
      (thisRow.productCostBeforeTax * thisRow.CGST) / 100;

    setRows(updatedRows);
  };

  useEffect(() => {
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    if (templateDetail && !isEmpty(templateDetail)) {
      if (
        templateDetail?.templateDetails &&
        !isEmpty(templateDetail?.templateDetails)
      ) {
        let templateDetails = templateDetail?.templateDetails;
        setTemplateName(templateDetails.templateName);
        let productInfo = [...templateDetails?.productInfos];
        productInfo.map((v, i) => {
          let v1 = { ...v };
          v1.discount = v1.discountAmount;
          v1.SGST = v1.sgst;
          v1.CGST = v1.cgst;
          v1.productCostBeforeTax = v1.productCost;
          v1.productCost = v1.netAmount;
          productInfo[i] = v1;
        });
        setRows(productInfo);
        setManualDiscount(templateDetails?.manualDiscount);
        setShowInRupees(
          templateDetails?.manualDiscountType === "percentage" ? false : true
        );
      }
    }
  }, [templateDetail]);

  useEffect(() => {
    const newAmount = qty * rate;

    if (isNaN(newAmount)) {
      // Check for NaN
      setAmount(0);
      return;
    }

    setAmount(newAmount);

    if (isNaN(discount)) {
      setProductCostBeforeTax(newAmount);
      const totalCostWithTax =
        newAmount + (newAmount * SGST) / 100 + (newAmount * CGST) / 100;
      setProductCost(parseFloat(totalCostWithTax));
      return;
    }
    const totalCostWithTax =
      newAmount -
      discount +
      ((newAmount - discount) * SGST) / 100 +
      ((newAmount - discount) * CGST) / 100;

    const newProductCostBeforeTax = newAmount - discount;
    setProductCostBeforeTax(newProductCostBeforeTax);

    setProductCost(totalCostWithTax);
  }, [qty, rate, discount]);

  useEffect(() => {
    const total = parseFloat(
      rows.reduce((acc, row) => acc + parseFloat(row.productCost), 0)
    );
    setSubTotal(total);

    const manualDiscountAmountCalculation = showInRupees
      ? manualDiscount !== null
        ? parseFloat(manualDiscount)
        : 0.0
      : (total * manualDiscount) / 100;

    const totalWithDiscount =
      total > 0 ? total - manualDiscountAmountCalculation : 0.0; // Apply manual discount

    setManualDiscountAmount(manualDiscountAmountCalculation);

    if (igstPercentage >= 0 && igstPercentage <= 100) {
      const igst = (totalWithDiscount * igstPercentage) / 100; // Calculate IGST
      setIgstAmount(igst); // Set IGST amount

      setTotalBeforeTax(totalWithDiscount);
      setTotalAmount(totalWithDiscount + igst);
    }
  }, [
    rows,
    updateRowProduct,
    updateRow,
    manualDiscount,
    showInRupees,
    igstPercentage,
  ]);

  const inputRefs = useRef([]);
  const [focusIndex, setFocusIndex] = useState(null);

  useEffect(() => {
    // This will set focus to the last input box in the array of refs whenever a new row is added.
    const lastRef = inputRefs.current[rows.length - 1];
    if (lastRef) {
      lastRef.focus();
    }
  }, [rows]);

  useEffect(() => {
    if (focusIndex !== null) {
      const input = document.getElementById(`qty-input-${focusIndex}`);
      if (input) {
        input.focus();
      }
    }
  }, [focusIndex]);

  const addRow = (data) => {
    // console.log(data);
    if (!data.productName || !data.unitPrice) {
      setAlerts({
        type: "danger",
        message:
          "Please select a product to the current row to add a new Product.",
      });
      return;
    }

    const isProductAlreadyAdded = rows.some(
      (row) => row.productId === data.productId
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });
      return;
    }

    setQty(1);
    setRate(data?.unitPrice);
    setSGST(data?.tax2);
    setCGST(data?.tax1);
    setDiscount(parseFloat(data?.product_discount));
    setExpiryDate(data?.expiry_date || undefined);

    const productCostBeforeTaxCal =
      data?.unitPrice - (parseFloat(data?.product_discount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * data?.tax2) / 100;
    const CGSTCost = (productCostBeforeTaxCal * data?.tax1) / 100;

    const newRow = {
      productId: data.productId,
      productDescription: data.productDescription,
      productName: data.productName,
      categoryId: data.categoryId,
      expiryDate: data.expiryDate || null,
      qty: 1,
      rate: data.unitPrice,
      availableQty: data?.available_qty,
      amount: data.unitPrice,
      discount: parseFloat(data?.product_discount) || 0,
      productCostBeforeTax: productCostBeforeTaxCal,
      SGST: data.tax2,
      CGST: data.tax1,
      productCost: productCostBeforeTaxCal + SGSTCost + CGSTCost,
    };

    const newRows = [...rows, newRow];

    setRows([...rows, newRow]);

    setFocusIndex(newRows.length - 1);

    setParticulars("");
    setQty(0);
    setRate(0);
    setCGST(0);
    setSGST(0);
    setProductCostBeforeTax(0);
    setProductCost(0);
    setDiscount(0);
    setExpiryDate(null);
  };

  const deleteRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const resetFields = () => {
    setSelectedProducts(null);
  };

  const saveTemplate = async () => {
    setLoading(true);

    if (!templateName) {
      setLoading(false);
      setAlerts({
        type: "danger",
        message: "Enter Template Name.",
      });
      return;
    }

    if (rows.length === 0) {
      setLoading(false);
      setAlerts({
        type: "danger",
        message: "Please Select any Product.",
      });
      return;
    }

    // console.log("Hello world", rows);

    const productInfoData = rows.map((item, index) => {
      return {
        productId: item.productId,
        productName: item.productName,
        qty: item.qty,
        rate: item.rate,
        cgst: item.CGST,
        cgstType: "PERCENTAGE",
        sgst: item.SGST,
        sgstType: "PERCENTAGE",
        expiryDate: item.expiryDate || null,
        active_status: 0,
        discountAmount: item.discount,
        productDiscountType: "AMOUNT",
        amount: item.amount,
        productCost: item.productCostBeforeTax,
        netAmount: parseFloat(item.productCost),
      };
    });

    // console.log(productInfoData);

    const totalBillDiscount = rows.reduce((accumulator, item) => {
      return accumulator + item.discount;
    }, 0);

    let result = "";
    if (templateDetail && !isEmpty(templateDetail)) {
      const params = {
        templateID: templateDetail.templateID,
        templateDetails: {
          templateName: templateName,
          templateType: "custome",
          productInfos: productInfoData,
          subTotal: parseFloat(subTotal).toFixed(2),
          manualDiscount: manualDiscount ? manualDiscount : 0,
          manualDiscountType: showInRupees ? "RUPEES" : "PERCENTAGE",
          totalBeforeTax: parseFloat(totalBeforeTax).toFixed(2),
          gst: igstPercentage,
          paidStatus: true,
          gstType: "PERCENTAGE",
          // grandTotal: parseFloat(totalAmount.toFixed(2)),
          grandTotal: parseFloat(totalBeforeTax).toFixed(2),
        },
      };

      result = await updateBillTemplate(params);
      setLoading(false);
    } else {
      const params = {
        templateName: templateName,
        templateType: "custome",
        productInfos: productInfoData,
        subTotal: parseFloat(subTotal).toFixed(2),
        manualDiscount: manualDiscount ? manualDiscount : 0,
        manualDiscountType: showInRupees ? "RUPEES" : "PERCENTAGE",
        totalBeforeTax: parseFloat(totalBeforeTax).toFixed(2),
        gst: igstPercentage,
        gstType: "PERCENTAGE",
        // grandTotal: parseFloat(totalAmount.toFixed(2)),
        grandTotal: parseFloat(totalBeforeTax.toFixed(2)),
      };

      setLoading(false);
      // console.log("params", params);

      result = await createBillTemplate(params);
    }

    if (result) {
      setLoading(false);
      await setAlerts({
        type: "success",
        message: "Template saved successfully",
      });
    }
    clearTemplate();
  };

  const clearTemplate = () => {
    setLoadingReset(true);
    setTemplateName("");
    setRows([]);
    setManualDiscount(0);
    setIgstAmount(0);
    setLoadingReset(false);
    setManualDiscount(0);
  };

  return (
    <div className="content-dashboard-createbill">
      {loading ? (
        <ChildLoader />
      ) : (
        <>
          <div
            className="menu-title-div d-flex align-items-center justify-content-between"
          >
            <h4 className="menu-title-h m-0">
              Bills / Combo Template (Offers)
            </h4>
          </div>
          <Card color="secondary" outline className="create-bill-template-card">
            {/* Bill Hero Section */}
            <div
              className="mb-3 pb-4"
              style={{ borderBottom: "1px solid #E0E0E0" }}
            >
              <Row>
                <Col xs="12" md="12" lg="12">
                  <div className="title-row">
                    <h4>Template Name</h4>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-center" style={{ rowGap: "10px" }}>
                <Col xs="12" sm="12" md="6" lg="3">
                  <Input
                    type="text"
                    className="custom-input-control"
                    delay={300}
                    placeholder="Template Name"
                    value={templateName}
                    onChange={(e) => setTemplateName(e.target.value)}
                    maxLength={100}
                  />
                </Col>
              </Row>
            </div>

            <>
              <div className="title-row">
                <h4>Purchase Details</h4>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table className="purchase-table">
                  <thead>
                    <tr>
                      <th className="product-name-col">Particulars</th>
                      <th className="qty-col">Quantity</th>
                      <th className="expiry-col">Expiry Date</th>
                      <th className="rate-col">Rate</th>
                      <th className="amount-col">Amount</th>
                      <th className="discount-col">Discount</th>
                      <th className="total-price-col">Total</th>
                      <th className="sgst-col">SGST (%)</th>
                      <th className="cgst-col">CGST (%)</th>
                      <th className="net-amount-col">Net Amount</th>
                      <th className="action-btn-col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td className="product-name-col cutomize-form-control">
                          <div className="product-seach-input">
                            <AutoComplete
                              className="custom-autocomplete"
                              value={
                                selectedRowProducts &&
                                selectedRowProducts?.value.length > 0 &&
                                selectedRowProducts?.index === index
                                  ? selectedRowProducts?.value
                                  : row.productName
                              }
                              suggestions={filteredProduct}
                              completeMethod={(e) =>
                                filterProduct(e, "update", index)
                              }
                              field="productName"
                              placeholder="Search by Product Name - atleast 2 characters"
                              minLength={1}
                              onChange={(e) => {
                                setSelectedRowProducts({
                                  index,
                                  value: e.value,
                                });
                                const updatedProduct = e.value;

                                setProductSearch(updatedProduct);
                                updateRow(index, "productName", updatedProduct);
                              }}
                              onSelect={(e) => {
                                updateRowProduct(index, e.value);
                              }}
                              pt={{
                                panel: {
                                  className: "autocomplete-Zindex",
                                },
                              }}
                              delay={300}
                            />
                            <AdvanceFilterModal
                              addRow={addRow}
                              setProductSearch={setProductSearch}
                              setSelectedProducts={setSelectedProducts}
                              index={index}
                              updateRowProduct={updateRowProduct}
                            />
                          </div>
                        </td>

                        <td className="qty-col cutomize-form-control">
                          <Input
                            className="cutomize-form-control-input custom-number-input"
                            id={`qty-input-${index}`}
                            type="number"
                            value={row.qty} // Changed to defaultValue
                            onChange={(e) => {
                              let newQty = parseFloat(e.target.value);
                              if (isNaN(newQty)) {
                                newQty = 0;
                              }
                              updateRow(index, "qty", Math.max(0, newQty));
                            }}
                          />
                        </td>

                        <td
                          className="expiry-col cutomize-form-control p-0"
                          style={{
                            fontSize: "15px",
                            color:
                              moment(row?.expiryDate).diff(moment(), "days") <=
                              expiryDays
                                ? "red" // Optional visual indicator for disabled
                                : "green",
                          }}
                        >
                          {Common.getFormatedDate(
                            row?.expiryDate,
                            "DD MMM YYYY",
                            true
                          ) !== "Invalid date"
                            ? Common.getFormatedDate(
                                row?.expiryDate,
                                "DD MMM YYYY",
                                true
                              )
                            : "N/A"}
                        </td>

                        <td className="rate-col cutomize-form-control">
                          <Input
                            className="custom-number-input"
                            type="number"
                            value={row.rate} // Changed to defaultValue
                            onChange={(e) => {
                              let newRate = parseFloat(e.target.value);
                              if (isNaN(newRate)) {
                                newRate = 0;
                              }
                              updateRow(index, "rate", Math.max(0, newRate));
                            }}
                          />
                        </td>

                        <td className="amount-col cutomize-form-control">
                          {row.amount.toFixed(2)}
                        </td>

                        <td className="discount-col cutomize-form-control">
                          <Input
                            className="cutomize-form-control-input custom-number-input"
                            type="number"
                            value={
                              isNaN(row?.discountAmount)
                                ? 0
                                : row?.discountAmount
                            }
                            onChange={(e) => {
                              let newDiscount = parseFloat(e.target.value);
                              if (isNaN(newDiscount)) {
                                newDiscount = 0;
                              }
                              updateRow(
                                index,
                                "discountAmount",
                                Math.max(0, newDiscount)
                              );
                            }}
                          />
                        </td>

                        <td className="total-price-col cutomize-form-control">
                          {row.productCostBeforeTax.toFixed(2)}
                        </td>

                        <td className="sgst-col cutomize-form-control pl-4">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <input
                              style={{
                                height: "34.5px",
                                fontSize: "14px",
                                paddingRight: "8px",
                                width: "60%",
                                boxSizing: "border-box",
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white",
                              }}
                              className="cutomize-form-control-input custom-number-input"
                              type="number"
                              value={row?.SGST || 0}
                              // onKeyDown={(e) => handleKeyDown(e, sgstRef)}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "SGST",
                                  Math.max(0, isNaN(value) ? 0 : value)
                                );
                              }}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                            />
                            <div
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              <span
                                data-tooltip-id="totalTooltip"
                                data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                                                                <p style="margin: 0; padding: 0;">SGST: ₹${(
                                                                  (row?.productCost *
                                                                    row?.SGST) /
                                                                  100
                                                                )?.toFixed(
                                                                  2
                                                                )}</p>
                                                            </span>`}
                              >
                                <RiArrowRightLine
                                  style={{
                                    marginLeft: "8px",
                                    color: "#4a6cf7",
                                    fontSize: "20px", // Increase this value to make the icon larger
                                  }}
                                />
                                <Tooltip
                                  style={{
                                    backgroundColor: "#434ce6",
                                    color: "white",
                                  }}
                                  id="totalTooltip"
                                  place="bottom"
                                />
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="cgst-col cutomize-form-control pl-4">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <input
                              style={{
                                height: "34.5px",
                                fontSize: "14px",
                                paddingRight: "8px",
                                width: "60%",
                                boxSizing: "border-box",
                                backgroundColor:
                                  row?.availableQty < 1 ||
                                  moment(row?.expiryDate).diff(
                                    moment(),
                                    "days"
                                  ) <= expiryDays
                                    ? "#f0f0f0"
                                    : "white",
                              }}
                              className="cutomize-form-control-input custom-number-input"
                              type="number"
                              // onKeyDown={(e) =>
                              //   handleKeyDown(e, autoCompleteRef)
                              // }
                              // ref={cgstRef}
                              value={row?.CGST || 0}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                updateRow(
                                  index,
                                  "CGST",
                                  Math.max(0, isNaN(value) ? 0 : value)
                                );
                              }}
                              disabled={
                                row?.availableQty < 1 ||
                                moment(row?.expiryDate).diff(
                                  moment(),
                                  "days"
                                ) <= expiryDays
                              }
                            />
                            <div
                              style={{
                                marginLeft: "8px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span
                                data-tooltip-id="totalTooltip"
                                data-tooltip-html={`<span style="color: white; margin: 0; padding: 0; display: block;">
                       <p style="margin: 0; padding: 0;">CGST: ₹${(
                         (row?.productCost * row?.CGST) /
                         100
                       )?.toFixed(2)}</p>
                     </span>`}
                              >
                                <RiArrowRightLine
                                  style={{
                                    marginLeft: "8px",
                                    color: "#4a6cf7",
                                    fontSize: "20px", // Increase this value to make the icon larger
                                  }}
                                />
                                <Tooltip
                                  style={{
                                    backgroundColor: "#434ce6",
                                    color: "white",
                                  }}
                                  id="totalTooltip"
                                  place="bottom"
                                />
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className="net-amount-col cutomize-form-control">
                          {row.productCost.toFixed(2)}
                        </td>

                        <td className="action-btn-col">
                          <FaRegTrashCan
                            className="create-bill-icon delete-row-icon"
                            id={`Popover-${index}`}
                            onClick={() => togglePopover(index)}
                          />
                          <Popover
                            placement="bottom"
                            isOpen={popoverOpen === index}
                            target={`Popover-${index}`}
                            toggle={() => togglePopover(index)}
                          >
                            <PopoverBody style={{ textAlign: "center" }}>
                              <span
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                  wordWrap: "break-word",
                                }}
                              >
                                Are you sure you want to delete it?
                              </span>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => {
                                  deleteRow(index);
                                  togglePopover(index);
                                }}
                              >
                                Delete
                              </Button>
                              <Button
                                color="secondary"
                                size="sm"
                                onClick={() => togglePopover(index)}
                              >
                                Cancel
                              </Button>
                            </PopoverBody>
                          </Popover>
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td className="product-name-col">
                        <div className="product-seach-input">
                          <AutoComplete
                            className="custom-autocomplete customize-autocomplete"
                            value={selectedProducts}
                            suggestions={filteredProduct}
                            completeMethod={filterProduct}
                            field="productName"
                            placeholder="Search by Product Name"
                            minLength={1}
                            onChange={(e) => {
                              setSelectedProducts(e.value);
                              setProductSearch(e.value);
                              setQty(1);
                              setRate(e.value?.unitPrice);
                              setSGST(e.value?.tax2);
                              setCGST(e.value?.tax1);
                              setDiscount(
                                parseFloat(e.value?.product_discount)
                              );
                            }}
                            onSelect={(e) => {
                              // This will run only when an item is actually selected from the dropdown

                              addRow(e.value);
                              resetFields();
                            }}
                            pt={{
                              panel: {
                                className: "autocomplete-Zindex",
                              },
                            }}
                            delay={300}
                          />

                          <AdvanceFilterModal
                            addRow={addRow}
                            setProductSearch={setProductSearch}
                            setSelectedProducts={setSelectedProducts}
                          />
                        </div>
                      </td>

                      <td className="qty-col cutomize-form-control">
                        <Input
                          className="custom-number-input"
                          value={qty}
                          min="0"
                          onChange={(e) => {
                            setQty(
                              Math.max(0, parseFloat(e.target.value) || 0)
                            );
                            setFocusIndex(null);
                          }}
                        />
                      </td>

                      <td
                        className="expiry-col cutomize-form-control p-0"
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        N/A
                      </td>

                      <td className="rate-col cutomize-form-control">
                        <Input
                          className="custom-number-input"
                          type="number"
                          value={rate}
                          min="0"
                          onChange={(e) =>
                            setRate(
                              Math.max(0, parseFloat(e.target.value) || 0)
                            )
                          }
                        />
                      </td>

                      <td className="amount-col cutomize-form-control">
                        {!isNaN(amount) ? amount : 0}
                      </td>

                      <td className="discount-col cutomize-form-control">
                        <Input
                          className="custom-number-input"
                          type="number"
                          value={discount}
                          min="0"
                          onChange={(e) =>
                            setDiscount(
                              Math.max(0, parseFloat(e.target.value) || 0)
                            )
                          }
                        />
                      </td>

                      <td className="total-price-col cutomize-form-control">
                        {productCostBeforeTax}
                      </td>

                      <td className="sgst-col cutomize-form-control">0</td>
                      <td className="cgst-col cutomize-form-control">0</td>
                      <td className="net-amount-col cutomize-form-control">
                        {productCost}
                      </td>
                      <td className="action-btn-col">
                        <IoAddCircleOutline
                          className="create-bill-icon"
                          onClick={addRow}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>

            <div className="invoice-summary-container">
              <Row className="w-100">
                <Col xs={12} sm={12} md={12} lg={8}>
                  <Row>
                    <Col md={12}>
                      <div className="d-flex align-items-center mb-2">
                        <h4 className="title">Tax & Discounts</h4>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ rowGap: "10px" }}>
                    <Col sx={12} sm={12} md={6} lg={6}>
                      <div className="invoice-form-control">
                        <label>
                          Manual Discount ({showInRupees ? "₹" : "%"})
                        </label>
                        <div className="custom-manual-discount-control">
                          <input
                            type="number"
                            min="0"
                            max="100"
                            value={manualDiscount}
                            style={{ width: "150px" }}
                            className="manual-discount-input"
                            onChange={(e) => {
                              let value = parseFloat(e.target.value);
                              if (isNaN(value)) {
                                value = "";
                              }

                              if (!showInRupees) {
                                if (value > 100) {
                                  value = 100;
                                } else if (value < 0) {
                                  value = 0;
                                }
                              }
                              setManualDiscount(value);
                            }}
                          />
                          <Input
                            id="discount"
                            name="discount"
                            type="select"
                            value={showInRupees}
                            className="type-dropdown"
                            onChange={(e) => setShowInRupees(!showInRupees)}
                          >
                            <option value={true}>Fixed</option>
                            <option value={false}>Percentage</option>
                          </Input>
                        </div>
                      </div>
                    </Col>
                    {/* <Col sx={12} sm={12} md={6} lg={6}>
                      <div className="invoice-form-control">
                        <label htmlFor="">IGST (%)</label>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="manual-igst-minus-btn"
                            onClick={() =>
                              setIgstPercentage(igstPercentage - 1)
                            }
                          >
                            -
                          </button>
                          <input
                            type="number"
                            min="0"
                            max="100"
                            value={igstPercentage || 0}
                            className="manual-igst-input"
                            onChange={(e) => {
                              setIgstPercentage(e.target.value);
                            }}
                          />
                          <button
                            className="manual-igst-plus-btn"
                            onClick={() =>
                              setIgstPercentage(igstPercentage + 1)
                            }
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  className="d-flex flex-column justify-content-between"
                >
                  <Row style={{ gap: "15px" }}>
                    {/* <Col md={12} className="invoice-summary-data-row">
                      <p>Total</p>
                      <p style={{ color: "#483EB6" }}>
                        {
                          "(" +
                          currency +
                          ") " +
                          getSymbolFromCurrency(currency) +
                          " " +
                          totalBeforeTax
                        }
                      </p>
                    </Col> */}
                    <Col md={12} className="invoice-summary-data-row">
                      <p>Sub Total</p>
                      <p style={{ color: "#483EB6" }}>{subTotal.toFixed(2)}</p>
                    </Col>
                    <Col
                      md={12}
                      className="invoice-summary-data-row invoice-summary-grand-total-row"
                    >
                      <p>Net Payable Amount</p>
                      <p style={{ color: "#58AD6B", fontSize: "15px" }}>
                        {" "}
                        {"(" +
                          (currency ?? "0") +
                          ") " +
                          (getSymbolFromCurrency(currency) ?? "") +
                          " " +
                          (totalBeforeTax ?? 0)?.toFixed(2)}{" "}
                      </p>
                      {/* <p>
                        {"(" +
                          currency +
                          ") " +
                          getSymbolFromCurrency(currency) +
                          " " +
                          totalAmount.toFixed(2)}
                      </p> */}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col
                      md={12}
                      className="d-flex justify-content-start align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <CustomButton
                        label={loading ? "Submitting..." : "Submit"}
                        disabled={loading}
                        onClick={saveTemplate}
                        customStyle="submit-btn"
                      />

                      <CustomButton
                        label={loadingReset ? "Reseting..." : "Reset"}
                        disabled={loadingReset}
                        onClick={clearTemplate}
                        customStyle="reset-btn"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </>
      )}
      <TemplateList loading={loading} setLoading={setLoading} />
    </div>
  );
};

export default CreateBillTemplate;
