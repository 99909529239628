import CustomButton from "components/Button/CustomButton";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useNotificationStore } from "hooks/Notification";
import React, { useState } from "react";
import { Card, CardBody, FormGroup, Label, Input } from "reactstrap";

const RaiseIssue = () => {
  const [{}, { sendEmail }, {}] = useNotificationStore(); // Move this inside the component
  const [{}, {}, { setAlerts }] = useLoginStore();

  const [issueSubject, setIssueSubject] = useState("");
  const [issueBody, setIssueBody] = useState("");
  const [validation, setValidation] = useState({ subject: "", body: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    let validationState = { subject: "", body: "" };

    // Validation logic
    if (!issueSubject) {
      error = true;
      validationState.subject = "has-danger"; // Mark subject as invalid
    }
    if (!issueBody) {
      error = true;
      validationState.body = "has-danger"; // Mark body as invalid
    }

    setValidation(validationState);

    if (!error) {
      let customerEmail = "admin@apsthreeai.ai";
      let emailFormData = new FormData();
      emailFormData.append("EmailToAddress", customerEmail);
      emailFormData.append("EmailSubject", `Issue Raised: ${issueSubject}`);
      emailFormData.append("EmailBody", `Description: ${issueBody}`);

      let res = await sendEmail(emailFormData);
      if (res) {
        // Assuming setAlerts is defined in your component
        await setAlerts({
          type: "success",
          message: "Your issue has been raised successfully!",
        });
        // Reset form or handle further as needed
      }

      setIssueSubject(""); // Clear the input
      setIssueBody(""); // Clear the input
      setValidation({ subject: "", body: "" }); // Reset validation state
    }
  };

  const handleInputChange = (setter, field) => (e) => {
    setter(e.target.value);
    if (validation[field]) {
      setValidation((prev) => ({ ...prev, [field]: "" })); // Clear validation when typing
    }
  };

  return (
    <>
      <div className="content-dashboard-inventory">
        <div className="title-route-container menu-title-div">
          {" "}
          <h4 className="menu-title-h"> Help & Activity / Raise Issue</h4>
        </div>
        <Card className="payment-data-card">
          <CardBody>
            <form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="issueSubject">Subject</Label>
                <Input
                  placeholder="Subject"
                  maxLength={100}
                  type="text"
                  id="issueSubject"
                  value={issueSubject}
                  onChange={handleInputChange(setIssueSubject, "subject")}
                  className={validation.subject} // Add validation class
                />
                {validation.subject && (
                  <small style={{ color: "red" }}>Subject is required.</small>
                )}
              </FormGroup>
              <FormGroup>
                <Label for="issueBody">Description</Label>
                <Input
                  maxLength={250}
                  placeholder="Description"
                  type="textarea"
                  id="issueBody"
                  value={issueBody}
                  onChange={handleInputChange(setIssueBody, "body")}
                  className={validation.body} // Add validation class
                />
                {validation.body && (
                  <small style={{ color: "red" }}>
                    Description is required.
                  </small>
                )}
              </FormGroup>
              <div
                className="text-right"
                style={{ display: "flex", justifyContent: "right" }}
              >
                <CustomButton
                  label="Submit Issue"
                  type="submit"
                  color="primary"
                />
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default RaiseIssue;
