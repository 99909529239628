import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useFeedbackStore } from "hooks/Feedback";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// reactstrap components
import {
  Label,
  Button,
  Card,
  Form,
  Input,
  Col,
  Row,
  FormGroup,
  Spinner,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ADMIN_URL_DASHBOARD } from "helpers/Paths";
import ChildLoader from "components/Loader/ChildLoader";

const Feedback = () => {
  const ref = useRef();
  const [overAllExperience, setOverAllExperience] = useState("Average");
  const [uiExperience, setUIExperience] = useState("Average");
  const [featureExperience, setFeatureExperience] = useState("Average");
  const [userComments, setUserComments] = useState("");
  const [loader, setLoader] = useState(false);
  const [gridLoader, setGridLoader] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);
  const navigate = useNavigate();
  const [{}, { getFeedbackList }, { submitFeedback }] = useFeedbackStore();
  const [{}, {}, { setAlerts }] = useLoginStore();
  const dt = useRef(null);

  useEffect(() => {
    setGridLoader(true);
    getUserFeedbackList();
  }, []);

  const getUserFeedbackList = async () => {
    setGridLoader(true);
    try {
      let feedbackData = await getFeedbackList({
        params: {
          userID: null,
          from_date: null,
          to_date: null,
        },
      });
      setFeedbackList(feedbackData);
      setGridLoader(false);
    } catch (error) {
      console.log("feedback list", error);
    }
    setGridLoader(false);
  };

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);

    let param = {
      OverAllExperience: overAllExperience,
      UIExperience: uiExperience,
      FeatureExperience: featureExperience,
      UserComments: userComments,
    };
    let res = await submitFeedback(param);
    if (res) {
      await setAlerts({
        type: "success",
        message: notificationMessages.submitFeedbackSuccess,
      });
    }
    reset(e);
    getUserFeedbackList();
    setLoader(false);
  };

  const reset = (ev) => {
    ev.preventDefault();
    ref.current.reset();
    setOverAllExperience("Average");
    setFeatureExperience("Average");
    setUIExperience("Average");
    setUserComments("");
  };

  return (
    <div className="content-dashboard-inventory">
      <div className="title-route-container menu-title-div">
        {" "}
        <h4 className="menu-title-h"> Help & Activity / Feedback</h4>
      </div>
      <Card
        body
        color="secondary"
        outline
        style={{
          width: "100%",
        }}
      >
        <Form className="form" onSubmit={handleFeedbackSubmit} innerRef={ref}>
          <Row xs="2" className="flex-container">
            <Col xs="12" md="12" lg="12">
              <ListGroup flush>
                <ListGroupItem>
                  <Row>
                    <span className="hospital-payment-row width-300">
                      Overall ERP Application experience:
                    </span>

                    <span className="">
                      <FormGroup>
                        <div className="d-flex justify-content-around">
                          <Label className="poor-txt mr-5" check>
                            <Input
                              type="radio"
                              name="OverAllExperience"
                              defaultValue="Poor"
                              onChange={(e) =>
                                setOverAllExperience(e.target.value)
                              }
                            />
                            Poor
                          </Label>
                          <Label className="avrg-txt mr-5" check>
                            <Input
                              name="OverAllExperience"
                              defaultValue="Average"
                              onChange={(e) =>
                                setOverAllExperience(e.target.value)
                              }
                              type="radio"
                              defaultChecked
                            />
                            Average
                          </Label>
                          <Label className="exclent-txt mr-5" check>
                            <Input
                              name="OverAllExperience"
                              defaultValue="Excellent"
                              onChange={(e) =>
                                setOverAllExperience(e.target.value)
                              }
                              type="radio"
                            />
                            Excellent
                          </Label>
                        </div>
                      </FormGroup>
                    </span>
                  </Row>
                  <Row>
                    <span className="hospital-payment-row width-300">
                      UIUX Experience:
                    </span>

                    <span className="">
                      <FormGroup>
                        <div className="d-flex justify-content-around">
                          <Label className="poor-txt mr-5" check>
                            <Input
                              name="UIExperience"
                              onChange={(e) => setUIExperience(e.target.value)}
                              type="radio"
                              defaultValue="Poor"
                            />
                            Poor
                          </Label>
                          <Label className="avrg-txt mr-5" check>
                            <Input
                              name="UIExperience"
                              onChange={(e) => setUIExperience(e.target.value)}
                              type="radio"
                              defaultChecked
                              defaultValue="Average"
                            />
                            Average
                          </Label>
                          <Label className="exclent-txt mr-5" check>
                            <Input
                              name="UIExperience"
                              onChange={(e) => setUIExperience(e.target.value)}
                              type="radio"
                              defaultValue="Excellent"
                            />
                            Excellent
                          </Label>
                        </div>
                      </FormGroup>
                    </span>
                  </Row>
                  <Row>
                    <span className="hospital-payment-row width-300">
                      ERP Features/Functionality Rating:
                    </span>

                    <span className="">
                      <FormGroup>
                        <div className="d-flex justify-content-around">
                          <Label className="poor-txt mr-5" check>
                            <Input
                              name="FeatureExperience"
                              onChange={(e) =>
                                setFeatureExperience(e.target.value)
                              }
                              type="radio"
                              defaultValue="Poor"
                            />
                            Poor
                          </Label>
                          <Label className="avrg-txt mr-5" check>
                            <Input
                              name="FeatureExperience"
                              onChange={(e) =>
                                setFeatureExperience(e.target.value)
                              }
                              type="radio"
                              defaultChecked
                              defaultValue="Average"
                            />
                            Average
                          </Label>
                          <Label className="exclent-txt mr-5" check>
                            <Input
                              name="FeatureExperience"
                              onChange={(e) =>
                                setFeatureExperience(e.target.value)
                              }
                              type="radio"
                              defaultValue="Excellent"
                            />
                            Excellent
                          </Label>
                        </div>
                      </FormGroup>
                    </span>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="description">Comment :</Label>
                <Input
                  // value={userComments}
                  defaultValue=""
                  maxLength={500}
                  type="textarea"
                  placeholder="Enter Comment"
                  onChange={(e) => setUserComments(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button color="success" className="mr-3" type="submit">
                Submit
                {loader ? (
                  <Spinner
                    size="sm"
                    color="secondary"
                    style={{ marginLeft: "15px" }}
                  />
                ) : (
                  ""
                )}
              </Button>
              <Button
                onClick={() => {
                  navigate(ADMIN_URL_DASHBOARD);
                }}
                color="danger"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card
        body
        color="secondary"
        outline
        style={{
          width: "100%",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "20px",
              display: "flex",
              justifyContent: "center",
            }}
            className="title"
          >
            User Feedbacks
          </span>
        </div>
        {gridLoader ? (
          <ChildLoader />
        ) : (
          <>
            <DataTable
              ref={dt}
              value={feedbackList ?? []}
              dataKey="feedbackID"
              tableStyle={{ minWidth: "25rem" }}
              pt={{
                root: { className: "table" },
              }}
              stripedRows
              resizableColumns
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 15]}
            >
              <Column
                field="overAllExperience"
                header="Overall Experience"
              ></Column>
              <Column field="uiExperience" header="UI Experience"></Column>
              <Column
                field="featureExperience"
                header="Feature Experience"
              ></Column>
              <Column field="userComments" header="Comments"></Column>
              <Column field="companyName" header="Company Name"></Column>
              <Column
                field="createdDate"
                header="Created On"
                dataType="date"
                body={(rowData) => {
                  try {
                    return Common.getFormatedDate(
                      rowData.createdDate,
                      "DD/MM/YYYY hh:mm:ss A",
                      true
                    );
                  } catch (error) {
                    console.error(error.message);
                    return "Invalid Date"; // or any fallback display text
                  }
                }}
              ></Column>
            </DataTable>
          </>
        )}
      </Card>
    </div>
  );
};

export default Feedback;
