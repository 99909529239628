import ProductQytRepository from "repository/ProductQytRepository"; 

export function useProductQytStore() {
    /**
     * Here is list of use selectors which are relates to report
     * Author: Riddhi
     */

     
    let fetch = {
        /**
         * Here is list of fetch methods which are used for get data from API or Repositories related to report
         * Author: Riddhi
         * dispatch is used for reducer or thunk method
         * if redux not needed use repository method
         */
        getProductQytData: async (params) => {
            return await new ProductQytRepository().getProductQytData(params);
        },
    };
    
    return [fetch]
}