const notificationMessages = {
  /**
   * constant messages for success and error
   * Author: Devanshi Patel
   */
  selectProductError:
    "Please select any product and unit properly to save bill.",
  saveBillProductError: "Please add any product to save bill.",
  saveBillSuccess: "Bill saved successfully.",
  addInventorySuccess: "Inventory added successfully.",
  updateInventorySuccess: "Inventory updated successfully.",
  updateLocationSuccess: "Location details updated successfully.",
  updateLegalInfoSuccess: "Legal Information updated successfully.",
  updateTaxDetailSuccess: "Tax Details updated successfully.",
  updateBranchSuccess: "Branch Detail updated successfully.",
  sendSMSSuccess: "SMS sent successfully.",
  sendEMAILSuccess: "Email sent successfully",
  submitFeedbackSuccess: "Feedback submitted successfully",
  notificationSCheduleSuccess: "Notification scheduled successfully",
  assignInventorySuccess: "Inventory assigned successfully.",
  paymentSuccess: "Payment done successfully.",
  customerProfileSettings: "Customer settings updated successfully.",
  genricSettingsSuccess: "Genric setings updated successfully",
  notificationSettings: "Notification updated successfully",
  invoiceheaderFooter: "header-footer data saved successfully",
  addInventoryDiscountSuccess: "Discount updated successfully.",
  inventorySettingsSuccess: "Inventory settings updated successfully.",
  invoiceFormat: "Invocie format settings updated successfully.",
  gstnDetails: "GSTN & Tax Details updated successfully.",
};

const roleType = {
  /**
   * constant for role
   * Author: Devanshi Patel
   */
  Admin: 1,
  Doctor: 2,
  Patient: 3,
};

export const FreeBenefits = [
  "Inventory Management",
  "Customizable templates",
  "Historical analytics",
  "Bill Template and Recent Product list",
  "Multi-location access (Cloud)",
  "Cloud web and Mobile App (Android and Ios)",
  "Advanced permissions",
  "Payment reports and payment status",
  "Limited storage for Product Images",
  "Re-Order and demand prediction",
  "99.9% uptime SLA",
  "N/A",
  "N/A",
  "N/A",
];

export const SmallBusinessBenifits = [
  "Inventory Management",
  "Customizable templates",
  "Historical analytics",
  "Bill Template and Recent Product list",
  "Multi-location access (Cloud)",
  "Cloud web and Mobile App (Android and Ios)",
  "Advanced permissions",
  "Payment reports and payment status",
  "Analytics",
  "Unlimited storage for Product Images",
  "Re-Order and demand prediction",
  "99.9% uptime SLA",
  "N/A",
  "N/A",
  "N/A",
];

export const EnterpriseBenifits = [
  "Inventory Management",
  "Customizable templates",
  "Historical analytics",
  "Bill Template and Recent Product list",
  "Multi-location access (Cloud)",
  "Cloud web and Mobile App (Android and Ios)",
  "Advanced permissions",
  "Payment reports and payment status",
  "Analytics",
  "Unlimited storage for Product Images",
  "Re-Order and demand prediction",
  "99.9% uptime SLA",
  "Premium support (Standard hours)",
  "Core EMR/EHR functionality",
  "Patient scheduling & billing",
];

export { notificationMessages, roleType };
