import React, { useState } from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator({ upiId }) {
  const [error, setError] = useState(null); // State to store error message

  // Example UPI payment data using only UPI ID (pa)
  const upiPaymentData = {
    pa: upiId, // UPI ID of the recipient
    // optional fields like 'am' (amount) are removed
  };

  // Convert UPI payment data to a string
  const upiPaymentString = Object.entries(upiPaymentData)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  // Function to handle errors
  const handleError = (error) => {
    setError(error);
    // Optionally, you can log the error or perform other actions
    console.error('UPI payment error:', error);
  };

  return (
    <div style={{ marginRight: '2%' }}>
      {/* Render QR code */}
      {error ? (
        <div>Error: {error}</div>
      ) : (
        <QRCode
          value={`upi://pay?${upiPaymentString}`}
          onError={handleError}
          size={130} // Adjusted the size for a larger QR code
        />
      )}
    </div>
  );
}

export default QRCodeGenerator;
