import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import {
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Sector,
  Label,
} from "recharts";
import { useDashboardV2DataStore } from "hooks/DashboardV2";
import ChildLoader from "components/Loader/ChildLoader";

const COLORS = ["#7DB3FF", "#494ca2", "#FF7F50", "#FFB6B9", "#FFA07A"];

// const renderActiveShape = (props) => {
//   const RADIAN = Math.PI / 180;
//   const {
//     cx,
//     cy,
//     midAngle,
//     innerRadius,
//     outerRadius,
//     startAngle,
//     endAngle,
//     fill,
//     payload,
//     percent,
//     value,
//   } = props;
//   const sin = Math.sin(-RADIAN * midAngle);
//   const cos = Math.cos(-RADIAN * midAngle);
//   const sx = cx + (outerRadius + 10) * cos;
//   const sy = cy + (outerRadius + 10) * sin;
//   const mx = cx + (outerRadius + 30) * cos;
//   const my = cy + (outerRadius + 30) * sin;
//   const ex = mx + (cos >= 0 ? 1 : -1) * 22;
//   const ey = my;
//   const textAnchor = cos >= 0 ? "start" : "end";

//   return (
//     <g>
//       <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
//         {payload?.name?.substring(0, 10) + "..."}
//       </text>
//       <Sector
//         cx={cx}
//         cy={cy}
//         innerRadius={innerRadius}
//         outerRadius={outerRadius}
//         startAngle={startAngle}
//         endAngle={endAngle}
//         fill={fill}
//       />
//       <path
//         d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
//         stroke={fill}
//         fill="none"
//       />
//       {/* <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
//       <text
//         className="pie-chart-label"
//         x={ex + (cos >= 0 ? 1 : -1) * 12}
//         y={ey}
//         textAnchor={textAnchor}
//         fill="#333"
//       >{`${value} (${(percent * 100).toFixed(2)}%)`}</text>
//     </g>
//   );
// };

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    cornerRadius
  } = props;

  const sin = Math.sin(-RADIAN * startAngle);
  const cos = Math.cos(-RADIAN * startAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload?.name?.substring(0, 10) + "..."}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
        cornerRadius={cornerRadius}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={-18} textAnchor={textAnchor} fill="#333">{`Value: ${props.value}`}</text> */}
    </g>
  );
};

const TopSellingMedicines = (props) => {
  const [{ }, { getTopSellingMedicines }, { }] = useDashboardV2DataStore();
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };
  let fromDateParam = props.fromDate;
  let toDateParam = props.toDate;
  const [loader, setLoader] = useState(false);
  const [topSellingMedicineData, setTopSellingMedicineData] = useState([]);

  let labelArray = [];
  let dataArray = [];
  topSellingMedicineData.map((item, key) => {
    labelArray.push(item.productName);
    dataArray.push(item.percentate);
  });

  useEffect(() => {
    gettopSellingMedicineData();
  }, []);

  useEffect(() => {
    gettopSellingMedicineData();
  }, [fromDateParam]);

  function formatDate(date) {
    let inputDate = new Date(date);
    let year = inputDate.getFullYear();
    let month = String(inputDate.getMonth() + 1).padStart(2, "0");
    let day = String(inputDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  function getTodayDate() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }

  const gettopSellingMedicineData = async () => {
    try {
      setLoader(true);
      let TopSellingMedsData = await getTopSellingMedicines({
        params: {
          From_date: fromDateParam,
          todate: toDateParam || formatDate(getTodayDate()),
        },
      });
      setTopSellingMedicineData(TopSellingMedsData);
      setLoader(false);
    } catch (error) {
      // for error handling
      // alert(error);
    }
  };

  const piedata = topSellingMedicineData.map((obj) => ({
    name: obj.productName,
    value: obj.percentate,
  }));

  // const gradientColors = [
  //   { start: "#7DB3FF", end: "#494ca2" }, // Gradient 1
  //   { start: "#FF7F50", end: "#FFB6B9" }, // Gradient 2
  //   { start: "#FFA07A", end: "#7DB3FF" }, // Gradient 3
  //   { start: "#84fab0", end: "#8fd3f4" }, // Gradient 4
  //   { start: "#a1c4fd", end: "#c2e9fb" }, // Gradient 5
  // ];
  // const gradientColors = [
  //   { start: "#1E90FF", end: "#20B2AA" }, // Blue to Teal Gradient
  //   { start: "#3CB371", end: "#98FB98" }, // Green to Light Green Gradient
  //   { start: "#8A2BE2", end: "#DDA0DD" }, // Violet to Lavender Gradient
  //   { start: "#FF8C00", end: "#FFD700" }, // Orange to Light Orange Gradient
  //   { start: "#B22222", end: "#FF69B4" }, // Deep Red to Pink Gradient
  // ];

  const gradientColors = [
    { start: "#434CE6", end: "#434CE6" }, // Cyan to Azure Gradient
    { start: "#7078FF", end: "#7078FF" }, // Emerald Green to Sea Green Gradient
    { start: "#9A9FFF", end: "#9A9FFF" }, // Magenta to Pink Gradient
    { start: "#B6BAFF", end: "#B6BAFF" }, // Sunset Orange to Deep Orange Gradient
    // { start: "#6A3093", end: "#A044FF" }, // Purple to Soft Pink Gradient
  ];

  return (
    <>
      <Col lg={4} md={6} xs={12}>
        <Card className="pb-1">
          <CardBody className="" style={{ height: "380px" }}>
            <div className="d-flex  align-items-center justify-content-between">
              <h6 className="title poppins-title top-selling-medicine-title">Top Selling Medicine</h6>
            </div>
            <div>
              {loader ? (
                <ChildLoader />
              ) : (
                <ResponsiveContainer className="h-300 mt-3" width="100%">
                  <PieChart>
                    <defs>
                      {gradientColors.map((color, index) => (
                        <linearGradient
                          key={index}
                          id={`colorGradient${index}`}
                        >
                          <stop offset="0%" stopColor={color.start} />
                          <stop offset="100%" stopColor={color.end} />
                        </linearGradient>
                      ))}
                    </defs>

                    <circle cx="50%" cy="50%" r="60" fill="#434ce61a" />

                    <Pie
                      activeIndex={activeIndex}
                      activeShape={renderActiveShape}
                      data={piedata}
                      cx="50%"
                      cy="50%"
                      innerRadius={90}
                      outerRadius={140}
                      dataKey="value"
                      paddingAngle={5}
                      cornerRadius={5}
                    // onMouseEnter={onPieEnter}
                    >
                      {piedata.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={`url(#colorGradient${index % gradientColors.length
                            })`}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    {/* <Legend /> */}
                  </PieChart>
                </ResponsiveContainer>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default TopSellingMedicines;
