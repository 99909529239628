import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";
import Widgets from "./Widgets";
import FinanceAnalysis from "./FinanceAnalysis";
import TopSellingMedicines from "./TopSellingMedicine";
import RecentInventory from "./RecentInventory";
import Bills from "./Bills";
import "index.css";
import FilterButton from "views/Report/FilterButton";
import moment from "moment";
import Common from "helpers/Common";

const Dashboard = () => {
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    // Google Ads conversion tracking code
    window.gtag('event', 'conversion', {
      send_to: 'AW-16685553251',
    });
  }, []);

  async function getReport(Date = "", endDate = "", page = 1) {
    setSelectedDate({ fromDate: Date, toDate: endDate })
  }

  return (
    <>
      <div className="content-dashboard">
        <div className="dashboard-title-row pt-1 d-flex align-items-center justify-content-end dashboard-container">
          {/* <div className="dashboard-title poppins-title">Dashboard</div> */}
          <div className="filter-wrapper">
            <FilterButton
              getReport={getReport}
              setLoadingFilter={setLoadingFilter}
            />
          </div>
        </div>

        {
          selectedDate &&
          <>
            <Row>
              <Widgets
                fromDate={selectedDate.fromDate}
                toDate={selectedDate.toDate}
              ></Widgets>
            </Row>
            <Row className="mt-2">
              <FinanceAnalysis
                fromDate={selectedDate.fromDate}
                toDate={selectedDate.toDate}
              ></FinanceAnalysis>
              <TopSellingMedicines
                fromDate={selectedDate.fromDate}
                toDate={selectedDate.toDate}
              ></TopSellingMedicines>
            </Row>
            <Row className="mt-2">
              <RecentInventory
                fromDate={selectedDate.fromDate}
                toDate={selectedDate.toDate}
              ></RecentInventory>
            </Row>
            <Row className="mt-2">
              <Bills
                fromDate={selectedDate.fromDate}
                toDate={selectedDate.toDate}
              ></Bills>
            </Row>
          </>
        }
      </div>
    </>
  );
};

export default Dashboard;
