import { useEffect, useState } from "react";
// reactstrap components
import ChildLoader from "components/Loader/ChildLoader";
import { useLoginStore } from "hooks/Login";
import { useSubscriptionStore } from "hooks/Subscription";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { isEmpty } from "underscore";
import "../../assets/styles/subscriptions/billingUsage.css";

const PlanDetails = () => {
    const [{}, { getCompanySubscriptionDetails }, {}] = useSubscriptionStore();
    const [{}, {}, { setAlerts }] = useLoginStore();
    const [subData, setsubData] = useState(null);
    const [loadingFilter, setLoadingFilter] = useState(false);

    useEffect(() => {
        setLoadingFilter(true);
        const getCurrentSubscriptionDetailsData = async () => {
            let res = await getCompanySubscriptionDetails();
            if (res && !isEmpty(res)) {
                setsubData(res);
            }
            setLoadingFilter(false);
        };
        getCurrentSubscriptionDetailsData();
    }, []);

    return (
        <>
            <div className="content-dashboard-inventory">
                <div className="title-route-container menu-title-div">
                    <h4 className="menu-title-h">
                        Subscription / Billing & Usage
                    </h4>
                </div>

                {loadingFilter ? (
                    <ChildLoader />
                ) : (
                    subData && (
                        <div className="billing-usage-data-container">
                            {/* Company Details Card */}
                            <Card className="inventory-product-card company-details-card">
                                <CardBody>
                                    <h5 className="usage-card-title">
                                        Company Details
                                    </h5>
                                    <Row>
                                        <Col xs="6" md="3">
                                            <p>Company ID:</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">{subData.companyId}</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p>Company Name:</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">{subData.companyName}</p>
                                        </Col>

                                        <Col xs="6" md="3">
                                            <p>Subscription ID:</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">{subData.subscriptionId}</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p>Subscription Name:</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">{subData.subscriptionName}</p>
                                        </Col>

                                        <Col xs="6" md="3">
                                            <p>Start Date:</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {`${new Date(subData.startDate)
                                                    .getDate()
                                                    .toString()
                                                    .padStart(2, "0")}/${(
                                                    new Date(
                                                        subData.startDate
                                                    ).getMonth() + 1
                                                )
                                                    .toString()
                                                    .padStart(
                                                        2,
                                                        "0"
                                                    )}/${new Date(
                                                    subData.startDate
                                                ).getFullYear()}`}
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p>End Date:</p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">{`${new Date(subData.endDate)
                                                .getDate()
                                                .toString()
                                                .padStart(2, "0")}/${(
                                                new Date(
                                                    subData.endDate
                                                ).getMonth() + 1
                                            )
                                                .toString()
                                                .padStart(2, "0")}/${new Date(
                                                subData.endDate
                                            ).getFullYear()}`}</p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Invoice Usage Card */}
                            <Card className="inventory-product-card invoice-usage-card">
                                <CardBody>
                                    <h5 className="usage-card-title">
                                        Invoice Usage
                                    </h5>
                                    <Row>
                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                Invoice Usage:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.invoiceUsage}
                                            </p>
                                        </Col>

                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                Bill Creation Limit:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.billCreationLimit}
                                            </p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Inventory Usage Card */}
                            <Card className="inventory-product-card inventory-usage-card">
                                <CardBody>
                                    <h5 className="usage-card-title">
                                        Inventory Usage
                                    </h5>
                                    <Row>
                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                Inventory Usage:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.inventoryUsage}
                                            </p>
                                        </Col>

                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                Inventory Creation Limit:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.inventoryCreationLimit}
                                            </p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {/* Notification Usage Card */}
                            <Card className="inventory-product-card notification-usage-card">
                                <CardBody>
                                    <h5 className="usage-card-title">
                                        Notification Usage
                                    </h5>
                                    <Row>
                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                SMS Usage:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.smsUsage}
                                            </p>
                                        </Col>

                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                SMS Limit:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.smsLimit}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                Email Usage:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.emailUsage}
                                            </p>
                                        </Col>

                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                Email Limit:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.emailLimit}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                WhatsApp Usage:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.whatsUpUsage}
                                            </p>
                                        </Col>

                                        <Col xs="6" md="3">
                                            <p className="usage-label">
                                                WhatsApp Limit:
                                            </p>
                                        </Col>
                                        <Col xs="6" md="3">
                                            <p className="usage-value">
                                                {subData.whatsUpLimit}
                                            </p>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>
                    )
                )}
            </div>
        </>
    );
};

export default PlanDetails;
