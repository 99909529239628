import { Card, Container, Row, Col } from "reactstrap";
import React, { useEffect, useState } from "react";
import "assets/styles/CreateBill.css";
import PatientBillForm from "./PatientBillForm";
import BillSummary from "./BillSummary";
import BillFooter from "./BillFooter";
import { useCreateBillStore } from "hooks/CreateBill";
import PageLoader from "components/Loader/PageLoader";
import "index.css";
import { useLoginStore } from "hooks/Login";
import DraftButtonWithPopover from "./DraftButton";
import CustomerDetails from "./components/CustomerDetails";
import { RiAddFill, RiArrowRightLine } from "react-icons/ri";
import { LuRefreshCw } from "react-icons/lu";
import RefreshButton from "components/Button/RefreshButton";

const NewCreateBill = () => {
  const [
    {},
    {
      getBranchDetail,
      GetTemplateDetails_By_ID,
      getSuggestedProduct,
      getProductSearchList,
    },
    {},
  ] = useCreateBillStore();
  const [{}, {}, { setAlerts }] = useLoginStore();

  const [patientData, setPatientData] = useState({
    patientName: "",
    address: "",
    mobileNumber: "",
  });
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [branchDetail, setBranchDetail] = useState({});
  const [customerData, setCustomerData] = useState({
    customerName: "",
    address1: "",
    address2: "",
    address: "",
    phoneNumber: "",
    emailId: "",
  });
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedCustomerID, setSelectedCustomerID] = useState(null);
  const [rows, setRows] = useState([]);
  const [manualDiscount, setManualDiscount] = useState(0);
  const [igstPercentage, setIgstPercentage] = useState(18);
  const [showInRupees, setShowInRupees] = useState(true);
  const [loading, setLoading] = useState(false);
  const [suggestedProductList, setSuggestedProductList] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("Cash");
  const [paymentDone, setPaymentDone] = useState(true);
  const [billId, setBillId] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [totalBeforeTax, setTotalBeforeTax] = useState(0);
  const [isDraftCreated, setIsDraftCreated] = useState(false);
  const [recentBillId, setRecentBillId] = useState(0);
  const [selectedRecentProduct, setSelectedRecentProduct] = useState();

  useEffect(() => {
    if (getSuggestedProduct() !== null) {
      setSuggestedProductList(getSuggestedProduct());
    }
  }, [JSON.stringify(getSuggestedProduct())]);

  const toggleCollapseBtn = () => {
    const parentElement = document.getElementsByClassName("navbar-minimize")[0];

    if (parentElement) {
      const btn = parentElement.querySelector("#minimizeSidebar");

      if (btn) {
        const icon = parentElement.querySelector(".nc-minimal-right");

        if (icon) {
          btn.click();
        }
      }
    }
  };

  function generateRandomDraftName() {
    const prefix = "Draft";
    const randomString = Math.random().toString(36).substring(2, 8); // Generate a random string
    return `${prefix}-${randomString}`;
  }

  function getCurrentDateTime() {
    return new Date().toISOString();
  }

  function storeDraft(params) {
    const draftName = generateRandomDraftName();
    const currentTime = getCurrentDateTime();
    const draftData = { ...params, draftName, savedAt: currentTime };

    let drafts = localStorage.getItem("drafts");
    drafts = drafts ? JSON.parse(drafts) : [];

    drafts.push(draftData);

    localStorage.setItem("drafts", JSON.stringify(drafts));
  }

  const createDraft = async () => {
    try {
      setLoading(true);
      if (rows && rows.length > 0) {
        const productInfoData = rows.map((item, index) => {
          return {
            medicinetime: item.medicinetime,
            productId: item.productId,
            productName: item.productName,
            qty: item.qty,
            rate: item.rate,
            cgst: item.cgst,
            cgstType: "PERCENTAGE",
            sgst: item.sgst,
            sgstType: "PERCENTAGE",
            discountAmount: item.discountAmount,
            productDiscountType: "AMOUNT",
            amount: item.amount,
            productCost: item.productCost,
            netAmount: parseFloat(item.netAmount),
            expiryDate: item.expiryDate,
          };
        });

        const params = {
          billId: 0,
          selectedCustomer: selectedCustomer,
          selectedCustomerID: selectedCustomerID,
          customerId: selectedCustomer?.customerID,
          customerName: customerData?.customerName,
          customerAddress: customerData?.address,
          customerContact: customerData?.phoneNumber,
          customerEmail: selectedCustomer?.emailId,
          productInfos: productInfoData,
          subTotal: parseFloat(subTotal),
          manualDiscount: manualDiscount ? manualDiscount : 0,
          manualDiscountType: showInRupees ? "RUPEES" : "PERCENTAGE",
          totalBeforeTax: parseFloat(totalBeforeTax),
          gst: igstPercentage,
          gstType: "PERCENTAGE",
          paidStatus: paymentDone,
          paymentMode: paymentMethod,
          grandTotal: parseFloat(Math.round(totalAmount).toFixed(2)),
        };

        storeDraft(params);

        setSelectedCustomer(null);
        setSelectedCustomerID(null);
        setManualDiscount(null);
        setIgstPercentage(18);
        setRows([]);
        setPaymentMethod("Cash");
        setPaymentDone(true);
        setBillId(0);
        setTotalAmount(0);
        setSubTotal(0);
        setTotalBeforeTax(0);

        setAlerts({
          type: "success",
          message: "Draft Saved Successfully.",
        });
        setLoading(false);
        setIsDraftCreated((prev) => !prev);
      } else {
        setAlerts({
          type: "danger",
          message: "Please Select Any Product.",
        });
        setLoading(false);
      }
    } catch (err) {
      setAlerts({
        type: "danger",
        message: "Error Creating Draft.",
      });
      setLoading(false);
    }
  };

  const retrieveBranchDetail = async () => {
    let branchDetail = localStorage.getItem("branchDetail");
    if (!branchDetail) {
      branchDetail = await getBranchDetail();
    } else {
      const parsedItem = JSON.parse(branchDetail);
      const now = new Date().getTime();
      if (now > parsedItem.expiry) {
        localStorage.removeItem("branchDetail"); // Remove from localStorage if expired
      }
    }

    setBranchDetail(branchDetail);
  };

  useEffect(() => {
    toggleCollapseBtn();
    retrieveBranchDetail();
  }, []);

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const handleTemplateSelect = (templateNumber) => {
    setSelectedTemplate(templateNumber);
  };

  const handleSuggestedProductSelect = async (productId) => {
    let productList = await getProductSearchList(productId);
    let selectedProduct = productList.filter((el) => {
      return el.productId === productId;
    });

    let data = selectedProduct[0];

    const isProductAlreadyAdded = rows.some(
      (row) => row.productId === data.productId
    );

    if (isProductAlreadyAdded) {
      setAlerts({
        type: "warning",
        message: "Product is already added.",
      });
      return;
    }

    const productCostBeforeTaxCal =
      data?.unitPrice - (parseFloat(data?.product_discount) || 0);

    const SGSTCost = (productCostBeforeTaxCal * data?.tax2) / 100;
    const CGSTCost = (productCostBeforeTaxCal * data?.tax1) / 100;

    selectedProduct = {
      productId: data?.productId,
      expiryDate: data?.expiryDate,
      productDescription: data?.productDescription,
      productName: data?.productName,
      categoryId: data?.categoryId,
      qty: 1,
      availableQty: data?.available_qty,
      rate: data?.unitPrice,
      amount: data?.unitPrice,
      discountAmount: parseFloat(data?.product_discount) || 0,
      productCost: productCostBeforeTaxCal,
      sgst: data?.tax2,
      cgst: data?.tax1,
      netAmount: productCostBeforeTaxCal + SGSTCost + CGSTCost,
    };
    setRows([...rows, selectedProduct]);
  };

  const fetchTemplateData = async (id) => {
    setLoading(true);
    const data = await GetTemplateDetails_By_ID(id);
    setRows(data.templateDetails.productInfos);
    setManualDiscount(data.templateDetails.manualDiscount);

    if (data.templateDetails.manualDiscountType !== "percentage") {
      setShowInRupees(true);
    }

    setIgstPercentage(data.templateDetails.gst);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedTemplate) {
      fetchTemplateData(selectedTemplate);
    }
  }, [selectedTemplate]);

  return (
    <>
      {loading && (
        <>
          <PageLoader />
        </>
      )}
      <div className="create-bill-container">
        <div className="d-flex align-items-center justify-content-between menu-title-div">
          <h4 className="menu-title-h m-0">Bills / Create Bill</h4>
          <RefreshButton />
        </div>
        {/* Create Bill Card */}
        <Card
          color="secondary"
          outline
          style={{
            width: "100%",
            padding: "15px 10px 0px 10px",
          }}
        >
          <CustomerDetails
            getSuggestedProduct={getSuggestedProduct}
            patientData={patientData}
            setPatientData={setPatientData}
            setSelectedPatient={setSelectedPatient}
            selectedPatient={selectedPatient}
            branchDetail={branchDetail}
            customerData={customerData}
            setCustomerData={setCustomerData}
            setSelectedCustomer={setSelectedCustomer}
            selectedCustomer={selectedCustomer}
            handleTemplateSelect={handleTemplateSelect}
            handleSuggestedProductSelect={handleSuggestedProductSelect}
            suggestedProductList={suggestedProductList}
            setSelectedCustomerID={setSelectedCustomerID}
            selectedCustomerID={selectedCustomerID}
            setRows={setRows}
            setManualDiscount={setManualDiscount}
            setShowInRupees={setShowInRupees}
            setIgstPercentage={setIgstPercentage}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            setPaymentDone={setPaymentDone}
            paymentDone={paymentDone}
            setBillId={setBillId}
            setRecentBillId={setRecentBillId}
            recentBillId={recentBillId}
            setLoading={setLoading}
            createDraft={createDraft}
            selectedRecentProduct={selectedRecentProduct}
            setSelectedRecentProduct={setSelectedRecentProduct}
          />

          <BillSummary
            selectedCustomerID={selectedCustomerID}
            patientData={customerData}
            selectedPatient={selectedCustomer}
            branchDetail={branchDetail}
            customerData={customerData}
            setCustomerData={setCustomerData}
            setSelectedCustomer={setSelectedCustomer}
            selectedCustomer={selectedCustomer}
            setRows={setRows}
            rows={rows}
            manualDiscount={manualDiscount}
            setManualDiscount={setManualDiscount}
            showInRupees={showInRupees}
            setShowInRupees={setShowInRupees}
            setIgstPercentage={setIgstPercentage}
            igstPercentage={igstPercentage}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            setPaymentDone={setPaymentDone}
            paymentDone={paymentDone}
            billId={billId}
            setBillId={setBillId}
            subTotal={subTotal}
            setSubTotal={setSubTotal}
            totalAmount={totalAmount}
            setTotalAmount={setTotalAmount}
            totalBeforeTax={totalBeforeTax}
            setTotalBeforeTax={setTotalBeforeTax}
            setRecentBillId={setRecentBillId}
            recentBillId={recentBillId}
            createDraft={createDraft}
            selectedRecentProduct={selectedRecentProduct}
            setSelectedRecentProduct={setSelectedRecentProduct}
            isDraftCreated={isDraftCreated}
          />

          {/* <DraftButtonWithPopover
            isDraftCreated={isDraftCreated}
            setSelectedCustomer={setSelectedCustomer}
            setRows={setRows}
            setManualDiscount={setManualDiscount}
            setShowInRupees={setShowInRupees}
            setIgstPercentage={setIgstPercentage}
            setPaymentMethod={setPaymentMethod}
            setPaymentDone={setPaymentDone}
            setBillId={setBillId}
            setSubTotal={setSubTotal}
            setTotalAmount={setTotalAmount}
            setTotalBeforeTax={setTotalBeforeTax}
          /> */}
        </Card>
      </div>
    </>
  );
};

export default NewCreateBill;
