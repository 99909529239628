import { useLoginStore } from "hooks/Login";
import React, { useRef, useState, useEffect } from "react";
import Select from "react-select";
import {
  Label,
  Button,
  Card,
  Input,
  Col,
  Row,
  FormGroup,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { AutoComplete } from "primereact/autocomplete";
import "react-datepicker/dist/react-datepicker.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { usePaymentStore } from "hooks/Payment";
import { isEmpty } from "underscore";
import { useCreateBillStore } from "hooks/CreateBill";
import CashPaymentModal from "./PaymentTypeModals/CashPaymentModal";
import BankTransferModal from "./PaymentTypeModals/BankTransferModal";
import CreditDebitCardModal from "./PaymentTypeModals/CreditDebitCardModal";
import MobileWalletModal from "./PaymentTypeModals/MobileWalletModal";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import "../../assets/styles/Payment.css";
import Common from "helpers/Common";
import CustomButton from "components/Button/CustomButton";

const Payment = () => {
  const dt = useRef(null);

  const [{}, {}, { setAlerts }] = useLoginStore();
  const [loader, setLoader] = useState(false);

  const [
    {},
    {
      getTransactionServices,
      getPaymentModes,
      getBillsDetailsByID,
      getAdvancePayDetailsByID,
      getAccountStatementDetails,
    },
    {},
  ] = usePaymentStore();
  const [{}, { getCustomerDetail }, {}] = useCreateBillStore();

  const [billDetails, setBillDetails] = useState([]);
  const [filteredBills, setFilteredBills] = useState([]);
  const [selectedBills, setSelectedBills] = useState("");
  const [transactionServices, setTransactionServices] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [transactionServiceSelect, setTransactionServiceSelect] = useState({
    value: 1,
    label: "Bill-Invoice",
    type: "Credit",
  });
  const [loadingMore, setLoadingMore] = useState(false);
  const [paymentModeSelect, setPaymentModeSelect] = useState(null);

  const [transactionType, settransactionType] = useState(null);
  const [amount, setAmount] = useState(0);
  const [refNumber, setRefNumber] = useState(null);

  const [selectedBillItems, setSelectedBillItems] = useState(null);

  const debounceTimer = useRef(null);
  const [cashModal, setCashModal] = useState(false);
  const [banktransferModal, setBanktransferModal] = useState(false);
  const [creditDebitCardModal, setCreditDebitCardModal] = useState(false);
  const [mobileWalletModal, setMobileWalletModal] = useState(false);

  const [payDetails, setPayDetails] = useState([]);
  const autoCompleteRef = useRef(null);
  const [allSelected, setallSelected] = useState(true);
  const [selection, setselection] = useState([]);
  const [PartialPay, setIsPartialPay] = useState(false);
  const [customerID, setCustomerID] = useState(null);
  const [paymentConfirmModal, setPaymentConfirmModal] = useState(false);

  const [advancePayDetails, setAdvancePayDetails] = useState([]);
  const [accountStatements, setAccountStatements] = useState([]);

  const pageSize = 10;
  const [pageNumber, setPageNumber] = useState(1);

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    fetchAccountStatementDetails(pageNumber + 1);
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formatNull = (value) => (value === null ? "N/A" : value);

  const fetchAccountStatementDetails = async (page = 1) => {
    if (page === 1) {
      setPageNumber(1);
    }

    const today = new Date();

    const params = {
      amount: null,
      from_date: "2024-03-10",
      to_date: formatDate(today),
      pagesize: pageSize,
      pagenumber: page,
    };

    try {
      const data = await getAccountStatementDetails(params);

      const transformedData = data.map((jsonData) => {
        return {
          ...jsonData,
          createdDate: Common.getFormatedDate(
            jsonData.createdDate,
            "DD/MM/YYYY hh:mm:ss A",
            true
          ),
          paymentStatus: jsonData.paymentStatus === "True" ? "Paid" : "Pending",
          transactionServiceType: formatNull(jsonData.transactionServiceType),
          vendorInformation: formatNull(jsonData.vendorInformation),
          invoiceNumber: formatNull(jsonData.invoiceNumber),
          productName: formatNull(jsonData.productName),
          quantity: jsonData.quantity > 0 ? jsonData.quantity : "0.00",
          totalTax:
            jsonData.totalTax > 0 ? `${jsonData.totalTax.toFixed(2)}` : "0.00",
          totalDiscount:
            jsonData.totalDiscount > 0
              ? `${jsonData.totalDiscount.toFixed(2)}`
              : "0.00",
          totalNetPrice:
            jsonData.totalNetPrice > 0
              ? `${jsonData.totalNetPrice.toFixed(2)}`
              : "0.00",
          totalNetProductCost:
            jsonData.totalNetProductCost > 0
              ? `${jsonData.totalNetProductCost.toFixed(2)}`
              : "0.00",
          amountDue:
            jsonData.amountDue > 0
              ? `${jsonData.amountDue.toFixed(2)}`
              : "0.00",
          transactionAmount: `${jsonData.transactionAmount.toFixed(2)}`,
          transactionTax: `${jsonData.transactionTax.toFixed(2)}`,
          transactionTotalAmount: `${jsonData.transactionTotalAmount.toFixed(
            2
          )}`,
        };
      });

      if (page > 1) {
        setAccountStatements([...accountStatements, ...transformedData]);
      } else {
        setAccountStatements(transformedData);
      }

      setLoadingMore(false);
    } catch (error) {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    gettransactionService();
    getpaymentMode();
    fetchAccountStatementDetails();
  }, []);

  const gettransactionService = async () => {
    let transactionServiceList = await getTransactionServices();
    let list =
      transactionServiceList &&
      !isEmpty(transactionServiceList).length > 0 &&
      transactionServiceList.map((prop, key) => {
        return {
          value: prop.transactionServiceID,
          label: prop.transactionServiceName,
          type: prop.type,
        };
      });

    setTransactionServices(list);
  };

  const getpaymentMode = async () => {
    let paymentList = await getPaymentModes();
    let list =
      paymentList &&
      !isEmpty(paymentList).length > 0 &&
      paymentList.map((prop, key) => {
        return {
          value: prop.paymentTypeId,
          label: prop.paymentName,
        };
      });
    setPaymentModes(list);
  };

  const filterBills = (event) => {
    const query = event.query;
    // Clear the existing timer if any
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      retrieveBills(query);
    }, 1000); // 1s delay
  };

  const retrieveBills = async (queryText) => {
    if (queryText.length >= 2) {
      // Check if at least 2 characters are entered
      let billList = await getCustomerDetail(queryText);
      if (billList && !isEmpty(billList)) {
        setFilteredBills(billList);
      } else {
        setFilteredBills([
          {
            productName: (
              <span style={{ cursor: "none", padding: "70px" }}>
                no result found
              </span>
            ),
          },
        ]);
      }
    } else {
      setTimeout(() => {
        setAlerts({
          type: "danger",
          message: "Minimum 2 characters are needed for product search",
        });
      }, 2000);
    }
  };

  const retrieveAdvancePayDetails = async () => {
    setLoader(true);
    let advancePayDetailsData = await getAdvancePayDetailsByID({
      params: {
        customerID: customerID,
      },
    });
    setAdvancePayDetails(advancePayDetailsData);
    setLoader(false);
  };

  function getBillDetails(bill) {
    reset();

    async function getBillDetailsFunction() {
      let billDetailsData = await getBillsDetailsByID({
        params: {
          customerID: bill?.customerID,
        },
      });
      setCustomerID(bill?.customerID);
      let resData = billDetailsData.map((data) => {
        if ("Pending".includes(data.billStatusName))
          return { ...data, isChecked: true };
        return { ...data, isChecked: false };
      });
      setBillDetails(resData);
      setSelectedBillItems(resData.filter((val) => val.isChecked));
      setselection(resData.filter((val) => val.isChecked));
      let amount = billDetailsData.filter(
        (x) => x.netPayment && x.paidStatus == "false"
      );
      let netAmountTotal = amount?.map((x) => x.netPayment);
      let totalPendingAmount = 0;
      netAmountTotal?.forEach((el) => (totalPendingAmount += el));
      totalPendingAmount = totalPendingAmount?.toFixed(2);
      setAmount(totalPendingAmount);
      setLoader(false);
    }
    getBillDetailsFunction();
  }

  const handleTransactionSelect = (val) => {
    settransactionType(val?.type);
    if (val?.value == 3) {
      retrieveAdvancePayDetails();
    }
  };

  const handlePayment = () => {
    setPayDetails([]);
    if (customerID != null) {
      if (
        amount > 0 &&
        paymentModeSelect != null &&
        transactionServiceSelect != null
      ) {
        if (paymentModeSelect.value == 1) {
          setCashModal(true);
          setPayDetails(selectedBillItems);
        }
        if (paymentModeSelect.value == 2) {
          setBanktransferModal(true);
          setPayDetails(selectedBillItems);
        }
        if (paymentModeSelect.value == 3 || paymentModeSelect.value == 4) {
          setCreditDebitCardModal(true);
          setPayDetails(selectedBillItems);
        }
        if (paymentModeSelect.value == 5) {
          setMobileWalletModal(true);
          setPayDetails(selectedBillItems);
        }
      } else {
        alert("Please enter Amount, Transaction Mode & Payment Mode.");
      }
    } else {
      alert("Please search & select Customer Name/Mobile Number.");
    }
  };

  const selectable = (data) => {
    return data.billStatusName == "Pending";
  };

  const allSelectableItems = () => {
    return billDetails.filter((x) => selectable(x));
  };

  const reset = () => {
    setPayDetails([]);
    setallSelected(true);
    setselection([]);
    setAmount(0);
    setIsPartialPay(false);
    setPaymentConfirmModal(false);
    setRefNumber(null);
    setAdvancePayDetails([]);
  };

  const toggle = () => setPaymentConfirmModal(!paymentConfirmModal);

  return (
    <div className="content-dashboard-inventory">
      {loader ? (
        <PageLoader></PageLoader>
      ) : (
        <>
          <div className="title-route-container menu-title-div">
            <h4 className="menu-title-h m-2">
              Payment / Create Transaction
            </h4>
          </div>

          <Card className="payment-data-card">
            <CardBody>
              {/* <div className="payment-card-title-container">
                <h5>Create Transaction</h5>
              </div> */}

              <Row xs="2">
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup className="customer-input-container">
                    <Label for="searchBills">
                      Search Customer{" "}
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <AutoComplete
                      className="form-control"
                      inputStyle={{ width: "100%" }}
                      value={selectedBills}
                      suggestions={filteredBills}
                      completeMethod={filterBills}
                      field="customerName"
                      placeholder="Customer Name/Mobile Number"
                      minLength={1}
                      style={{
                        border: "none",
                        width: "100%",
                        padding: "0px",
                      }}
                      onChange={(e) => {
                        setSelectedBills(e.value);
                      }}
                      onSelect={(e) => {
                        setSelectedBills(e.value);
                        getBillDetails(e.value);
                        setLoader(true);
                      }}
                      pt={{
                        panel: {
                          className: "autocomplete-Zindex",
                        },
                      }}
                      delay={300}
                      ref={autoCompleteRef}
                    />
                  </FormGroup>
                </Col>
                {/* <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label for="doctor">Patient Name</Label>
                    <Input
                      readOnly
                      id="name"
                      placeholder="Patient Name"
                      value={billDetails[0]?.customerName}
                      name="name"
                      type="text"
                    ></Input>
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label for="doctor">Patient Address</Label>
                    <Input
                      readOnly
                      id="address"
                      name="address"
                      value={billDetails[0]?.customerAddress}
                      type="textarea"
                      rows="3"
                      placeholder="Patient Address"
                    />
                  </FormGroup>
                </Col> */}
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup className="customer-input-container">
                    <Label for="doctor">
                      Transaction Service{" "}
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      name="transactionService"
                      placeholder="Select Transaction Service"
                      type="hidden"
                    />
                    <Select
                      isDisabled={customerID != null ? false : true}
                      className="profile-custom-select payment-mode-select"
                      // classNamePrefix="react-select"
                      placeholder="Select Transaction Service"
                      name="transactionService"
                      value={transactionServiceSelect}
                      options={transactionServices}
                      onChange={(e) => {
                        setTransactionServiceSelect(e);
                        handleTransactionSelect(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup className="customer-input-container">
                    <Label for="doctor">
                      Payment Mode <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      className="w-100"
                      name="paymentMode"
                      placeholder="Select Payment Mode"
                      type="hidden"
                    />
                    <Select
                      isDisabled={customerID != null ? false : true}
                      className="profile-custom-select payment-mode-select"
                      // classNamePrefix="react-select"
                      placeholder="Select Payment Mode"
                      name="paymentMode"
                      value={paymentModeSelect}
                      options={paymentModes}
                      onChange={(e) => {
                        setPaymentModeSelect(e);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup className="customer-input-container">
                    <Label for="doctor">
                      Transaction Type{" "}
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      readOnly
                      id="transactionType"
                      placeholder="Transaction Type"
                      value={transactionServiceSelect?.type}
                      name="transactionType"
                      type="text"
                    ></Input>
                  </FormGroup>
                </Col>
                {/* <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup>
                    <Label for="doctor">Reference Number</Label>
                    <Input
                      id="refNumber"
                      value={refNumber}
                      onChange={(e) => {
                        setRefNumber(e.target.value);
                      }}
                      placeholder="Reference Number"
                      name="refNumber"
                      type="text"
                    ></Input>
                  </FormGroup>
                </Col> */}
                <Col className="" xs={12} md={4} lg={4}>
                  <FormGroup className="customer-input-container">
                    <Label for="doctor">
                      Amount <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      // readOnly={
                      //   transactionServiceSelect.value == 1 ? true : false
                      // }
                      id="amount"
                      placeholder="Amount"
                      value={amount}
                      inputMode="numeric"
                      onChange={(e) => {
                        setAmount(e.target.value);
                        setIsPartialPay(true);
                      }}
                      name="amount"
                      type="text"
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs={12} md={4} lg={4}>
                  <CustomButton
                    customStyle="transparent-save-btn"
                    label="Save"
                    onClick={() => {
                      if (transactionServiceSelect.value == 2) {
                        setPaymentConfirmModal(true);
                      } else {
                        handlePayment();
                      }
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          {transactionServiceSelect?.value == 1 ? (
            <Card
              className="report-table-card mt-3"
              style={{ borderRadius: "10px" }}
            >
              <CardBody>
                <div className="report-table-title">
                  <h5>Transaction List</h5>
                </div>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <DataTable
                      // pt={{
                      //   root: { className: "table table-payment" },
                      // }}
                      // responsiveLayout="scroll"
                      ref={dt}
                      value={accountStatements ?? []}
                      dataKey="transactionPaymentID"
                      tableStyle={{ minWidth: "25rem" }}
                      className="text-center"
                    >
                      <Column
                        field="transactionPaymentID"
                        header="Payment ID"
                        align="center"
                      ></Column>
                      <Column
                        field="transactionServiceName"
                        header="Service Name"
                        align="center"
                      ></Column>
                      <Column
                        field="contactPerson"
                        header="Contact Person"
                        align="center"
                      ></Column>
                      <Column
                        field="mode"
                        header="Mode"
                        align="center"
                      ></Column>
                      <Column
                        field="transactionTax"
                        header="Tax"
                        align="center"
                      ></Column>
                      <Column
                        field="transactionTotalAmount"
                        header="Total Amount"
                        align="center"
                        bodyStyle={{ fontWeight: "bold" }}
                      ></Column>
                      <Column
                        field="createdDate"
                        header="Date Created"
                        align="center"
                      ></Column>

                      {/* <Column
                        field="paymentStatus"
                        header="Payment Status"
                      ></Column>
                      <Column field="transactionAmount" header="Amount"></Column>
                      <Column
                        field="transactionServiceType"
                        header="Service Type"
                      ></Column>
                      <Column
                        field="vendorInformation"
                        header="Vendor Information"
                      ></Column>
                      <Column
                        field="invoiceNumber"
                        header="Invoice Number"
                      ></Column>
                      <Column field="productName" header="Product Name"></Column>
                      <Column field="quantity" header="Quantity"></Column>
                      <Column field="totalTax" header="Total Tax"></Column>
                      <Column
                        field="totalDiscount"
                        header="Total Discount"
                      ></Column>
                      <Column field="totalNetPrice" header="Net Price"></Column>
                      <Column
                        field="totalNetProductCost"
                        header="Net Product Cost"
                      ></Column>
                      <Column field="amountDue" header="Amount Due"></Column> */}
                    </DataTable>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : transactionServiceSelect?.value == 3 ? (
            <Card
              className="report-table-card mt-3"
              style={{ borderRadius: "10px" }}
            >
              <CardBody>
                <Row>
                  <Col lg={12} md={12} xs={12}>
                    <div style={{ overflowX: "auto" }}>
                      <DataTable
                        // stripedRows
                        // pt={{
                        //   root: { className: "table" },
                        // }}
                        ref={dt}
                        value={advancePayDetails ?? []}
                        dataKey="transactionPaymentID"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 15]}
                        tableStyle={{ minWidth: "25rem" }}
                      >
                        <Column
                          field="transactionPaymentID"
                          header="Payment ID"
                          align="center"
                        ></Column>
                        <Column
                          field="transactionServiceName"
                          header="Service Name"
                          align="center"
                        ></Column>
                        <Column
                          field="contactPerson"
                          header="Contact Person"
                          align="center"
                        ></Column>
                        <Column
                          field="mode"
                          header="Mode"
                          align="center"
                        ></Column>
                        <Column
                          field="transactionAmount"
                          header="Amount"
                          align="center"
                        ></Column>
                        <Column
                          field="transactionTax"
                          header="Tax"
                          align="center"
                        ></Column>
                        <Column
                          field="transactionTotalAmount"
                          header="Total Amount"
                          align="center"
                          bodyStyle={{ fontWeight: "bold" }}
                        ></Column>
                        <Column
                          field="createdDate"
                          header="Date"
                          body={(rowData) =>
                            Common.getFormatedDate(
                              rowData.createdDate,
                              "DD/MM/YYYY hh:mm:ss A"
                            )
                          }
                        ></Column>

                        {/* <Column
                          field="paymentStatus"
                          header="Payment Status"
                        ></Column>
                        <Column
                          field="transactionServiceType"
                          header="Service Type"
                        ></Column>
                        <Column
                          field="vendorInformation"
                          header="Vendor Information"
                        ></Column>
                        <Column
                          field="invoiceNumber"
                          header="Invoice Number"
                        ></Column>
                        <Column field="productName" header="Product Name"></Column>
                        <Column field="quantity" header="Quantity"></Column>
                        <Column field="totalTax" header="Total Tax"></Column>
                        <Column
                          field="totalDiscount"
                          header="Total Discount"
                        ></Column>
                        <Column field="totalNetPrice" header="Net Price"></Column>
                        <Column
                          field="totalNetProductCost"
                          header="Net Product Cost"
                        ></Column>
                        <Column field="amountDue" header="Amount Due"></Column> */}
                      </DataTable>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : null}

          <CashPaymentModal
            setCashModal={setCashModal}
            cashModal={cashModal}
            payDetails={payDetails}
            paymentModeSelect={paymentModeSelect}
            transactionServiceSelect={transactionServiceSelect}
            partialPay={PartialPay}
            partialPayAmount={amount}
            reset={reset}
            setLoader={setLoader}
            customerID={customerID}
            setSelectedBills={setSelectedBills}
            getBillDetails={getBillDetails}
            selectedBills={selectedBills}
          />
          <BankTransferModal
            setBanktransferModal={setBanktransferModal}
            banktransferModal={banktransferModal}
            payDetails={payDetails}
            paymentModeSelect={paymentModeSelect}
            transactionServiceSelect={transactionServiceSelect}
            partialPay={PartialPay}
            partialPayAmount={amount}
            reset={reset}
            setLoader={setLoader}
            customerID={customerID}
            setSelectedBills={setSelectedBills}
            getBillDetails={getBillDetails}
            selectedBills={selectedBills}
          />
          <CreditDebitCardModal
            setCreditDebitCardModal={setCreditDebitCardModal}
            creditDebitCardModal={creditDebitCardModal}
            payDetails={payDetails}
            paymentModeSelect={paymentModeSelect}
            transactionServiceSelect={transactionServiceSelect}
            partialPay={PartialPay}
            partialPayAmount={amount}
            reset={reset}
            setLoader={setLoader}
            customerID={customerID}
            setSelectedBills={setSelectedBills}
            getBillDetails={getBillDetails}
            selectedBills={selectedBills}
          />
          <MobileWalletModal
            setMobileWalletModal={setMobileWalletModal}
            mobileWalletModal={mobileWalletModal}
            payDetails={payDetails}
            paymentModeSelect={paymentModeSelect}
            transactionServiceSelect={transactionServiceSelect}
            partialPay={PartialPay}
            partialPayAmount={amount}
            reset={reset}
            setLoader={setLoader}
            customerID={customerID}
            setSelectedBills={setSelectedBills}
            getBillDetails={getBillDetails}
            selectedBills={selectedBills}
          />

          <div>
            <Modal isOpen={paymentConfirmModal} toggle={toggle} centered={true}>
              <ModalHeader toggle={toggle}>Partial Payment</ModalHeader>
              <ModalBody>
                Note that your payment has no bill/bills selected, it will be
                considered as an <b>Advance Payment</b>. Do you want to continue
                ?
              </ModalBody>
              <ModalFooter style={{ justifyContent: "center" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    setIsPartialPay(true);
                    handlePayment();
                  }}
                >
                  Continue
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
