// environmental variables for blob storage

const accountDetails = {
  // STORAGE_ACCOUNT: "myhealthcarehomestorage",
  STORAGE_ACCOUNT: "erpinvociehtmlqueue",
  INVENTORY_STORAGE_CONTAINER: "erp-inventory-images",
  INVENTORY_STORAGE_SAS:
    "sp=racwdli&st=2024-05-23T11:27:47Z&se=2026-04-22T19:27:47Z&sv=2022-11-02&sr=c&sig=4zuCy6WPRT33E8Ga%2BR6QzKGVVhbXMhj4s17LKa6tRGk%3D",
  BILL_STORAGE_CONTAINER: "pdffileoutput",
  // "bills",
  BILL_STORAGE_SAS:
    "sp=racwdli&st=2024-05-23T11:28:57Z&se=2026-04-22T19:28:57Z&sv=2022-11-02&sr=c&sig=TsnzfZVhKk1LKy5vBRMEgw3Z%2BqfWob%2Bt2HbDdwZfZF4%3D",

  INVENTORY_SAMPLE_FILE_CONTAINER: "erpfiles",
  INVENTORY_SAMPLE_FILE_SAS:
    "sp=racwdli&st=2024-05-23T11:30:02Z&se=2026-04-22T19:30:02Z&sv=2022-11-02&sr=c&sig=A562F%2BdP0cW8BOMNy%2Bd92pZC9FuPcAUQPXR7tgBXsDg%3D",
  SAMPLE_FILE_NAME: "InventorySampleTest.csv",
  SAMPLE_FILE_NAME_PDF: "InventoryPDFSampleTest.pdf",
};

export default accountDetails;
