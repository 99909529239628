import { useEffect, useState } from "react";
// reactstrap
import {
  Col,
  Form,
  Row
} from "reactstrap";
// CompanyProfile
import EmailContent from "./EmailContent";
import SmsContent from "./SmsContent";
import WhatsAppConent from "./WhatsAppContent";
// primereacr
// hooks
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import { useLoginStore } from "hooks/Login";
// underscore
import { isEmpty } from "underscore";
// helpers
import CustomButton from "components/Button/CustomButton";
import { notificationMessages } from "helpers/constantMessages";

const Notification = ({ taxDetails, getGenricData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [{ }, { }, { updateGenricData }] = useCompanyProfileStore();
  const [notificationData, setnotificationData] = useState({});
  const [notificationValidate, setNotificationValidate] = useState({});
  const [{ }, { }, { setAlerts }] = useLoginStore();

  useEffect(() => {
    try {
      setnotificationData({
        smsData: taxDetails?.filter(
          (data) => data.feature_key === "invoice_sms_text"
        )[0].feature_value,
        emailSubjectValue: taxDetails?.filter(
          (data) => data.feature_key === "invoice_email_subject"
        )[0].feature_value,
        emailBodyData: taxDetails?.filter(
          (data) => data.feature_key === "invoice_email_body"
        )[0].feature_value,
        emailAttachmentId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "invoice_email_body_attachment"
          )[0].feature_value,
        },
        WhatUpBodyData: taxDetails?.filter(
          (data) => data.feature_key === "invoice_whatsup_body"
        )[0].feature_value,
        WhatsUpAttachmentId: {
          label: taxDetails?.filter(
            (data) => data.feature_key === "invoice_whatsup_imageattachment"
          )[0].feature_value,
        },
      });
    } catch (error) { }
  }, [taxDetails]);

  const handleNotificationData = (value) => {
    setnotificationData(value);
  };

  const handleNotificationValidation = (value) => {
    setNotificationValidate(value);
  };

  async function saveDetails(e) {
    e.preventDefault();
    let error = false;
    let validate = { ...notificationValidate };

    if (notificationValidate && isEmpty(notificationValidate)) {
      error = true;
      validate.emailSubjectValue = "has-danger";
      validate.emailBodyData = "has-danger";
      validate.emailAttachmentId = "has-danger";
      validate.smsData = "has-danger";
      validate.WhatUpBodyData = "has-danger";
      validate.WhatsUpAttachmentId = "has-danger";
    } else {
      if (!notificationData.emailSubjectValue) {
        error = true;
        validate.emailSubjectValue = "has-danger";
      }
      if (!notificationData.emailBodyData) {
        error = true;
        validate.emailBodyData = "has-danger";
      }
      if (!notificationData.emailAttachmentId) {
        error = true;
        validate.emailAttachmentId = "has-danger";
      }
      if (!notificationData.smsData) {
        error = true;
        validate.smsData = "has-danger";
      }
      if (!notificationData.WhatUpBodyData) {
        error = true;
        validate.WhatUpBodyValue = "has-danger";
      }
      if (!notificationData.WhatsUpAttachmentId) {
        error = true;
        validate.WhatsUpAttachmentId = "has-danger";
      }
    }
    setNotificationValidate(validate);
    if (error === false) {
      let param = [
        {
          feature_key: "invoice_sms_text",
          feature_value: notificationData.smsData,
        },
        {
          feature_key: "invoice_email_subject",
          feature_value: notificationData.emailSubjectValue,
        },
        {
          feature_key: "invoice_email_body",
          feature_value: notificationData.emailBodyData,
        },
        {
          feature_key: "invoice_email_body_attachment",
          feature_value: notificationData.emailAttachmentId?.label,
        },
        {
          feature_key: "invoice_whatsup_body",
          feature_value: notificationData.WhatUpBodyData,
        },
        {
          feature_key: "invoice_whatsup_imageattachment",
          feature_value: notificationData.WhatsUpAttachmentId?.label,
        },
      ];
      const res = await updateGenricData(param);
      if (res) {
        const response = await getGenricData();
        await setAlerts({
          type: "success",
          message: notificationMessages.notificationSettings,
        });
      }
    }
  }

  function resetData(e) {
    e.preventDefault();
    getGenricData();
  }

  return (
    <div className="profile-content-container">
      <Form onSubmit={saveDetails} className="profile-address-form">
        {/* <div className="profile-content-title">
          <h5>SMS Settings</h5>
        </div>
        <Row>
          <Col md="6">
            <SmsContent
              handleNotificationData={handleNotificationData}
              notificationData={notificationData}
              handleNotificationValidation={
                handleNotificationValidation
              }
              notificationValidate={notificationValidate}
              saveDetails={saveDetails}
            />
          </Col>
        </Row> */}

        <div className="profile-content-title">
          <h5>Email Settings</h5>
        </div>
        <Row>
          <EmailContent
            handleNotificationData={handleNotificationData}
            notificationData={notificationData}
            handleNotificationValidation={
              handleNotificationValidation
            }
            notificationValidate={notificationValidate}
          />
        </Row>

        <div className="profile-content-title">
          <h5>WhatsApp Settings</h5>
        </div>
        <Row>
          <WhatsAppConent
            handleNotificationData={handleNotificationData}
            notificationData={notificationData}
            handleNotificationValidation={
              handleNotificationValidation
            }
            notificationValidate={notificationValidate}
          />
        </Row>


        <div className="mt-lg-3 profile-button-container">
          <CustomButton
            label="Save Details"
            type="submit"
          />
          <CustomButton
            label="Reset"
            onClick={resetData}
            customStyle='profile-reset-btn'
          />
        </div>

      </Form>

    </div >
  );
};

export default Notification;
