import APIHandler from "helpers/APIHandler";
import { ASSIGN_INVENTORY_API } from "helpers/APIRouter";
import { CREATE_MODIFY_DISCOUNT_BY_CATEGORY_API } from "helpers/APIRouter";
import { GET_DETAILS_DISCOUNT_BY_PRODUCT_API } from "helpers/APIRouter";
import { PRODUCT_DETAILS_BY_ID_API } from "helpers/APIRouter";
import { GET_DETAILS_DISCOUNT_BY_CATEGORY_API } from "helpers/APIRouter";
import { CREATE_MODIFY_DISCOUNT_BY_PRODUCT_API } from "helpers/APIRouter";
import { INVENTORY_FILE_UPLOAD_TO_AZURE_API } from "helpers/APIRouter";
import { SEARCH_CATEGORIES_SUB_CATEGORIES_API } from "helpers/APIRouter";
import { UPLOAD_INVENTORY_BY_FILE_API } from "helpers/APIRouter";
import {
  INVENTORY_REPORT_API,
  CREATE_MODIFY_INVENTORY_API,
  UPLOAD_BLOBS_INVENTORY_API,
  INVENTORY_FILE_CONTENT_API,
  INVENTORY_SUMMARY_API,
  INVENTORY_SUMMARY_DETAILS_API,
  CATEGORIES_API,
  SUB_CATEGORIES_API,
  REORDERSTOCK_REPORT_API,
  PRODUCT_EXPIRY_REPORT_API,
} from "helpers/APIRouter";

const API = new APIHandler();

class InventoryAPICall {
  createModifyInventory = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(CREATE_MODIFY_INVENTORY_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  inventoryReport = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(INVENTORY_REPORT_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  uploadBlobs = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    param.isMultipart = true;
    await API.post(UPLOAD_BLOBS_INVENTORY_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getFileContent = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(INVENTORY_FILE_CONTENT_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  uploadInventoryByFile = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(UPLOAD_INVENTORY_BY_FILE_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  uploadedInventorySummary = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;

    // console.log(param);

    await API.get(INVENTORY_SUMMARY_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  uploadedInventorySummaryDetails = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(INVENTORY_SUMMARY_DETAILS_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getCategoryList = async (params = {}) => {
    let res = {};
    await API.get(CATEGORIES_API)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getSubCategoryList = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(SUB_CATEGORIES_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getCategorySubCategorySearchList = async (params) => {
    let res = {};
    await API.get(SEARCH_CATEGORIES_SUB_CATEGORIES_API, params)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  assignInventory = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(ASSIGN_INVENTORY_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createModifyDiscountByCategory = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(CREATE_MODIFY_DISCOUNT_BY_CATEGORY_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  createModifyDiscountByProduct = async (params = {}) => {
    let res = {};
    let param = {};
    param.data = params;
    await API.post(CREATE_MODIFY_DISCOUNT_BY_PRODUCT_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getDiscountDetailsByProductId = async (params) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_DETAILS_DISCOUNT_BY_PRODUCT_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getDiscountDetailsByCategoryId = async (params) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(GET_DETAILS_DISCOUNT_BY_CATEGORY_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  reOrderStockReport = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.post(REORDERSTOCK_REPORT_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getProductExpiryDateReport = async (params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.post(PRODUCT_EXPIRY_REPORT_API, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };

  getProductDetailsById = async (id = '', params = {}) => {
    let res = {};
    let param = {};
    param.params = params;
    await API.get(`${PRODUCT_DETAILS_BY_ID_API}/${id}`, param)
      .then((result) => {
        if (result) {
          res = result.result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return res;
  };
}

export default InventoryAPICall;
