import React, { useEffect, useRef, useState } from "react";

// reactstrap components
import { Col, Card, CardBody, Button } from "reactstrap";

// used components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDashboardV2DataStore } from "hooks/DashboardV2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import PageLoader from "components/Loader/PageLoader";
import ChildLoader from "components/Loader/ChildLoader";
import { ADMIN_URL_RECENT_ENVENTORY } from "helpers/Paths";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import LoadMore from "components/Button/LoadMore";
import { FaPenToSquare } from "react-icons/fa6";
import Common from "helpers/Common";
import { URL_INVENTORY_PRODUCTS_ADD } from "helpers/Paths";
import moment from "moment";

// Define the date parameters outside the component scope
const toDateParam = new Date().toISOString().slice(0, 23).replace("T", " ");
const fromDateParam = new Date(new Date().setDate(new Date().getDate() - 30))
  .toISOString()
  .slice(0, 23)
  .replace("T", " ");

const RecentInventory = () => {
  const [{}, { getRecentInventory }] = useDashboardV2DataStore();
  const [loader, setLoader] = useState(false);
  const [recentInventoryData, setRecentInventoryData] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);

  const dt = useRef(null);
  const navigate = useNavigate();

  // Initial data load on component mount
  useEffect(() => {
    setLoader(true);
    getRecentInventoryData();
  }, []);

  const getRecentInventoryData = async (page = 1) => {
    if (page === 1) {
      setPageNumber(1);
    }

    try {
      const RecentInvData = await getRecentInventory({
        params: {
          fromdate: fromDateParam,
          toDate: toDateParam,
          pagesize: new CompanyProfileRepository().getPageSize(),
          pageNumber: page,
        },
      });

      if (page > 1) {
        setRecentInventoryData((prev) => [...prev, ...RecentInvData]);
      } else {
        setRecentInventoryData(RecentInvData);
      }
      setLoader(false);
      setLoadingMore(false);
    } catch (error) {
      alert(error); // Error handling
      setLoader(false);
      setLoadingMore(false);
    }
  };

  const loadMoreData = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
    setLoadingMore(true);
    getRecentInventoryData(pageNumber + 1);
  };

  const redirectToRecentInventory = () => {
    navigate(ADMIN_URL_RECENT_ENVENTORY);
  };

  const ActionTemplate = ({ rowData }) => {
    return (
      <div className="d-flex justify-content-around w-100">
        <Link
          to={`/admin${URL_INVENTORY_PRODUCTS_ADD}`}
          state={{ productId: rowData?.productID }}
        >
          <button type="button" className="actions-btn">
            <FaPenToSquare className="table-action-icon" />
          </button>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="content w-100">
        <Col
          lg={12}
          md={12}
          xs={12}
          style={{
            padding: 0,
          }}
        >
          <Card className="dashboard-inventory-tile">
            <CardBody>
              <div className="d-flex align-items-center justify-content-between pb-2">
                <div className="poppins-title recent-inventory-title">
                  Recent Products
                </div>
              </div>
              {loader ? (
                <ChildLoader />
              ) : (
                <>
                  <DataTable
                    ref={dt}
                    value={recentInventoryData ?? []}
                    selection={selectedInventory}
                    onSelectionChange={(e) => setSelectedInventory(e.value)}
                    dataKey="productID"
                    tableStyle={{ minWidth: "25rem" }}
                    pt={{
                      root: { className: "table" },
                    }}
                  >
                    <Column field="productName" header="Product Name"></Column>
                    <Column
                      align="center"
                      field="branchName"
                      header="Branch Name"
                    ></Column>
                    <Column field="quantity" header="Quantity"></Column>
                    <Column field="categoryName" header="Category ID"></Column>
                    <Column field="unitPrice" header="Unit Price"></Column>
                    <Column field="upcCode" header="UPC Code"></Column>
                    <Column
                      field="productCreationDate"
                      header="Created On"
                      body={(rowData) => {
                        try {
                          return Common.getFormatedDate(
                            rowData.productCreationDate,
                            "DD/MM/YYYY hh:mm:ss A",
                            true
                          );
                        } catch (error) {
                          console.error(error.message);
                          return "Invalid Date"; // or any fallback display text
                        }
                      }}
                    ></Column>
                    <Column
                      header="Actions"
                      body={(rowData) => <ActionTemplate rowData={rowData} />}
                      exportable={false}
                    />
                  </DataTable>
                  <Col className="text-center">
                    {loadingMore ? (
                      <ChildLoader />
                    ) : (
                      recentInventoryData.length > 0 && (
                        <Button
                          color="default"
                          className="show-more-button"
                          onClick={() => loadMoreData()}
                        >
                          Load More
                        </Button>
                      )
                    )}
                  </Col>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </div>
    </>
  );
};

export default RecentInventory;
