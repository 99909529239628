import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Card, CardBody, Row, Col } from "reactstrap";
import { isArray, isEmpty } from "underscore";
import { useCreateBillStore } from "hooks/CreateBill";
import { useReportStore } from "hooks/Report";
import moment from "moment";
import Common from "helpers/Common";
import FilterButton from "views/Report/FilterButton";
import ChildLoader from "components/Loader/ChildLoader";
import CompanyProfileRepository from "repository/CompanyProfileRepository";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";
import "assets/styles/analytics/SalesReport.css";
import CustomButton from "components/Button/CustomButton";
import { useNavigate } from "react-router-dom";
import { URL_BEST_SELLING_USAGE_REPORT } from "helpers/Paths";
import { URL_LEAST_SELLING_USAGE_REPORT } from "helpers/Paths";
import { URL_REVENUE_REPORT_LINE_CHART } from "helpers/Paths";

const SalesReoprt = () => {
  let initialReportState = {
    labels: [],
    datasets: [],
  };

  const [{}, { getRecentBill }, {}] = useCreateBillStore();
  const [{}, { getRevenueReport }, {}] = useReportStore();

  const [highestRevenue, setHighestRevenue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [salesQuantity, setSaleQuantity] = useState([]);
  const [salesRevenue, setSalesRevenue] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [revenueChartData, setRevenueChartData] = useState(initialReportState);
  const [selectedDate, setSelectedDate] = useState("");

  const MAX_RECORDS = 15;
  const navigate = useNavigate();

  const revenueChartOptions = {
    // responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            return `Revenue: ${context.raw}`;
          },
        },
        displayColors: false,
        backgroundColor: "#585858",
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        padding: 10,
        cornerRadius: 4,
      },
    },
    scales: {
      y: {
        // min: 0,
        // max: Math.ceil(
        //     (highestRevenue + (highestRevenue < 100 ? 10 : 100)) /
        //     (highestRevenue > 10 && highestRevenue < 100 ? 10 : 100)
        // ) * (highestRevenue > 10 && highestRevenue < 100 ? 10 : 100),
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: 10,
          padding: 20,
          color: "#9f9f9f",
          font: {
            family: "DM Sans, sans-serif",
          },
          // stepSize: 500
        },
      },
      x: {
        grid: {
          borderDash: [5, 5],
        },
        ticks: {
          padding: 20,
          color: "#9f9f9f",
          maxRotation: 0,
          minRotation: 0,
          callback: function (value) {
            const label = this.getLabelForValue(value);
            const words = label.split(" ");
            const maxLineLength = 10;
            let line = "";
            let lines = [];
            words.forEach((word) => {
              if ((line + word).length > maxLineLength) {
                lines.push(line.trim());
                line = "";
              }
              line += word + " ";
            });
            lines.push(line.trim());
            return lines;
          },
        },
      },
    },
  };

  const formatFloat = (value) => {
    return value === null
      ? parseFloat(0.0).toFixed(2)
      : parseFloat(parseFloat(value).toFixed(2));
  };

  const setChartQuantityDetails = (
    labelData,
    salesDataInfo,
    label = "Sales Quantity"
  ) => {
    const chartQuantityDetails = {
      labels: labelData,
      datasets: [
        {
          label: label,
          data: salesDataInfo,
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  };

  const setChartRevenueDetails = (
    labelData,
    allRevenueData,
    label = "Revenue"
  ) => {
    labelData = labelData.slice(0, 10);
    allRevenueData = allRevenueData.slice(0, 10);
    const chartRevenueDetails = {
      labels: labelData,
      datasets: [
        {
          label: label,
          data: allRevenueData,
          borderColor: "rgb(67, 76, 230)",
          backgroundColor: "rgb(67, 76, 230)",
          borderWidth: 1,
        },
      ],
    };
    setRevenueChartData(chartRevenueDetails);
  };

  async function getReport(Date = "", endDate = "", page = 1) {
    setSelectedDate({ fromDate: Date, toDate: endDate });
  }

  useEffect(() => {
    setLoader(true);
    if (selectedDate) {
      getReportData();
    }
  }, [selectedDate]);

  async function getReportData(page = 1) {
    let params = {};
    params.pagenumber = page;
    params.pagesize = new CompanyProfileRepository().getPageSize();
    params.from_date = selectedDate.fromDate;
    params.to_date = selectedDate.toDate;
    params.fromDate = selectedDate.fromDate;
    params.toDate = selectedDate.toDate;

    let revenueData = await getRevenueReport(params);

    let labelsData = [];
    let salesQuantityData = [];
    let salesRevenueData = [];
    if (revenueData && isArray(revenueData) && !isEmpty(revenueData)) {
      revenueData.forEach((v, i) => {
        labelsData.push(Common.getFormatedDate(v.revenueDate, "DD/MM"));
        salesQuantityData.push(v.salesQuantity);
        salesRevenueData.push(v.salesRevenue);
      });
    }

    if (revenueData.length === 0) {
      if (page === 1) {
        setSaleQuantity(salesQuantityData);
        setSalesRevenue(salesRevenueData);
        setLabels(labelsData);
        setChartRevenueDetails(labelsData, salesRevenueData);
        setChartQuantityDetails(labelsData, salesQuantityData);
        setHighestRevenue(Math.round(Math.max(...salesRevenueData)));
      }

      setLoadingMore(false);
      setLoader(false);
    } else if (revenueData.length > 0 && page > 1) {
      // Append new data to existing data for subsequent pages

      const salesinfo = [...salesQuantity, ...salesQuantityData];
      const labelsInfo = [...labels, ...labelsData];
      const revenueInfo = [...salesRevenue, ...salesRevenueData];

      setSaleQuantity((prevData) => [...prevData, ...salesQuantityData]);
      setSalesRevenue((prevData) => [...prevData, ...salesRevenueData]);
      setLabels((prevData) => [...prevData, ...labelsData]);

      if (salesinfo.length > MAX_RECORDS) {
        // Combine data for sorting and slicing
        let combinedData = labelsInfo.map((label, index) => ({
          label,
          salesQuantity: salesinfo[index],
          salesRevenue: revenueInfo[index],
        }));

        // Sort combinedData based on salesQuantity in descending order and slice top 20 records
        combinedData.sort((a, b) => b.salesQuantity - a.salesQuantity);
        combinedData = combinedData.slice(0, MAX_RECORDS);

        // Extract data for the chart
        let topLabels = combinedData.map((data) => data.label);
        let topSalesQuantities = combinedData.map((data) => data.salesQuantity);
        let topSalesRevenues = combinedData.map((data) => data.salesRevenue);

        // Update charts with top 20 data
        setChartQuantityDetails(
          topLabels,
          topSalesQuantities,
          `Top ${MAX_RECORDS} Sales Quantities`
        );
        setChartRevenueDetails(
          topLabels,
          topSalesRevenues,
          `Top ${MAX_RECORDS} Sales Revenues`
        );
        setHighestRevenue(Math.round(Math.max(...topSalesRevenues)));

        setLoadingMore(false);
        setLoader(false);
      } else {
        const allSalesQuantity = [...salesQuantity, ...salesQuantityData];
        const allRevenueData = [...salesRevenue, ...salesRevenueData];
        const allLabelsInfo = [...labels, ...labelsData];

        setChartRevenueDetails(allLabelsInfo, allRevenueData);
        setChartQuantityDetails(allLabelsInfo, allSalesQuantity);

        setHighestRevenue(Math.round(Math.max(...allRevenueData)));

        setLoadingMore(false);
        setLoader(false);
      }
    } else {
      // Set new data for the first page

      setSaleQuantity(salesQuantityData);
      setSalesRevenue(salesRevenueData);
      setLabels(labelsData);
      setChartRevenueDetails(labelsData, salesRevenueData);
      setChartQuantityDetails(labelsData, salesQuantityData);
      setHighestRevenue(Math.round(Math.max(...salesRevenueData)));

      setLoadingMore(false);
      setLoader(false);
    }
    let creditData = await getRecentBill(params);

    let arr = [];
    if (creditData && isArray(creditData) && !isEmpty(creditData)) {
      creditData.map((prop, key) => {
        let createDateObj = moment(prop.createDateTime);
        let formattedDate = createDateObj.format("MMMM D, YYYY");
        let formattedTime = createDateObj.format("hh:mm A");

        let formattedDateTime = `${formattedDate}, ${formattedTime}`;

        let obj = {
          id: prop.billId,
          billId: prop.billId,
          billDetail: prop.billDetail,
          customerName: prop.customerName,
          totalDiscountAmount: formatFloat(prop.total_discount_amount),
          netProductPayment: formatFloat(prop.netProductPayment),
          applicableTax: formatFloat(prop.applicableTax),
          netPayment: formatFloat(prop.netPayment),
          dateTime: formattedDateTime,
        };
        arr.push(obj);
      });
    }

    if (page > 1) {
      setLoadingMore(false);
      setLoader(false);
    } else {
      setLoader(false);
    }

    setLoadingFilter(false);
  }

  const navigateToReport = (route) => {
    navigate(`/admin${route}`);
  };

  return (
    <>
      <div className="content-dashboard-inventory">
        <div className="title-route-container menu-title-div">
          <h4 className="menu-title-h">Analytics / Sales Report</h4>
          <div className="payment-header-btn-container">
            <FilterButton
              getReport={getReport}
              setLoadingFilter={setLoadingFilter}
            />
          </div>
        </div>

        <Row className="cards-container">
          <Col sm="12" md="12" lg="6" xl="6">
            <Card className="chart-card">
              <CardBody>
                {loadingMore || loadingFilter ? (
                  <ChildLoader />
                ) : (
                  <>
                    <div className="selling-header">
                      <h5>Sales Report</h5>
                      <CustomButton
                        label="Detailed View"
                        onClick={() =>
                          navigateToReport(URL_REVENUE_REPORT_LINE_CHART)
                        }
                      />
                    </div>
                    {revenueChartData.labels.length > 0 ? (
                      <div className="bar-chart-wrapper">
                        <div className="bar-chart-container">
                          <Line
                            data={revenueChartData}
                            options={revenueChartOptions}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "calc(100% - 40px)",
                          fontSize: "20px",
                        }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        No Data Available
                      </div>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col sm="6" md="6" lg="3" xl="3">
            <Card
              className="selling-cards"
              onClick={() => navigateToReport(URL_BEST_SELLING_USAGE_REPORT)}
            >
              <CardBody className="selling-card-body">
                <div className="selling-header">
                  <h5 className="m-0">Best Selling Product</h5>
                  <FaAnglesUp className="angle-arrow-svg" />
                </div>
                <div className="selling-data-label">
                  <p>Product Quantity:</p>
                  <p className="selling-data-value">1968</p>
                </div>
                <div className="selling-data-label">
                  <p>Product Name:</p>
                  <p className="selling-data-value">Dolo 750</p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6" md="6" lg="3" xl="3">
            <Card
              className="selling-cards"
              onClick={() => navigateToReport(URL_LEAST_SELLING_USAGE_REPORT)}
            >
              <CardBody className="selling-card-body">
                <div className="selling-header">
                  <h5 className="m-0">Least Selling Product</h5>
                  <FaAnglesDown className="angle-arrow-svg" />
                </div>
                <div className="selling-data-label">
                  <p>Product Quantity:</p>
                  <p className="selling-data-value">50</p>
                </div>
                <div className="selling-data-label">
                  <p>Product Name:</p>
                  <p className="selling-data-value">Adoxa</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SalesReoprt;
