import CustomButton from "components/Button/CustomButton";
import PageLoader from "components/Loader/PageLoader";
import Common from "helpers/Common";
import { notificationMessages } from "helpers/constantMessages";
import { useLoginStore } from "hooks/Login";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useCompanyProfileStore } from "hooks/CompanyProfile";
import QRCode from "qrcode.react"; // Import QRCode generator
import QRCodeGenerator from "views/payment/QRCodeGenerator";

const GSTNTax = ({ taxDetails, getGenricData }) => {
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [formatData, setFormatData] = useState({
    gstn: "",
    pan: "",
    tan: "",
    bankAccount: "",
    ifsc: "",
    upi: "",
  });
  const [locationValidate, setLocationValidate] = useState({});
  const [loader, setLoader] = useState(false);
  const [{}, {}, { updateGenricData }] = useCompanyProfileStore();

  useEffect(() => {
    try {
      setLoader(true);
      setFormatData({
        ...formatData,
        gstn:
          taxDetails?.filter((data) => data.feature_key === "GSTN_Number")[0]
            ?.feature_value || "",
        pan:
          taxDetails?.filter((data) => data.feature_key === "PAN_Number")[0]
            ?.feature_value || "",
        tan:
          taxDetails?.filter((data) => data.feature_key === "TAN_Number")[0]
            ?.feature_value || "",
        bankAccount:
          taxDetails?.filter((data) => data.feature_key === "Bank_Account")[0]
            ?.feature_value || "",
        ifsc:
          taxDetails?.filter((data) => data.feature_key === "IFSC")[0]
            ?.feature_value || "",
        upi:
          taxDetails?.filter((data) => data.feature_key === "UPI_ID")[0]
            ?.feature_value || "",
      });
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  }, [taxDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let error = { ...locationValidate };

    if (!Common.verifyLength(value, 1)) {
      error[name] = "has-danger";
    } else {
      error[name] = "has-success";
    }

    setLocationValidate(error);

    setFormatData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function saveDetails(e) {
    e.preventDefault();
    setLoader(true);
    let param = [
      { feature_key: "GSTN_Number", feature_value: formatData.gstn },
      { feature_key: "PAN_Number", feature_value: formatData.pan },
      { feature_key: "TAN_Number", feature_value: formatData.tan },
      { feature_key: "Bank_Account", feature_value: formatData.bankAccount },
      { feature_key: "IFSC", feature_value: formatData.ifsc },
      { feature_key: "UPI_ID", feature_value: formatData.upi },
    ];

    const res = await updateGenricData(param);
    if (res) {
      const response = await getGenricData();
      await setAlerts({
        type: "success",
        message: notificationMessages.gstnDetails,
      });
    }
    setLoader(false);
  }

  const resetDetail = async (e) => {
    e.preventDefault();
    setFormatData({
      gstn: "",
      pan: "",
      tan: "",
      bankAccount: "",
      ifsc: "",
      upi: "",
    });
  };

  return (
    <>
      {loader ? (
        <PageLoader />
      ) : (
        <div className="profile-content-container">
          <div className="profile-content-title">
            <h5>GSTN & Tax</h5>
          </div>
          <form className="profile-address-form" onSubmit={saveDetails}>
            <Row>
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>GSTN Number</label>
                  <input
                    placeholder="GSTN Number"
                    type="text"
                    name="gstn" // Updated name to match state key
                    value={formatData.gstn}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </div>
              </Col>
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>PAN Number</label>
                  <input
                    placeholder="PAN Number"
                    type="text"
                    name="pan" // Updated name to match state key
                    value={formatData.pan}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-lg-3">
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>TAN Number</label>
                  <input
                    placeholder="TAN Number"
                    type="text"
                    name="tan" // Updated name to match state key
                    value={formatData.tan}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </div>
              </Col>
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>IFSC</label>
                  <input
                    placeholder="IFSC"
                    type="text"
                    name="ifsc" // Updated name to match state key
                    value={formatData.ifsc}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-lg-3">
              <Col sm="12" md="12" lg="6">
                <div className="custom-input-container">
                  <label>UPI ID (Registered Valid Payment UPI)</label>
                  <input
                    placeholder="UPI ID"
                    type="text"
                    name="upi" // Updated name to match state key
                    value={formatData.upi}
                    onChange={handleChange}
                    maxLength={100}
                  />
                </div>
              </Col>

              <Col sm="12" md="12" lg="6">
                {formatData.upi && (
                  <div className="qr-code-container">
                    <label>UPI QR Code</label>
                    {/* QR code for UPI payment */}
                    <QRCodeGenerator upiId={formatData.upi} />
                  </div>
                )}
              </Col>
            </Row>

            <div className="mt-lg-3 profile-button-container">
              <CustomButton label="Save Details" onClick={saveDetails} />
              <CustomButton
                label="Reset"
                onClick={resetDetail}
                customStyle="profile-reset-btn"
              />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default GSTNTax;
