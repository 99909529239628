import { useCompanyStore } from "hooks/Company";
import { useLoginStore } from "hooks/Login";
import React, { useEffect, useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { AUTH_URL_LOGIN, ADMIN_URL_DASHBOARD } from "helpers/Paths";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import Common from "helpers/Common";
import { isEmpty } from "underscore";
import CustomInput from "components/Input/CustomInput";
import { RiInformationLine } from "react-icons/ri"; // Importing the info icon

const FinishInput = ({
  handleNext,
  otpVerify,
  setOtpVerify,
  formData,
  setFormData,
  submitHandler,
}) => {
  const [{}, {}, { setAlerts }] = useLoginStore();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePassword = () => {
    setPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  return (
    <>
      {/* <div className="header">
        <div className="verification-text">4. Password Details</div>
        <div className="step-indicator">Step 4 - 4</div>
      </div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <FormGroup className="has-label">
          <Label>Login ID *</Label>
          <CustomInput
            autoFocus={true}
            placeholder="Login Id ex. ABC123"
            type="text"
            name="loginID"
            id="loginID"
            onChange={handleChange}
            // disabled={otpVerify ? false : true}
            // disabled={otpVerify ? false : true}
            onKeyPress={(e) => {
              // Allow only alphanumeric characters (0-9, a-z, A-Z)
              const regex = /^[a-zA-Z0-9]*$/;
              if (!regex.test(e.key)) {
                e.preventDefault();
              }
            }}
            onPaste={(e) => {
              const pastedText = e.clipboardData.getData("Text");
              const regex = /^[a-zA-Z0-9]*$/;
              if (!regex.test(pastedText)) {
                e.preventDefault();
              }
            }}
            maxLength={100}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <RiInformationLine
              size={20}
              style={{ marginRight: "5px", color: "#007bff" }}
            />
            <span style={{ fontSize: "13px", color: "#444" }}>
              Only text and numbers allowed (A-Z, a-z, 0-9)
            </span>
          </div>
          {/* <Input
            placeholder="example@mail.com"
            type="text"
            name="loginID"
            id="loginID"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={100}
          /> */}
        </FormGroup>

        <FormGroup className="has-label">
          <Label>Password *</Label>
          <CustomInput
            placeholder="Password"
            name="loginPassword"
            id="loginPassword"
            onChange={handleChange}
            // disabled={otpVerify ? false : true}
            maxLength={15}
            showEyeIcon={true}
            onEyeClick={togglePassword}
            type={passwordType}
            passwordType={passwordType}
          />
          {/* <Input
            placeholder="Enter Password"
            type="password"
            name="loginPassword"
            id="loginPassword"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={15}
          /> */}
        </FormGroup>

        <FormGroup className="has-label">
          <label>Confirm Password *</label>
          <CustomInput
            placeholder="Confirm Password"
            name="confirmPassword"
            id="confirmPassword"
            onChange={handleChange}
            // disabled={otpVerify ? false : true}
            maxLength={15}
            showEyeIcon={true}
            onEyeClick={toggleConfirmPassword} // Add the onEyeClick prop here
            type={confirmPasswordType} // Use the confirmPasswordType state
            passwordType={confirmPasswordType} // Pass the confirmPasswordType to CustomInput if needed
          />
          {/* <Input
            placeholder="Enter Password Again"
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            onChange={handleChange}
            disabled={otpVerify ? false : true}
            maxLength={15}
          /> */}
        </FormGroup>
      </div>
      <button className="custom-login-btn" onClick={submitHandler}>
        Finish
      </button>
    </>
  );
};

export default FinishInput;
